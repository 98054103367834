import React from "react";

import Body from "./Body";
import Empty from "./EmptyTable";
import Footer from "./Footer";
import Header from "./Header";
import Loading from "./LoadingTable";
import Wrapper from "./Wrapper";
import { cn } from "../../../../../helper/utils";
import { BaseTable } from "../baseTable";

export const InnerTable = (props, ref) => {
  const {
    loading,
    className,
    headerClassName,
    empty,
    classnamethead,
    paginator,
    pagination,
    wrapperClassName,
    header,
    cellClassName,
    rowClassName,
    bodyClassName,
    ...rest
  } = props;

  return (
    <BaseTable {...rest}>
      <Table.Wrapper
        onScroll={rest?.onScroll}
        className={cn(Table.TABLE_WRAPPER_CLASSNAME, wrapperClassName)}
      >
        {loading && <Table.Loading className={Table.TABLE_LOADING_CLASSNAME} />}

        <table
          className={cn(Table.TABLE_CLASSNAME, className, {
            "h-full": !loading && !rest.data?.length,
          })}
          ref={ref}
        >
          {header !== null && (
            <Table.Header
              className={cn(Table.TABLE_HEADER_CLASSNAME, headerClassName)}
              classnamethead={cn(classnamethead)}
            />
          )}

          {!loading && (
            <Table.Body
              loading={loading}
              cellClassName={cellClassName}
              rowClassName={rowClassName}
              className={cn(Table.TABLE_BODY_CLASSNAME, bodyClassName)}
              empty={
                empty || <Table.Empty className={Table.TABLE_EMPTY_CLASSNAME} />
              }
            />
          )}
          <Table.Footer className={Table.TABLE_FOOTER_CLASSNAME} />
        </table>
      </Table.Wrapper>
    </BaseTable>
  );
};

const Table = React.forwardRef(InnerTable);
export default Table;

Table.Wrapper = Wrapper;
Table.Header = Header;
Table.Body = Body;
Table.Footer = Footer;
Table.Empty = Empty;
Table.Loading = Loading;

Table.TABLE_WRAPPER_CLASSNAME =
  "overflow-x-auto custom-vertical-scrollbar-4 relative";
Table.TABLE_CLASSNAME =
  "w-full border-collapse overflow-x-auto custom-vertical-scrollbar-4";
Table.TABLE_HEADER_CLASSNAME =
  "bg-transparent rounded-tl-xl rounded-tr-xl overflow-hidden";
Table.TABLE_BODY_CLASSNAME = "";
Table.TABLE_FOOTER_CLASSNAME = "Table.TABLE_FOOTER_CLASSNAME";
Table.TABLE_EMPTY_CLASSNAME = "Table.TABLE_EMPTY_CLASSNAME";
Table.TABLE_LOADING_CLASSNAME =
  "absolute inset-0 flex justify-center items-center text-primary z-[1]";
