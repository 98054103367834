import React from "react";

const PlusIcon = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#C7A446" />
      <path
        d="M10.4274 3.59711L9.23199 3.59711L9.23199 9.2324L3.59671 9.2324V10.4278L9.23199 10.4278L9.23199 16.063L10.4274 16.063L10.4274 10.4278L16.0626 10.4278V9.2324L10.4274 9.2324L10.4274 3.59711Z"
        fill="white"
      />
    </svg>
  );
};

export default PlusIcon;
