import React, { useEffect } from "react";
import { useController } from "react-hook-form";
import { cn } from "../../../../helper/utils";

const RadioGroupField = ({
  controller,
  callbackOnChange,
  options,
  wrapperClassName,
  defaultValue,
  isNoLabel = false,
}) => {
  const { field } = useController(controller);

  const handleOnChange = (v) => {
    field.onChange(v);
    callbackOnChange && callbackOnChange(v);
  };

  useEffect(() => {
    if (defaultValue) {
      field.onChange(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div className={cn("wrap-radio-group-field", wrapperClassName)}>
      {options.map((option, index) => (
        <div key={`radio-${option.value}`} className="flex gap-2 items-center">
          <input
            name={field.name}
            type="radio"
            id={`radio-${option.value}`}
            checked={field.value === option.value}
            onChange={() => handleOnChange(option.value)}
            onFocus={() => handleOnChange(option.value)}
            value={option.value}
            className="w-4 h-4"
          />
          <label
            htmlFor={`radio-${option.value}`}
            onClick={() => handleOnChange(option.value)}
          >
            {" "}
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default RadioGroupField;
