import { io } from 'socket.io-client';
import config from '../config/index';
import { WEBSOCKET_CHANNEL } from '../constants/options';

class SocketIo {
  constructor() {
    this.socket = io(config.SOCKET_URL, {
      autoConnect: false,
      rejectUnauthorized: false,
    });
  }

  setAuthUser(authUser) {
    this.authUser = authUser;
  }

  connect(authUser) {
    this.authUser = authUser;

    this.socket.auth = {
      userId: authUser?.id,
    };

    return this.socket.connect();
  }

  disconnect() {
    return this.socket.disconnect();
  }

  emitUIActionEvent(actionName, data) {
    return this.socket.emit(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, {
      content: {
        action: actionName,
        data: data,
      },
      to: this.authUser?.id || 'cp87pvd1ec',
      from: this.authUser?.id || 'cp87pvd1ec',
    });
  }

  emit(channel, data) {
    return this.socket.emit(channel, data);
  }

  off(channel, callback) {
    return this.socket.off(channel, callback);
  }

  on(channel, callback) {
    return this.socket.on(channel, callback);
  }

  shareMedia(data) {
    if (!this.authUser.presentation) return;

    return this.socket.emit(WEBSOCKET_CHANNEL.SHARE_MEDIA, data);
  }
}

const socket = new SocketIo();

export default socket;
