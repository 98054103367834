import {
  reqSetIsShowExploreModal,
  reqSetIsShowExpandAmenity,
  reqSetIsShowAmenityVirtualTour,
} from "../../reduxs/explore-modal/action";
import {
  reqSetIsShowGalleryModal,
} from "../../reduxs/district-future-detail/action";
import React, { useEffect } from "react";
import socket from "../../helper/socket";
import VirtualModal from "./virtual-modal";
import { getS3FEMediaUrl } from "../../helper/media";
import { useDispatch, useSelector } from "react-redux";
import { reqSetActiveAreaId } from "../../reduxs/home/action";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { reqFilterUnitPrecinct } from "../../reduxs/unit-explore/action";
import { selectedHotspot, selectedHotspot3D } from "../3d-scene/CanvasBox";
import rightArrowIcon from "../../assets/images/arrow-right-thin-black.svg";
import { reqSetActivePrecinctID } from "../../reduxs/transport-options/action";
import { PAGES_ENUM, FEATURES_ENUM, COMPONENTS_ENUM } from "../../constants/modules";
import { ACTION_NAME, REACTUI_PAGES, WEBSOCKET_CHANNEL } from "../../constants/options";
import { reqSetIsShowPrecinctExploreDetail } from "../../reduxs/precinct-explore/action";
import classNames from "classnames";

const RightPanel = (props) => {
  const { reactuiPage, isPresentation } = props;
  const isShowRightPanel = useSelector(
    (state) => state.exploreModal.isShowExploreModal
  );
  const isShowExpandAmenity = useSelector(
    (state) => state.exploreModal.isShowExpandAmenity
  );
  const modal = useSelector((state) => state.exploreModal.modal);
  const dispatch = useDispatch();
  const isShowGallery = useSelector(
    (state) => state.districtdetail.isShowGalleryModal
  );
  const isShowAmenityVirtualTour = useSelector(
    (state) => state.exploreModal.isShowAmenityVirtualTour
  );
  const pages = useSelector((state) => state.configuration.pages);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CLICK_CLOSE_MODAL) {
      onClose();
    }
    if (content.action === ACTION_NAME.SHOW_AMENITY_MODAL_IMAGE) {
      handleShowModalImage();
    }
    if (content.action === ACTION_NAME.CLOSE_AMENITY_MODAL_IMAGE) {
      handleCloseModalImage();
    }
    if (content.action === ACTION_NAME.SHOW_AMENITY_VIRTUAL_TOUR) {
      handleClickVirtualTour();
    }
  };
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, []);

  const onClose = () => {
    if (selectedHotspot3D?.material?.map && selectedHotspot?.texture) {
      selectedHotspot3D.material.map = selectedHotspot.texture;
    }

    dispatch(reqSetActivePrecinctID());
    dispatch(reqSetIsShowExploreModal(false));
  };

  const handleButtonInContent = (event) => {
    const clickedEl = event.target;
    if (clickedEl.id == "btn_close_right_panel") {
      dispatch(reqSetIsShowGalleryModal(false));
      return onClose();
    }

    if (clickedEl.id == "btn_explore_precinct") {
      if (clickedEl.dataset?.area_id) {
        dispatch(reqSetActiveAreaId(clickedEl.dataset.area_id));
      } else {
        dispatch(reqSetActiveAreaId());
      }

      if (clickedEl.dataset?.precinct) {
        dispatch(reqFilterUnitPrecinct(clickedEl.dataset.precinct));
      } else {
        dispatch(reqFilterUnitPrecinct());
      }

      dispatch(reqSetIsShowPrecinctExploreDetail(true));
      onClose();
    }
  };

  const renderModalContent = () => {
    const VALID_REACTUI_PAGES = [
      REACTUI_PAGES.AMENITIES_PAGE,
      REACTUI_PAGES.EXPLORE_TRANSPORTS_PAGE,
    ];

    const visibleComponents = pages
      ?.find((p) => p.name === PAGES_ENUM.INTERACTIVE_3D && !!p?.props?.visible)
      ?.modules?.find(
        (m) =>
          VALID_REACTUI_PAGES.includes(m?.props?.reactui_page) &&
          !!m?.props?.visible
      )
      ?.features?.find(
        (f) => f.name === FEATURES_ENUM.RIGHT_PANEL && !!f?.props?.visible
      )
      ?.components?.filter((c) => !!c?.props?.visible);

    if (!visibleComponents?.length) return <></>;

    return (
      <>
        <div className="h-full w-[285px] float-right border-none bg-white relative pointer-events-[all]">
          {visibleComponents?.some(
            (c) => c.name === COMPONENTS_ENUM.AMENITY_IMAGE
          ) && (
            <>
              <div className="absolute top-[127px] right-[15px] cursor-pointer z-[1000] pointer-events-auto">
                <img
                  src={"/icons/expand-image.svg"}
                  alt="modal-expand-image"
                  onClick={handleShowModalImage}
                />
              </div>
              <div className="pointer-events-auto">
                <img
                  src={getS3FEMediaUrl(modal?.image)}
                  alt="modal-image"
                  className="w-full h-[177px] object-cover"
                />
              </div>
            </>
          )}
          <div className="p-[30px_35px]">
            {
              visibleComponents?.some((c) => c.name === COMPONENTS_ENUM.AMENITY_NAME) &&
              <div className="font-domaine font-normal text-2xl text-[@242422] leading-[115%] uppercase mb-[30px]">
                {modal?.name}
              </div>
            }
            {
              visibleComponents?.some((c) => c.name === COMPONENTS_ENUM.AMENITY_DESCRIPTION) &&
              <div className="font-sans font-light text-base text-[#242422] leading-[24px]">
                {modal?.description}
              </div>
            }
          </div>
        </div>
        {visibleComponents?.some(
          (c) => c.name === COMPONENTS_ENUM.VIRTUAL_TOUR_LINK
        ) && (
          <div
            onClick={handleClickVirtualTour}
            className="absolute bottom-9 right-9 px-[22.5px] py-0 z-[1000] h-[60px] w-[13rem] flex items-center justify-between font-sans uppercase cursor-pointer !pointer-events-auto"
            style={{
              border: "1px solid #242422",
              color: "#242422",
              display: modal?.virtualTour ? "flex" : "none",
            }}
          >
            Virtual tour
            <img src={rightArrowIcon} alt="" />
          </div>
        )}
      </>
    );
  };

  const handleShowModalImage = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_AMENITY_MODAL_IMAGE);
    }
    dispatch(reqSetIsShowExpandAmenity(true));
  };

  const handleCloseModalImage = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_AMENITY_MODAL_IMAGE);
    }
    dispatch(reqSetIsShowExpandAmenity(false));
  };

  const handleClickWrapper = (clickedId) => {
    if (clickedId === "id-wrapper-right-panel") {
      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.CLICK_CLOSE_MODAL);
      }
      return onClose();
    }
  };

  const handleClickVirtualTour = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_AMENITY_VIRTUAL_TOUR);
    }
    dispatch(reqSetIsShowExploreModal(false));
    dispatch(reqSetIsShowAmenityVirtualTour(true));
  };

  return (
    <>
      <TransitionGroup>
        {isShowRightPanel && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <div
              id="id-wrapper-right-panel"
              className="absolute w-full h-full bg-transparent top-0 pointer-events-none"
              onClick={(e) => {
                e.stopPropagation();
                const clickedId = e.target.id;
                handleClickWrapper(clickedId);
              }}
            >
              <div
                className={classNames(
                  `absolute right-0 top-0 bottom-0 mt-auto mb-auto z-[100] w-[285px] h-[calc(100svh_-_122px_-_122px)]`,
                  reactuiPage === REACTUI_PAGES.EXPLORE_TRANSPORTS_PAGE ? 'h-[calc(100vh_-_260px)]' : ''
                )}
                onClick={(e) => handleButtonInContent(e)}
              >
                {renderModalContent()}
              </div>
            </div>
          </CSSTransition>
        )}
        {isShowAmenityVirtualTour && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <div>
              <VirtualModal isPresentation={isPresentation} />
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
      {isShowExpandAmenity && (
        <div
          className={
            "media-popup-gallery fixed inset-0 bg-[#333333e6] z-[1000]"
          }
        >
          <div className={"popup-gallery-wrapper h-full w-full relative"}>
            <div
              className={
                "popup-gallery-close absolute top-[50px] right-[50px] cursor-pointer w-[26px] h-[24px] z-[1002]"
              }
              onClick={handleCloseModalImage}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="24"
                viewBox="0 0 26 24"
                fill="none"
              >
                <path
                  d="M1 1L25 22.8182M25 1L1 22.8182"
                  stroke="white"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="container-image flex justify-center items-center w-full h-full bg-transparent">
              <img
                alt={"modal-image"}
                src={getS3FEMediaUrl(modal?.image)}
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RightPanel;
