import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import pageConfiguration from "../../../../../apis/api/page-configuration";
import { reqAddDefaultBGalleyComponent } from "../../../../../reduxs/page-configuration/action";
import InputField from "../../../components/commons/inputField";
import CheckboxField from "../../components/checkboxField";
import { Button } from "../../../components/commons";

const AddModule = ({ setIsShow, idPages }) => {
  const dispatch = useDispatch();
  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
  });

  const { control, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleOnSave = async (v) => {
    try {
      const resModule = await pageConfiguration.createModule(idPages, {
        name: v.name,
        props: {
          visible: v.visible,
        },
      });

      if (resModule.data) {
        dispatch(
          reqAddDefaultBGalleyComponent({
            idModule: resModule.data.id,
            nameModule: resModule.data.name,
            onSuccess: () => {
              toast.success("Add module successfully");
            },
          })
        );
        setIsShow(false);
      }
    } catch (e) {}
  };

  return (
    <div>
      <div className="mb-3">
        <InputField
          isRequired
          label="New module"
          controller={{
            name: `name`,
            control,
          }}
          inputProps={{ placeholder: "Name module" }}
        />
      </div>
      <div className="mb-3">
        <CheckboxField
          controller={{
            name: "visible",
            control,
          }}
          label="Visible"
          defaultChecked={true}
        />
      </div>
      <div className="flex gap-4 justify-center">
        <Button
          className="w-full"
          variant="text"
          onClick={() => setIsShow(false)}
        >
          Cancel
        </Button>
        <Button
          disabled={!formState.isDirty}
          className="w-full"
          onClick={handleSubmit(handleOnSave)}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddModule;
