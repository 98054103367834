import React from "react";

import { flexRender } from "@tanstack/react-table";
import { useTableContext } from "../baseTable";

const Footer = (props) => {
  const table = useTableContext();

  return (
    <tfoot {...props}>
      {table.getFooterGroups().map((footerGroup) => (
        <tr key={footerGroup.id} className="table-footer-row">
          {footerGroup.headers.map((header) => (
            <th
              key={header.id}
              className="table-footer-cell"
              colSpan={header.colSpan}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.footer,
                    header.getContext()
                  )}
            </th>
          ))}
        </tr>
      ))}
    </tfoot>
  );
};

export default Footer;
