/** @format */

import React, { useCallback, useState } from "react";
import { Modal } from "../components/commons";
import { getS3BEMediaUrl } from "../../../helper/media";
import uploadIcon from "../../../assets/images/upload.svg";
import closeIcon from "../../../assets/images/close-white.svg";
import { toast } from "react-toastify";
import mediaAPI from "../../../apis/api/media";
import { isEmpty } from "lodash";
import { MEDIA_TYPE } from "../utils";

const EditMediaContentModal = ({
  show,
  setShow,
  content,
  thumbnailContent,
  onSaveSuccess,
  isFromMediaFolder,
  mediaType,
}) => {
  const [isShowDeleteContentModal, setIsShowDeleteContentModal] =
    useState(false);
  const [videoFiles, setVideoFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [mediaName, setMediaName] = useState(content?.name);
  const [isShowLoading, setShowLoading] = useState(false);

  const handleCloseDeleteContentModal = () => {
    setIsShowDeleteContentModal(false);
  };

  const [isDragging, setIsDragging] = useState(false);
  const [isVideoDragging, setIsVideoDragging] = useState(false);

  const handleDragOver = (e, forVideo) => {
    e.preventDefault();
    forVideo ? setIsVideoDragging(true) : setIsDragging(true);
  };

  const handleDragLeave = (e, forVideo) => {
    forVideo ? setIsVideoDragging(false) : setIsDragging(false);
  };

  const handleDrop = (e, forVideo) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    if (forVideo && droppedFiles[0].type.startsWith("video")) {
      setVideoFiles(droppedFiles);
      return setErrors({ ...errors, videoFile: "" });
    } else if (forVideo && !droppedFiles[0].type.startsWith("video")) {
      return setErrors({ ...errors, videoFile: "Invalid file type" });
    } else if (!droppedFiles[0].type.startsWith("image")) {
      return setErrors({ ...errors, file: "Invalid file type" });
    } else {
      setErrors({ ...errors, file: "" });
      return setFiles(droppedFiles);
    }
  };

  const handleDeleteContent = async () => {
    // if (!content) return;
    // try {
    //   const deleted = await mediaAPI.deleteMedia(content.id);
    //   if (deleted.data) {
    //     await galleryApi.deleteGallery(gallery?.id);
    //     toast.success("Content deleted successfully!");
    //     handleOpenDeleteContentModal();
    //     handleClose && handleClose();
    //     onSaveSuccess && onSaveSuccess();
    //   }
    // } catch (err) {
    //   toast.error(err.message);
    // }
  };

  const handleFileSelect = (e, forVideo) => {
    const selectedFiles = Array.from(e.target.files);
    forVideo ? setVideoFiles(selectedFiles) : setFiles(selectedFiles);
  };

  if (isShowDeleteContentModal) {
    return (
      <Modal
        iconCancelMark={
          <div className="z-[10000] cursor-pointer fixed top-[50px] right-[80px]">
            <img
              src={closeIcon}
              alt="close-icon"
              onClick={handleCloseDeleteContentModal}
            />
          </div>
        }
        wrapperClassName="wrap-delete-content-modal"
        panelClassName="w-[521px] p-0 rounded-none"
        maskClassName="opacity-90"
        zIndex="z-[9998]"
        show={isShowDeleteContentModal}
        setShow={handleCloseDeleteContentModal}
        content={
          <div className="p-[40px] space-y-5">
            <h2 className="text-black text-[30px] font-[700] uppercase font-poppins">
              Delete Content?
            </h2>
            <div className="text-white relative flex flex-col gap-10 max-h-[calc(100vh_-_290px)] p-0 overflow-y-auto">
              <div className="font-proxima text-[14px] text-[#000] font-[400] leading-[17.5px]">
                Are you sure you want to delete {content?.name}?
              </div>
            </div>
            <div className="mt-5">
              <div
                onClick={handleDeleteContent}
                className="px-[18px] py-[12px] text-center  text-[#E11736] cursor-pointer font-[700] font-poppins text-[14px] border-[1px] border-solid border-[#E11736] uppercase w-full"
              >
                YES, DELETE
              </div>
            </div>
          </div>
        }
      />
    );
  }

  const handleClose = () => {
    setShow(false);
  };

  const renderImagePreview = useCallback(() => {
    if (files.length > 0) {
      return URL.createObjectURL(files[0]);
    }

    if (mediaType !== MEDIA_TYPE?.VIDEO && content.path) {
      return getS3BEMediaUrl(content.path);
    }

    if (thumbnailContent && thumbnailContent.path) {
      return getS3BEMediaUrl(thumbnailContent.path);
    }

    if (content?.thumbnailPath) {
      return getS3BEMediaUrl(content?.thumbnailPath);
    }

    return "/images/image.png";
  }, [files, content]);

  const renderVideoPreview = useCallback(() => {
    if (videoFiles.length) {
      return URL.createObjectURL(videoFiles[0]);
    }

    if (content.path) {
      return getS3BEMediaUrl(content.path);
    }
  }, [videoFiles]);

  const updateImageMediaPlayList = async () => {
    setShowLoading(true);
    try {
      const params = {
        name: mediaName,
      };

      if (files.length > 0) {
        const formData = new FormData();
        formData.append("file", files[0]);

        const uploaded = await mediaAPI.uploadMedia(formData);

        if (!uploaded?.data?.path) {
          throw new Error("Unable to upload image");
        }
        params.path = uploaded.data.path;
        params.fileName = uploaded.data.fileName;
      }

      const updated = await mediaAPI.updateMedia(content.id, params);

      if (updated?.data) {
        handleClose();
        toast.success("Content updated successfully!");
        onSaveSuccess && onSaveSuccess(updated?.data);
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
        handleClose();
      }
    } finally {
      setShowLoading(false);
    }
  };

  const updateVideoMediaPlaylist = async () => {
    setShowLoading(true);
    try {
      let updatedThumbnail;
      let updatedContent;

      if (files.length > 0) {
        const formDataThumbnail = new FormData();
        formDataThumbnail.append("file", files[0]);
        updatedThumbnail = await mediaAPI.uploadMedia(formDataThumbnail);
      }

      if (videoFiles.length > 0) {
        const formData = new FormData();
        formData.append("file", videoFiles[0]);
        updatedContent = await mediaAPI.uploadMedia(formData);
      }
      const isUpdatedThumbnail = files.length > 0;
      const isUpdatedContent = videoFiles.length > 0;
      let params = {
        name: mediaName,
        type: mediaType,
      };
      if (isUpdatedThumbnail) {
        params.thumbnailPath = updatedThumbnail?.data?.path;
      }
      if (isUpdatedContent) {
        params.path = updatedContent?.data?.path;
        params.fileName = updatedContent?.data?.fileName;
      }
      const res = await mediaAPI.updateMedia(content.id, params);
      if (res && res?.data) {
        toast.success("Content updated successfully!");
        handleClose();
        onSaveSuccess && onSaveSuccess(res?.data);
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
      toast.error("Upload media failed!");
    } finally {
      setShowLoading(false);
    }
  };

  const updateMediaPlayList = async () => {
    if (mediaType !== MEDIA_TYPE?.VIDEO) {
      updateImageMediaPlayList();
    } else {
      updateVideoMediaPlaylist();
    }
  };

  const onSave = () => {
    if (validateForm()) {
      return;
    }
    updateMediaPlayList();
  };

  const validateForm = () => {
    let hasErrors = Object.values(errors).find((error) => error);
    if (!mediaName) {
      setErrors({ ...errors, mediaName: "Media name is required" });
      hasErrors = true;
    }

    return !!hasErrors;
  };

  const onChangeMediaName = (value) => {
    if (!value) {
      setErrors({ ...errors, mediaName: "Media name is required" });
    } else {
      setErrors({ ...errors, mediaName: undefined });
    }

    setMediaName(value);
  };

  return (
    <Modal
      iconCancelMark={() => (
        <div className="z-[9999] cursor-pointer fixed top-[50px] right-[80px]">
          <img src={closeIcon} alt="close-icon" />
        </div>
      )}
      isLoading={isShowLoading}
      wrapperClassName="wrap-update-content-modal"
      panelClassName="w-[698px] p-0 rounded-none"
      zIndex="z-[9998]"
      maskClassName="opacity-90"
      show={show}
      setShow={handleClose}
      content={
        <div className="p-[40px] max-h-[90vh] overflow-y-scroll">
          <h2 className="text-black text-[30px] font-[700] uppercase font-poppins">
            Update Content
          </h2>
          {mediaType === MEDIA_TYPE.VIDEO && (
            <>
              <div
                className={`min-h-[330px] relative flex flex-col justify-center mt-4 mb-4 border-dashed border-2 border-gray-400 p-4 rounded-md ${
                  isVideoDragging ? "bg-gray-200" : "bg-white"
                }`}
                onDragOver={(e) => handleDragOver(e, true)}
                onDragLeave={(e) => handleDragLeave(e, true)}
                onDrop={(e) => handleDrop(e, true)}
              >
                <video
                  key={
                    videoFiles.length
                      ? URL.createObjectURL(videoFiles[0])
                      : "default"
                  }
                  className="absolute top-0 left-0 w-full img-fluid cursor-pointer h-[325px] object-cover"
                >
                  <source src={renderVideoPreview()} type="video/mp4" />
                </video>
                <input
                  type="file"
                  multiple
                  className="hidden"
                  id="video-upload"
                  onChange={(e) => handleFileSelect(e, true)}
                  accept={"video/*"}
                />
                <label
                  htmlFor="video-upload"
                  className="cursor-pointer z-[999] h-[325px] flex justify-center items-center"
                >
                  <div className="text-center flex flex-col items-center">
                    <p className="text-gray-600">
                      Drag & drop your videos here or click to upload
                    </p>
                    <img
                      src={uploadIcon}
                      alt="Upload"
                      className="w-20 h-20 relative"
                    />
                    {videoFiles.length > 0 && (
                      <ul className="mt-4">
                        {videoFiles.map((file, index) => (
                          <li key={index} className="text-sm text-gray-800">
                            {file.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </label>
              </div>
              <span className="text-[#ae6537] text-sm not-italic font-normal leading-[150%]">
                {errors?.videoFile}
              </span>
              <h3 className="text-black text-[20px] font-[600] uppercase font-poppins">
                THUMBNAIL
              </h3>
            </>
          )}
          <div
            className={`min-h-[330px] relative flex flex-col justify-center mt-4 mb-4 border-dashed border-2 border-gray-400 p-4 rounded-md ${
              isDragging ? "bg-gray-200" : "bg-white"
            }`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            {(!isEmpty(files) || content?.path || content?.thumbnailPath) && (
              <img
                className="absolute z-1 top-0 left-0 w-full img-fluid cursor-pointer h-[325px] object-cover"
                src={renderImagePreview()}
                alt=""
              />
            )}
            <input
              type="file"
              multiple
              className="hidden"
              id="file-upload"
              onChange={handleFileSelect}
              accept={"image/*"}
            />
            <label htmlFor="file-upload" className="cursor-pointer">
              <div className="text-center flex flex-col items-center">
                <p className="text-gray-600">
                  Drag & drop your files here or click to upload
                </p>
                <img
                  src={uploadIcon}
                  alt="Upload"
                  className="w-20 h-20 relative"
                />
                {files.length > 0 && (
                  <ul className="mt-4">
                    {files.map((file, index) => (
                      <li key={index} className="text-sm text-gray-800">
                        {file.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </label>
          </div>
          <span className="text-[#ae6537] text-sm not-italic font-normal leading-[150%]">
            {errors?.file}
          </span>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Media name *
            </label>
            <div className="mt-2 mb-4">
              <input
                onChange={(e) => onChangeMediaName(e.target.value)}
                value={mediaName}
                id="meida_name"
                name="meida_name"
                placeholder="Media name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <span className="text-red-500">{errors.mediaName}</span>
            </div>
          </div>
          <div className="flex justify-between mt-2 items-center">
            <span
              className="border-solid border-2 font-poppins font-[700] border-black text-center cursor-pointer m-0 text-[14px] px-[18px] py-[12px]"
              onClick={onSave}
            >
              SAVE
            </span>
          </div>
        </div>
      }
    />
  );
};

export default EditMediaContentModal;
