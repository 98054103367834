import closeIcon from "../../../assets/images/close-white.svg";
import React, { useEffect, useState } from "react";
import { parseResidenceData, validationResidenceSchema } from "./utils";
import ChevronDown from "../../../assets/images/chevron-down.svg";
import { Modal } from "../components/commons";
import { cn } from "../../../helper/utils";

const UpdateResidenceModal = ({ show, data, onSave, onHide }) => {
  const [formData, setFormData] = useState();
  const [isDirty, setIsDirty] = useState(false);
  const [errors, setErrors] = useState({});

  const formatNumber = (num) => {
    if (!num) return "";
    const number = parseFloat(num.replace(/,/g, ""));
    if (isNaN(number)) return "";
    return new Intl.NumberFormat().format(number);
  };

  useEffect(() => {
    if (show && data) {
      setFormData(
        parseResidenceData({ ...data, price: formatNumber(String(data.price)) })
      );
    }
  }, [show, data]);

  const handleChange = (value, field) => {
    if (field === "price") {
      value = formatNumber(value);
    }

    setFormData((currentData) => ({
      ...currentData,
      [field]: value,
    }));
    if (!isDirty) {
      setIsDirty(true);
    }
  };

  const handleSubmit = async () => {
    //reformat price
    const newForm = {
      ...formData,
      price: Number(formData.price.replace(/,/g, "")),
    };

    try {
      await validationResidenceSchema.validate(newForm, { abortEarly: false });
      setErrors({});
      if (!isDirty) return handleClose();
      await onSave(newForm);
      handleClose();
    } catch (error) {
      if (error.inner) {
        const errs = {};
        error.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
    }
  };

  const handleClose = () => {
    setFormData(undefined);
    setErrors({});
    setIsDirty(false);
    onHide();
  };

  return (
    <>
      {show && (
        <div className="absolute right-[3rem] top-[3rem] z-[9999] cursor-pointer">
          <img src={closeIcon} alt="close-icon" onClick={handleClose} />
        </div>
      )}
      <Modal
        maskClassName="bg-opacity-85"
        panelClassName="!w-[609px] p-0 rounded-none"
        zIndex="z-[9998]"
        maskClosable
        wrapperClassName={cn("bg-white")}
        show={show}
        setShow={handleClose}
        content={
          <div className="p-[40px] overflow-y-hidden">
            <div className="mb-[63px]">
              <span className="text-black font-poppins text-3xl font-bold uppercase">
                Edit residence
              </span>
            </div>
            <div className="wrap-form">
              <div className="flex justify-between mt-[13px] mb-[25px]">
                <div className="flex flex-col flex flex-col w-[48%]">
                  <label
                    className={cn(
                      "text-black font-poppins text-[14px] font-bold uppercase",
                      {
                        "text-[#e11736]": errors.name,
                      }
                    )}
                    htmlFor="name"
                  >
                    Residence*
                  </label>
                  <div className="relative w-full">
                    <p className="absolute top-2 p-0 m-0 text-black font-poppins text-sm font-normal">
                      Residence
                    </p>
                    <input
                      className={cn(
                        "pl-[76px]   text-sm font-poppins  appearance-none rounded-none border-t-0 border-l-0 border-r-0",
                        {
                          "text-[#e11736]": errors?.name,
                        }
                      )}
                      onChange={(e) => handleChange(e.target.value, "name")}
                      value={formData?.name}
                      id="name"
                      placeholder="Residence ####"
                    />
                  </div>

                  <span className="font-poppins text-sm font-normal text-[#e11736]">
                    {errors?.name}
                  </span>
                </div>
                <div className="flex flex-col w-[48%]">
                  <label
                    className={cn(
                      "text-black font-poppins text-[14px] font-bold uppercase",
                      {
                        "text-[#e11736]": errors.name,
                      }
                    )}
                    htmlFor="availabilityStatus"
                  >
                    Availability*
                  </label>
                  <div className="relative w-full">
                    <select
                      className={cn(
                        "bg-none appearance-none pl-0 pr-[8px] py-[13.5px] border-l-0 border-r-0 border-t-0 border-b-[1px] border-black font-poppins text-sm font-normal leading-[10px] text-black  w-full bg-white focus:outline-none focus:ring-0",
                        {
                          error: errors?.name,
                        }
                      )}
                      onChange={(e) =>
                        handleChange(e.target.value, "availabilityStatus")
                      }
                      id="availabilityStatus"
                      value={formData?.availabilityStatus}
                      placeholder="Available"
                    >
                      <option value="available">Available</option>
                      <option value="reserved">Reserved</option>
                      <option value="sold">Sold</option>
                      <option value="releasing_soon">Releasing Soon</option>
                    </select>
                    <img
                      className="absolute right-0 transform translate-y-[-50%] top-1/2"
                      src={ChevronDown}
                    />
                  </div>

                  <span className="font-poppins text-sm font-normal text-[#e11736]">
                    {errors?.availabilityStatus}
                  </span>
                </div>
              </div>
              <div className="flex justify-between mb-[25px]">
                <div className="flex relative flex-col w-[48%]">
                  <label
                    className={cn(
                      "text-black font-poppins text-[14px] font-bold uppercase",
                      {
                        "text-[#e11736]": errors.price,
                      }
                    )}
                    htmlFor="price"
                  >
                    Price*
                  </label>
                  <input
                    className={cn(
                      " text-sm font-poppins  appearance-none rounded-none border-t-0 border-l-0 border-r-0",
                      {
                        "text-[#e11736]": errors?.price,
                      }
                    )}
                    onChange={(e) => handleChange(e.target.value, "price")}
                    value={formData?.price}
                    id="price"
                    placeholder="$$$$$$"
                    style={{ paddingLeft: formData?.price ? "8px" : 0 }}
                  />
                  {formData?.price && (
                    <span className="absolute top-[28px] text-[#000]">$</span>
                  )}
                  <span className="font-poppins text-sm font-normal text-[#e11736]">
                    {errors?.price}
                  </span>
                </div>
                <div className="flex flex-col w-[48%]">
                  <label
                    className={cn(
                      "text-black font-poppins text-[14px] font-bold uppercase",
                      {
                        "text-[#e11736]": errors.level,
                      }
                    )}
                    htmlFor="level"
                  >
                    Floor*
                  </label>
                  <input
                    disabled={true}
                    className={cn(
                      " text-sm font-poppins  appearance-none rounded-none border-t-0 border-l-0 border-r-0",
                      {
                        "text-[#e11736]": errors?.level,
                      }
                    )}
                    onChange={(e) => handleChange(e.target.value, "level")}
                    value={formData?.level}
                    id="level"
                    placeholder="2"
                  />
                  <span className="font-poppins text-sm font-normal text-[#e11736]">
                    {errors?.level}
                  </span>
                </div>
              </div>
              <div className="flex justify-between mb-[25px]">
                <div className="flex flex-col  w-[48%] ">
                  <label
                    className={cn(
                      "text-black font-poppins text-[14px] font-bold uppercase",
                      {
                        "text-[#e11736]": errors.areaSqm,
                      }
                    )}
                    htmlFor="areaSqm"
                  >
                    sq. ft.*
                  </label>
                  <input
                    disabled={true}
                    className={cn(
                      " text-sm font-poppins  appearance-none rounded-none border-t-0 border-l-0 border-r-0",
                      {
                        "text-[#e11736]": errors?.areaSqm,
                      }
                    )}
                    onChange={(e) => handleChange(e.target.value, "areaSqm")}
                    value={formData?.areaSqm}
                    id="areaSqm"
                    placeholder="1354"
                  />
                  <span className="font-poppins text-sm font-normal text-[#e11736]">
                    {errors?.areaSqm}
                  </span>
                </div>
                <div className="flex flex-col w-[48%]">
                  <label
                    className={cn(
                      "text-black font-poppins text-[14px] font-bold uppercase",
                      {
                        "text-[#e11736]": errors.bedrooms,
                      }
                    )}
                    htmlFor="bedrooms"
                  >
                    Beds*
                  </label>
                  <input
                    disabled={true}
                    className={cn(
                      " text-sm font-poppins  appearance-none rounded-none border-t-0 border-l-0 border-r-0",
                      {
                        "text-[#e11736]": errors?.bedrooms,
                      }
                    )}
                    onChange={(e) => handleChange(e.target.value, "bedrooms")}
                    value={formData?.bedrooms}
                    id="bedrooms"
                    placeholder="5"
                  />
                  <span className="font-poppins text-sm font-normal text-[#e11736]">
                    {errors?.bedrooms}
                  </span>
                </div>
              </div>
              <div className="flex justify-between mb-[25px]">
                <div className="flex flex-col w-[48%]">
                  <label
                    className={cn(
                      "text-black font-poppins text-[14px] font-bold uppercase",
                      {
                        "text-[#e11736]": errors.bathrooms,
                      }
                    )}
                    htmlFor="bathrooms"
                  >
                    Baths*
                  </label>
                  <input
                    disabled={true}
                    className={cn(
                      " text-sm font-poppins  appearance-none rounded-none border-t-0 border-l-0 border-r-0",
                      {
                        "text-[#e11736]": errors?.bathrooms,
                      }
                    )}
                    onChange={(e) => handleChange(e.target.value, "bathrooms")}
                    value={formData?.bathrooms}
                    id="bathrooms"
                    placeholder="3"
                  />
                  <span className="font-poppins text-sm font-normal text-[#e11736]">
                    {errors?.bathrooms}
                  </span>
                </div>
                <div className="flex flex-col  w-[48%]">
                  <label
                    className={cn(
                      "text-black font-poppins text-[14px] font-bold uppercase",
                      {
                        "text-[#e11736]": errors.hasPool,
                      }
                    )}
                    htmlFor="hasPool"
                  >
                    Pool*
                  </label>
                  <div className="relative w-full">
                    <select
                      disabled={true}
                      className={cn(
                        "bg-none appearance-none pl-0 pr-[8px] py-[13.5px] border-l-0 border-r-0 border-t-0 border-b-[1px] border-black font-poppins text-sm font-normal leading-[10px] text-black  w-full bg-white focus:outline-none focus:ring-0",
                        {
                          error: errors?.name,
                        }
                      )}
                      onChange={(e) => handleChange(e.target.value, "hasPool")}
                      id="hasPool"
                      value={formData?.hasPool}
                      placeholder="Yes"
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                    <img
                      className="absolute right-0 transform translate-y-[-50%] top-1/2"
                      src={ChevronDown}
                    />
                  </div>
                  <span className="font-poppins text-sm font-normal text-[#e11736]">
                    {errors?.hasPool}
                  </span>
                </div>
              </div>
              <div className="flex flex-col w-full">
                <button
                  variant="text"
                  className="bg-[#fff] text-[#000] font-poppins text-sm font-bold uppercase border border-black px-[18px] py-3 transition duration-200 ease-in mt-[25px] hover:bg-black hover:text-white"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

export default UpdateResidenceModal;
