import React, { useEffect, useState } from "react";

const CmsPageHeader = (props) => {
  const { title } = props;

  return (
    <div className="page-header bg-black flex justify-center items-center pt-[36px] pb-[50px] h-[131px]">
      <h1 className="font-poppins font-bold text-[30px] leading-[45px] uppercase text-center text-white mb-0">
        {title}
      </h1>
    </div>
  );
};

export default CmsPageHeader;
