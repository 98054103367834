import TopNavCMS from "./cms-top-nav";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reqGetPageList } from "../../reduxs/page-configuration/action";

const CMS = (props) => {
  const dispatch = useDispatch();
  const { roles, authMiddleware } = props;
  const pagesRes = useSelector((state) => state.configuration.pages);
  const staticPage = pagesRes?.find((page) => page?.name === "static_page");
  const navigate = useNavigate();

  useEffect(() => {
    authMiddleware(roles, navigate);
  }, []);

  useEffect(() => {
    dispatch(reqGetPageList({}));
  }, []);

  return (
    <div className="min-h-[100dvh] bg-[#fff]">
      <TopNavCMS staticPage={staticPage} />
      {props.children}
    </div>
  );
};
export default CMS;
