/* eslint-disable no-unreachable */
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { routeOptions } from './options';
import PATH from './path';
import authApi from '../apis/api/auth';
import jwtAuth from '../apis/utils/jwtAuth';
import { useDispatch, useSelector } from 'react-redux';
import { reqGet3DSettings, reqGetPageList } from '../reduxs/page-configuration/action';
import { reqGetModalList } from '../reduxs/home/action';
import CMSContent from '../pages/cms/contents';
import CMSCustomers from '../pages/cms/customers';
import DynamicStaticPage from '../pages/cms/dynamic-static-page';
import { reqGetMeshes } from '../reduxs/scene/action';
import { CMSGallery } from '../pages/cms/gallery';

const redirectPath = '/holding';

export const Router = () => {
  const dispatch = useDispatch();

  const authUser = useSelector((state) => state.user.data);
  const pages = useSelector((state) => state.configuration.pages);
  const staticPage = pages?.find((page) => page?.name === "static_page");

  useEffect(() => {
    if (authUser) {
      dispatch(reqGetMeshes());
      dispatch(reqGet3DSettings());
      dispatch(reqGetPageList());
      dispatch(reqGetModalList());
    }
  }, [authUser]);

  const authMiddleware = async (routeRoles, navigate) => {
    if (routeRoles.length) {
      const isToken = await jwtAuth.checkToken();
      if (isToken) {
        return getUserProfile(routeRoles, navigate);
      } else {
        return navigate(redirectPath);
      }
    }
  };


  const getUserProfile = async (routeRoles, navigate) => {
    try {
      const res = await authApi.getMe();
      const userData = res?.data;
      if (routeRoles.length && !routeRoles.includes(userData?.userGroup?.name)) {
        return navigate(redirectPath);
      }
    } catch (error) {
      return navigate(redirectPath);
    }
  };

  const renderNestedRoutes = (item) => {
    if (item.subRoutes?.length) {
      return <>
        <Route path="content" element={<CMSGallery />} />
        <Route path="configuration" element={<CMSConfiguration />} />
        <Route path="faqs" element={<CMSFrequentlyAQS />} />
        <Route path="residence" element={<CMSResidence />} />
        <Route path="customers" element={<CMSCustomers />} />
        <Route path="amenities" element={<CMSAmenities />} />
        <Route path="analytics" element={<CMSAnalytics />} />
        <Route path="profile" element={<CMSProfile />} />
      </>;
    }
    return <></>
  }

  return (
    <BrowserRouter>
      <Routes>
        {routeOptions.map((item, index) => {
          return <Route
            key={index}
            path={item.path}
            exact={item.exact}
            element={!item.subRoutes?.length
              ? <item.component roles={item.roles} authMiddleware={authMiddleware} />
              : undefined
            }
          >
            {
              item.subRoutes?.map((subRoute, _i) => {
                return <Route
                  key={_i}
                  path={subRoute.path}
                  element={<item.component roles={item.roles} authMiddleware={authMiddleware}>
                    <subRoute.element />
                  </item.component>}
                />;
              })
            }
            {
              (item.path === PATH.CMS_PAGE && staticPage?.modules?.length) &&
              staticPage?.modules?.map((page, index) => (
                <Route
                  key={`cms-static-${index}`}
                  path={`/cms/${page?.props?.path}/:id`}
                  element={<item.component roles={item.roles} authMiddleware={authMiddleware}>
                    <DynamicStaticPage />
                  </item.component>}
                />
              ))
            }
          </Route>
        })}
        <Route path="*" element={() => <Navigate to={PATH.NOTFOUND_PAGE} replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
