export const validateEmail = (email) => {
  const re = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;

  return re.test(String(email).toLowerCase());
};

export const validatePassword = (password) => {
  const re = /^(?=.*\d)(?=.*[a-zA-Z-\#\$\.\%\&\*])(?=.*[a-zA-Z]).{8,}$/;

  return re.test(String(password).toLowerCase());
};

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}
