/** @format */

import React from "react";
import { COMPONENTS_ENUM, FEATURES_ENUM } from "../../../constants/modules";

const LocationInfo = (props) => {
  const {
    visible = false,
    htmlContent = ''
  } = props;
  if (!visible) return null;
  return (
    <div
      className="location-detail-wrapper w-full h-full"
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  )
};

const LocationDetailPanel = (props) => {
  const {
    pois,
    activePoi,
    visible = false,
    featureConfig = null,
  } = props;

  if (!visible || !activePoi) return <></>;

  const poi = pois.find((poi) => poi.id === activePoi);
  if (!poi.action_types.includes("panel")) return <></>;

  return (
    <div className="absolute overflow-hidden right-0 top-[122px] z-[100] flex w-[285px] px-[35px] py-[30px] flex-col items-start gap-[30px] shrink-0 bg-white border-[1px] border-[#24242226] h-[calc(100svh_-_122px_-_122px)]">
      <LocationInfo
        htmlContent={poi.panel.content}
        {...(featureConfig?.components?.find((c) => c.name === COMPONENTS_ENUM.DETAIL_LOCATION)?.props || {})}
      />
    </div>
  );
};
LocationDetailPanel.displayName = FEATURES_ENUM.DETAIL_POI;

export default LocationDetailPanel;
