import React from "react";

import { flexRender } from "@tanstack/react-table";
import _ from "lodash";
import { useTableContext } from "../baseTable";
import { cn } from "../../../../../helper/utils";

const Header = (props) => {
  const table = useTableContext();

  return (
    <thead {...props}>
      {table.getHeaderGroups().map((headerGroup) => {
        return (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  colSpan={header.colSpan}
                  style={{
                    minWidth: header.column.getSize(),
                  }}
                  className={cn(
                    "p-4 border-b border-gray-300 text-center text-sm font-semibold text-black/70",
                    "first:rounded-tl-md ",
                    "last:rounded-tr-md   bg-[#f9fafb]",
                    props?.classnamethead,
                    _.get(header.column.columnDef.meta, "className")
                  )}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      className={cn(
                        "flex gap-1 items-center text-sm font-semibold  bg-[#f9fafb]",
                        props?.thClassName,
                        _.get(header.column.columnDef.meta, "className")
                      )}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        );
      })}
    </thead>
  );
};

export default Header;
