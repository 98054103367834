import * as Types from './type';
import amenitiesApi from '../../apis/api/amenities';
import areaApi from '../../apis/api/area';

const actGetAmenitiesList = (data) => ({
  type: Types.GET_AMENITIES_LIST,
  data,
});

export const actSetActiveAmenityArea = (data) => ({
  type: Types.SET_ACTIVE_AMENITY_AREA,
  data,
});

export const actSetActiveAmenity = (data) => ({
  type: Types.SET_ACTIVE_AMENITY,
  data,
});

export const reqGetAmenitiesList = () => (dispatch) => {
  return amenitiesApi.getAmenitiesList().then((data) => {
    dispatch(actGetAmenitiesList(data));
  });
};

export const reqSetActiveAmenityArea = (id) => (dispatch) => {
  if (!id) return dispatch(actSetActiveAmenityArea(''));

  return areaApi.getAreaDetail(id).then((data) => {
    dispatch(actSetActiveAmenityArea(data));
  });
};

export const reqSetActiveAmenity = (id) => (dispatch) => {
  if (!id) return;

  return amenitiesApi.getAmenityDetail(id).then((data) => {
    dispatch(actSetActiveAmenity(data));
  });
};
