import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { reqGetPageList } from "../../../reduxs/page-configuration/action";
import { cn } from "../../../helper/utils";
import { convertLabelSetting } from "../configuration/utils";
import GalleryTypeA from "./typeA";
import GalleryTypeB from "./typeB";
import { actSetGallery } from "../../../reduxs/gallery/action";

export const CMSGallery = () => {
  const dispatch = useDispatch();
  const { pages: pagesRes, loading } = useSelector(
    (state) => state.configuration
  );
  const [page, setPage] = useState(null);

  useEffect(() => {
    dispatch(reqGetPageList({}));
  }, []);

  let galleryTypeA = useMemo(() => {
    const item = pagesRes?.find((v) => v?.name === "gallery_page_type_a");

    return {
      ...item,
      visiblePage: item?.props.visible,
      data: {
        ...item?.modules[0],
        tabs: item?.modules[0]?.features[0]?.components || [],
      },
    };
  }, [pagesRes]);

  const galleryTypeB = useMemo(() => {
    const item = pagesRes?.find((v) => v?.name === "gallery_page_type_b");

    return {
      ...item,
      visiblePage: item?.props.visible,
      data: item?.modules?.map((v) => ({
        ...v,
        tabs: v?.features[0]?.components || [],
      })),
    };
  }, [pagesRes]);

  const isShowGalleryTypeBLeftSide = galleryTypeB && galleryTypeB?.visiblePage;
  const isShowGalleryTypeALeftSide =
    galleryTypeA &&
    galleryTypeA?.visiblePage &&
    galleryTypeA?.data?.props?.visible;

  return (
    <div>
      <div className="page-header bg-black flex justify-center items-center pt-4 pb-4 h-12">
        <h1 className="font-poppins font-bold text-[30px] leading-[45px] uppercase text-center text-white mb-0">
          GALLERY
        </h1>
      </div>
      {!loading && (
        <div>
          {(isShowGalleryTypeALeftSide || isShowGalleryTypeBLeftSide) && (
            <div className="flex bg-[rgb(244,244,244)]">
              <div className="w-[300px] bg-white p-2 border-r-[1px] border-gray-300 h-[calc(100vh-80px)] overflow-y-auto">
                {isShowGalleryTypeALeftSide && (
                  <div
                    onClick={() =>
                      setPage((prev) =>
                        prev?.name === galleryTypeA?.name ? null : galleryTypeA
                      )
                    }
                    className={cn(
                      `flex w-full justify-between border-[1px] border-gray-300 mt-2  flex-1 p-2 mb-2 hover:cursor-pointer hover:bg-gray-300 rounded `,
                      {
                        "bg-gray-300": page?.name === galleryTypeA?.name,
                      }
                    )}
                  >
                    <p className="flex-1 ">Gallery A</p>
                  </div>
                )}
                {isShowGalleryTypeBLeftSide && (
                  <div>
                    <ul
                      className={cn(
                        `flex w-full items-center gap-3 justify-between  mt-2  flex-1 py-2 mb-2 `
                      )}
                    >
                      <li className="flex-1 px-2">Gallery B</li>
                    </ul>
                    {galleryTypeB?.data?.map((item, i) => (
                      <div
                        onClick={() => {
                          setPage((prev) =>
                            prev?.name === item?.name ? null : item
                          );
                          dispatch(
                            actSetGallery({
                              data: [],
                            })
                          ); // clear gallery
                        }}
                        key={item?.id}
                        className={cn(
                          "mb-2 hover:cursor-pointer hover:bg-gray-300 rounded p-2 border-[1px] border-gray-300",
                          {
                            hidden: !item?.props?.visible,
                          },
                          {
                            "bg-gray-300": item?.id === page?.id,
                          }
                        )}
                      >
                        <p className="capitalize">
                          {convertLabelSetting(item?.name)}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="flex-1 h-full  p-4">
                {page?.name === galleryTypeA?.name && (
                  <GalleryTypeA
                    typeAEntity={isShowGalleryTypeALeftSide && page?.data}
                  />
                )}
                {galleryTypeB?.data?.some((v) => v?.id === page?.id) && (
                  <GalleryTypeB typeBEntity={page} />
                )}
              </div>
            </div>
          )}
          {!isShowGalleryTypeALeftSide && !isShowGalleryTypeBLeftSide && (
            <div className="flex justify-center items-center h-[calc(100vh-80px)]">
              <p className="text-2xl font-medium">
                No gallery found. Please create one.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
