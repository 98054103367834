import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import InputField from "../../../../components/commons/inputField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "../../../../components/commons";

const ComponentForm = ({
  setIsShow,
  defaultValues,
  handleAppendComponent,
  handleUpdateComponent,
}) => {
  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    label: yup.string().required("Label is required"),
    order: yup.string().required("Order is required"),
  });

  const { control, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (defaultValues) {
      reset({
        name: defaultValues.name,
        label: defaultValues.props.label,
        order: defaultValues.props.order,
        visible: defaultValues.props.visible,
      });
    }
  }, [defaultValues]);

  const handleOnSave = (v) => {
    if (defaultValues) {
      handleUpdateComponent(v);
    } else {
      handleAppendComponent(v);
    }
  };

  return (
    <div>
      <div className="mb-4">
        {/*<div className="mb-3">*/}
        {/*  <InputField*/}
        {/*    isRequired*/}
        {/*    label="Filter name"*/}
        {/*    controller={{*/}
        {/*      name: `name`,*/}
        {/*      control,*/}
        {/*    }}*/}
        {/*    inputProps={{ placeholder: "Filter name" }}*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="mb-3">
          <InputField
            isRequired
            label="Order"
            controller={{
              name: `order`,
              control,
            }}
            inputProps={{ placeholder: "Order", type: "number" }}
          />
        </div>
        <div className="mb-3">
          <InputField
            isRequired
            label="Project label"
            controller={{
              name: `label`,
              control,
            }}
            inputProps={{ placeholder: "Name" }}
          />
        </div>
        {/*<div className="mb-3">*/}
        {/*  <CheckboxGroupField*/}
        {/*    controller={{*/}
        {/*      name: `visible`,*/}
        {/*      control,*/}
        {/*    }}*/}
        {/*    options={[{ label: "Visible", value: true }]}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      <div className="flex gap-4 justify-center">
        <Button
          className="w-full"
          variant="text"
          onClick={() => setIsShow(false)}
        >
          Cancel
        </Button>
        <Button
          disabled={!formState.isDirty}
          className="w-full"
          onClick={handleSubmit(handleOnSave)}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default ComponentForm;
