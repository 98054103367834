/** @format */

import * as Types from "./type";
import gallery from "../../apis/api/gallery";
import unitApi from "../../apis/api/unit";
import { actSetListUnits, actSetLoadingUnits } from "../cms/action";

const actGetGalleryList = (data) => ({
  type: Types.GET_GALLERY_LIST,
  data,
});

const actSetIsShowVirtualModal = (data) => ({
  type: Types.SET_IS_SHOW_VIRTUAL_MODAL,
  data,
});

export const actSetActiveGallery = (data) => ({
  type: Types.SET_ACTIVE_GALLERY,
  data,
});

export const actSetGallery = (data) => ({
  type: Types.GET_GALLERY,
  data,
});

const actGetGalleryLoading = (data) => ({
  type: Types.GET_GALLERY_LOADING,
  data,
});

export const reqGetGalleryList = () => (dispatch) => {
  return gallery.getListGallery().then((data) => {
    dispatch(actGetGalleryList(data));
  });
};

export const reqSetIsShowVirtualModal = (data) => (dispatch) => {
  dispatch(actSetIsShowVirtualModal(data));
};

export const reqSetActiveGallery = (galleryId, data) => (dispatch) => {
  if (!galleryId) return dispatch(actSetActiveGallery(false));

  return gallery.getGalleryDetail(galleryId, data).then((res) => {
    dispatch(actSetActiveGallery(res));
  });
};

const actSetDataStaticType1 = (data) => ({
  type: Types.SET_DATA_STATIC_TYPE_1,
  data,
});

const actSetDataStaticType2 = (data) => ({
  type: Types.SET_DATA_STATIC_TYPE_2,
  data,
});

export const reqSetDataStaticType1 = (data) => (dispatch) => {
  return dispatch(actSetDataStaticType1(data));
};

export const reqSetDataStaticType2 = (data) => (dispatch) => {
  return dispatch(actSetDataStaticType2(data));
};

export const reqGetGallery = (id, query) => (dispatch) => {
  dispatch(actGetGalleryLoading(true));
  return gallery
    .getGalleryDetail(id, query)
    .then((data) => {
      dispatch(actSetGallery(data));
      dispatch(actGetGalleryLoading(false));
    })
    .catch(() => {
      dispatch(actGetGalleryLoading(false));
    });
};

export const refetchGetGallery = (id, query) => (dispatch) => {
  return gallery
    .getGalleryDetail(id, query)
    .then((data) => {
      dispatch(actSetGallery(data));
    })
    .catch(() => {});
};
