import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  actSetCMSMediaContent,
  actSetListPlaylists,
  reqGetListPlaylists,
} from "../../../../reduxs/cms/action";
import FolderIcon from "../FolderIcon";
import icEdit from "../../../../assets/images/cms/edit.png";
import { cn } from "../../../../helper/utils";
import { Button, Modal } from "../../components/commons";
import EditPlayListForm from "../left-panel/EditPlayListForm";
import TrashIcon from "../../configuration/components/svgs/TrashIcon";
import playListApi from "../../../../apis/api/playlist";

const MenuItem = ({
  title,
  path,
  marginTop = false,
  subfix,
  folder,
  id,
  item,
}) => {
  const dispatch = useDispatch();
  const { mediaType } = useSelector((state) => state.cms);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleDelete = async ({ onSuccess, onError }) => {
    setLoadingDelete(true);
    try {
      const res = await playListApi.deletePlaylist(id);
      if (res) {
        toast.success("Delete playlist successfully");
        onSuccess && onSuccess();
        dispatch(reqGetListPlaylists());
        dispatch(actSetCMSMediaContent(""));
        setLoadingDelete(false);
      }
    } catch (error) {
      onError && onError();
      toast.error("Delete playlist failed");
      setLoadingDelete(false);
    }
  };

  return folder ? (
    <button className={`block`}>
      <FolderIcon
        title={title}
        isShowEdit
        item={item}
        onClick={() => dispatch(actSetCMSMediaContent(`${path}_${subfix}`))}
      />
    </button>
  ) : (
    <button
      className={cn(
        `w-full text-black flex-wrap flex justify-between items-center p-2 text-sm font-medium text-left bg-yellow-200 hover:bg-yellow-300 rounded`,
        {
          "mt-2": marginTop,
          "bg-yellow-300": mediaType === `${path}_${subfix}`,
        }
      )}
      onClick={() => dispatch(actSetCMSMediaContent(`${path}_${subfix}`))}
    >
      <div className="flex flex-wrap flex-1">
        <span>{title}</span>
        <span className="flex-[0_0_100%]">
          ({subfix?.charAt(0).toUpperCase() + subfix.slice(1)})
        </span>
      </div>
      <div className="flex items-center">
        <Modal
          maskClassName="bg-opacity-50"
          zIndex="z-[9998]"
          title="Edit playlist"
          maskClosable
          trigger={<img src={icEdit} alt="edit" className="w-12" />}
          content={({ setIsShow }) => {
            const playList = {
              id,
              title,
              type: subfix,
            };

            return (
              <EditPlayListForm
                onClose={() => setIsShow(false)}
                playList={playList}
              />
            );
          }}
        />
        <Modal
          maskClassName="bg-opacity-50"
          zIndex="z-[9998]"
          maskClosable
          trigger={
            <span className="text-red-500 cursor-pointer">
              <TrashIcon />
            </span>
          }
          content={({ setIsShow }) => (
            <div className="bg-white  w-full max-w-md">
              <h2 className="text-md text-black font-semibold text-center">
                Are you sure you want to delete this playlist?
              </h2>
              <div className="flex justify-center mt-6 space-x-4">
                <Button
                  disabled={loadingDelete}
                  className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300 text-black"
                  onClick={() => setIsShow(false)}
                >
                  Cancel
                </Button>
                <Button
                  disabled={loadingDelete}
                  isLoading={loadingDelete}
                  className="bg-yellow-200 text-black px-4 py-2 rounded hover:bg-yellow-300"
                  onClick={() =>
                    handleDelete({
                      onSuccess: () => {
                        setIsShow(false);
                        dispatch(
                          actSetListPlaylists({
                            data: [],
                          })
                        );
                      },
                      onError: () => setIsShow(false),
                    })
                  }
                >
                  Delete
                </Button>
              </div>
            </div>
          )}
        />
      </div>
    </button>
  );
};

export default MenuItem;
