import './App.scss';
import './i18n/i18n';
import React from 'react';
import store from './reduxs/store';
import { Provider } from 'react-redux';
import { Router } from './routers/Router';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <Router />
      <ToastContainer pauseOnFocusLoss={false} />
    </Provider>
  </QueryClientProvider>
);

export default App;
