import React from "react";
import {
    InfoWindow,
    GroundOverlay,
  } from "@react-google-maps/api";

const MapOverlay = ({poi, clearActivePoi, setActivePoi}) => {
    if (!poi.action_types.includes("overlay")) return <></>;
    return (
      <>
        <GroundOverlay
          url={poi.overlay.url}
          bounds={poi.overlay.bounds}
          opacity={0.5}
          onClick={() => {
            setActivePoi(poi);
          }}
          onDblClick={(e) => {
            e.domEvent.preventDefault();
          }}
        />
        {activePoi === poi.id && poi.action_types.includes("info_window") && (
          <InfoWindow
            onCloseClick={clearActivePoi}
            position={{
              lat: -37.81321293099207,
              lng: 144.93801607899866,
            }}
            options={{ closeBoxMargin: "100px 20px 2px 2px", padding: "100px" }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: poi.info_window.content }}
            />
          </InfoWindow>
        )}
      </>
    );
};

export default MapOverlay