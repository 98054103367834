/** @format */

import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { Button, InputField } from "../../components/commons";
import folderApi from "../../../../apis/api/folder";
import { actSetFolders } from "../../../../reduxs/cms/action";

const EditFolderForm = ({ onClose, item }) => {
  const folders = useSelector((state) => state.cms.folders);
  const dispatch = useDispatch();
  const validationSchema = yup.object().shape({
    label: yup.string().required("Label is required"),
    value: yup.string().required("Value is required"),
  });

  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      label: item.label,
      value: item.value,
    },
    resolver: yupResolver(validationSchema),
  });

  const handleEdit = async (v) => {
    try {
      const res = await folderApi.editFolder(item.id, {
        value: v.value,
        label: v.label,
      });
      if (res.data) {
        const newArray = [...folders];
        const objIndex = folders.findIndex((obj) => obj.id == item.id);
        newArray[objIndex] = { ...res.data };
        console.log("newArray", newArray);
        dispatch(actSetFolders(newArray));
        toast.success("Edit folder successfully");
      } else {
        toast.error("Edit folder failed");
      }
    } catch {
      toast.error("Edit folder failed");
    } finally {
      onClose();
    }
  };

  return (
    <div className="bg-white rounded-lg  relative">
      <div className="mb-5">
        <InputField
          required
          label="Label"
          controller={{ control, name: "label" }}
          inputProps={{ placeholder: "Please input label of folder" }}
        />
      </div>
      <div className="mb-5 opacity-[0.6]">
        <InputField
          disabled
          required
          label="Value"
          controller={{ control, name: "value" }}
          inputProps={{ placeholder: "Please input value of folder" }}
        />
      </div>
      <div className="flex gap-3 mt-8">
        <Button
          disabled={formState?.isSubmitting}
          variant="text"
          onClick={onClose}
          className="flex-1"
        >
          Cancel
        </Button>
        <Button
          disabled={formState?.isSubmitting || !formState?.isDirty}
          isLoading={formState?.isSubmitting}
          onClick={handleSubmit(handleEdit)}
          className="bg-yellow-200 text-black hover:bg-yellow-300 hover:text-black disabled:hover:bg-yellow-200 disabled:hover:text-black flex-1"
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditFolderForm;
