import React, {  } from "react";
import HoldingPageWrapper from "../../components/holding/holding-wrapper";
import FormForgotPassword from "../../components/auth/form-pass-forgot";

const ForgotPassword = () => {

  return (
    <HoldingPageWrapper>
      <FormForgotPassword />
    </HoldingPageWrapper>
  );
};

export default ForgotPassword;
