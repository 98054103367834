import React from 'react';
import FilterGroupRange from './sub-components/filter-group-range';
import { COMPONENTS_ENUM, SUB_COMPONENTS_TYPES_ENUM } from '../../../constants/modules';

const FilterPrice = (props) => {
  const {
    filterValue,
    onChange,
    visible = false,
    componentConfig = null,
    label = 'Filter Price',
    order = 0,
  } = props;

  if (
    !visible ||
    !componentConfig?.subComponents?.some(c => c?.props?.visible)
  ) return null;

  const renderSubComponents = () => {
    const checkboxes = componentConfig?.subComponents
      ?.filter((sComp) => sComp?.props?.type === SUB_COMPONENTS_TYPES_ENUM.RANGE)
      ?.sort((a, b) => a?.props?.order - b?.props?.order);

    return checkboxes?.map((item, key) => (
      <FilterGroupRange
        label={{
          title: label,
          min: 'MIN',
          max: 'MAX',
        }}
        filterValue={{
          min: filterValue?.min,
          max: filterValue?.max,
        }}
        icon="-----"
        max={20_000_000}
        onChange={onChange}
        customClass='flex flex-col !gap-[5px]'
        order={order}
        key={key}
        {...(item?.props || {})}
      />
    ))
  }

  return <>
    {renderSubComponents()}
  </>;
};
FilterPrice.displayName = COMPONENTS_ENUM.FILTER_PRICE;

export default FilterPrice;
