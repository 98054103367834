import * as type from "./type";

const initialState = {
  isScrollUnits: false,

  loading: false,
  _3dSettings: null,
  pages: [],
  modules: [],
  module: null,
  moduleLoading: false,
  features: [],
  featureLoading: false,
  units: [],
  unitsLoading: false,
  amenities: [],
  amenitiesLoading: false,
  transportOptions: [],
  transportOptionsLoading: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.IS_SCROLL_UNITS:
      return {
        ...state,
        isScrollUnits: action.data,
      };
    case type.LOADING:
      return {
        ...state,
        loading: action.data,
      };
    case type.FEATURE_LOADING:
      return {
        ...state,
        featureLoading: action.data,
      };
    case type.GET_3D_SETTINGS:
      return {
        ...state,
        _3dSettings: action.data,
      };
    case type.GET_PAGES:
      return {
        ...state,
        pages: action.data.data,
      };

    case type.GET_MODULES:
      return {
        ...state,
        modules: action.data.data,
      };

    case type.GET_MODULE_DETAIL:
      return {
        ...state,
        module: action.data.data,
      };

    case type.MODULE_LOADING:
      return {
        ...state,
        moduleLoading: action.data,
      };

    case type.GET_FEATURES:
      return {
        ...state,
        features: action.data.data,
      };

    case type.GET_UNIT_LIST:
      return {
        ...state,
        units: action.data.data,
      };

    case type.UNITS_LOADING:
      return {
        ...state,
        unitsLoading: action.data,
      };

    case type.GET_AMENITY_LIST:
      return {
        ...state,
        amenities: action.data.data,
      };

    case type.AMENITY_LOADING:
      return {
        ...state,
        amenitiesLoading: action.data,
      };

    case type.TRANSPORT_OPTION_LOADING:
      return {
        ...state,
        transportOptionsLoading: action.data,
      };

    case type.GET_TRANSPORT_OPTION_LIST:
      return {
        ...state,
        transportOptions: action.data.data,
      };
    default:
      return state;
  }
};
