import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { reqGetListUnits } from "../../../reduxs/cms/action";
import unitApi from "../../../apis/api/unit";
import { toast } from "react-toastify";
import _ from "lodash";

import { useIsMount } from "../../../helper/use-is-mount";
import UpdateResidenceModal from "./UpdateResidenceModal";
import useDebouncedSearch from "../../../hooks/useDebouncedSearch";
import UnitData from "./unitData";
import Header from "./Header";
import useQuery from "../../../hooks/useQuery";
import UnitContent from "./unitContent";

const CMSResidence = () => {
  const dispatch = useDispatch();
  const [isSortAsc, toggleSortAsc] = useState(true);
  const [selectedData, setSelectedData] = useState();

  const { query } = useQuery();

  const [search, onSearch] = useDebouncedSearch();

  const isMount = useIsMount();

  useEffect(() => {
    getListProperties();
  }, []);

  useEffect(() => {
    //reset search when tab changes
    if (query?.tab) onSearch("");
  }, [query?.tab]);

  useEffect(() => {
    if (!isMount) getListProperties();
  }, [search, isSortAsc, query?.tab]);

  const getListProperties = async () => {
    dispatch(
      reqGetListUnits(
        _.omitBy(
          {
            search,
            sortBy: JSON.stringify({
              name: isSortAsc ? 1 : -1,
            }),
          },
          _.isEmpty
        )
      )
    );
  };

  const handleUpdate = async (data) => {
    try {
      await unitApi.putUnitUpdate(data.id, data);
      await getListProperties();
      toast.success("Residence updated successfully");
    } catch (error) {}
  };

  return (
    <div>
      <UpdateResidenceModal
        show={!!selectedData}
        data={selectedData}
        onSave={handleUpdate}
        onHide={() => setSelectedData(undefined)}
      />
      <div className="bg-black py-5">
        <Header />
      </div>
      {(!query?.tab || query?.tab === "unit-data") && (
        <UnitData onSearch={onSearch} setSelectedData={setSelectedData} />
      )}
      {query?.tab === "unit-content" && (
        <UnitContent onSearch={onSearch} setSelectedData={setSelectedData} />
      )}
    </div>
  );
};

export default CMSResidence;
