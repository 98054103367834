import {
  setIsLoading,
  receiveEnvMap,
  actSetMeshList,
  receiveGLTFModels,
} from "../../reduxs/scene/action";
import * as THREE from "three";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLoader } from "@react-three/fiber";
import { getS3FEMediaUrl } from "../../helper/media";
import _3dSettingsApi from '../../apis/api/_3dSettings';
import configApi from "../../apis/api/page-configuration";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { actGet3DSettings } from "../../reduxs/page-configuration/action";
import { openDatabase, getFile, saveFile, deleteFile } from './GLTF3DCache';

const GLTF3DLoader = () => {
  const dispatch = useDispatch();

  const [envMap] = useLoader(THREE.TextureLoader, [
    getS3FEMediaUrl("/env_maps/env-map.jpg"),
  ]);

  envMap.mapping = THREE.EquirectangularReflectionMapping;

  const getListAccess = async () => {
    const meshes = await configApi.getMeshesList();
    const settings = await _3dSettingsApi.get3DSettings();
    const _3dSettings = settings.data[0];
    const { gltf_file_name: fileName, gltf_file_version: fileVersion } = _3dSettings;

    const initScene = (gltf) => {
      const models = gltf.scene.children.map(c => {
        const objName = String(c.children[0]?.name).toLowerCase();

        if (meshes && meshes.data) {
          const mesh = meshes.data.find(a => String(a.name).toLowerCase() === objName);
          if (mesh) {
            const userData = {
              alpha: mesh.alpha != null ? mesh.alpha / 100.0 : 1.0,
              hover_alpha: mesh.hover_alpha != null ? mesh.hover_alpha / 100.0 : 1,
              active_alpha: mesh.active_alpha != null ? mesh.active_alpha / 100.0 : 1.0,
              color: mesh.color ?? "#999999",
              hover_color: mesh.hover_color ?? mesh.color,
              active_color: mesh.active_color ?? mesh.color,
              isActive: !!c.children[0].userData?.isActive,
              layer: mesh.layer,
              emissive: mesh.emissive ?? "#000000",
              active_emissive: mesh.active_emissive ?? "#554e2d",
            };

            if (c.children[0].material) {
              if (mesh.roughness || mesh.roughness === 0) c.children[0].material.roughness = mesh.roughness; 
              if (mesh.metalness || mesh.roughness === 0) c.children[0].material.metalness = mesh.metalness; 
              if (mesh.opacity || mesh.roughness === 0) c.children[0].material.opacity = mesh.opacity; 
              if (mesh.color) c.children[0].material.color = new THREE.Color(mesh.color);
              if (mesh.emissive) c.children[0].material.emissive = new THREE.Color(mesh.emissive);
            }

            c.children[0].userData = userData;
          }
        }

        return {
          ...c,
          name: objName || "",
        }
      })
      dispatch(actSetMeshList(meshes));
      dispatch(actGet3DSettings(_3dSettings));
      dispatch(receiveGLTFModels(models));
      dispatch(setIsLoading(false));
    }

    let loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("/draco/");
    loader.setDRACOLoader(dracoLoader);

    const db = await openDatabase();
    const cachedFile = await getFile(db, fileName);

    if (cachedFile && cachedFile.version === fileVersion) {
      // Load model from cache
      const blobUrl = URL.createObjectURL(cachedFile.file);
      loader.load(blobUrl, (gltf) => {
        initScene(gltf);

        URL.revokeObjectURL(blobUrl);
      });

    } else {
      try {
        // Load models from network
        const response = await fetch(getS3FEMediaUrl(`/${fileName}`));
        const arrayBuffer = await response.arrayBuffer();
        const blob = new Blob([new Uint8Array(arrayBuffer)], { type: 'model/gltf-binary' });
        const blobUrl = URL.createObjectURL(blob);

        loader.load(blobUrl, async (gltf) => {
          initScene(gltf);

          URL.revokeObjectURL(blobUrl);
        })

        // Delete old cache, and save new file
        if (cachedFile) {
          await deleteFile(db, fileName);
        }

        await saveFile(db, fileName, blob, fileVersion);
      } catch (error) {
        console.error("Error fetching the model:", error);
      }
    }
  }

  useEffect(() => {
    getListAccess();
  }, []);

  useEffect(() => {
    dispatch(receiveEnvMap(envMap));
  }, [envMap]);
  return <></>;
};
export default GLTF3DLoader;
