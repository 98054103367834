export function openDatabase() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("glb-cache", 1);
  
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("glb-files")) {
          const store = db.createObjectStore("glb-files");
          store.createIndex("version", "version", { unique: false });
        }
      };
  
      request.onsuccess = (event) => {
        resolve(event.target.result);
      };
  
      request.onerror = (event) => {
        reject("Database error: " + event.target.errorCode);
      };
    });
  }
  
  export function getFile(db, key) {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(["glb-files"], "readonly");
      const objectStore = transaction.objectStore("glb-files");
      const request = objectStore.get(key);
  
      request.onsuccess = (event) => {
        resolve(event.target.result);
      };
  
      request.onerror = (event) => {
        reject("File retrieval error: " + event.target.errorCode);
      };
    });
  }
  
  export function saveFile(db, key, file, version) {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(["glb-files"], "readwrite");
      const objectStore = transaction.objectStore("glb-files");
      const request = objectStore.put({ file, version }, key);
  
      request.onsuccess = () => {
        resolve();
      };
  
      request.onerror = (event) => {
        reject("File saving error: " + event.target.errorCode);
      };
    });
  }
  
  export function deleteFile(db, key) {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(["glb-files"], "readwrite");
      const objectStore = transaction.objectStore("glb-files");
      const request = objectStore.delete(key);
  
      request.onsuccess = () => {
        resolve();
      };
  
      request.onerror = (event) => {
        reject("File deletion error: " + event.target.errorCode);
      };
    });
  }
  