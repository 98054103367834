import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { cn } from "../../../../helper/utils";
import Search from "../Search";
import useDebouncedSearch from "../../../../hooks/useDebouncedSearch";
import { Button, Modal } from "../../components/commons";
import PlaylistSelect from "../PlaylistSelect";
import {
  actSetGallery,
  reqGetGallery,
} from "../../../../reduxs/gallery/action";
import {
  getValuesAfterUnderscore,
  sortMediasByPlaylistOverride,
} from "../utils";
import Table from "./table";

const GalleryTypeA = ({ typeAEntity }) => {
  const dispatch = useDispatch();
  const [search, setSearch, loading] = useDebouncedSearch();
  const { gallery, galleryLoading } = useSelector((state) => state.gallery);
  const [galleryId, setGalleryId] = useState("");

  const isLoadingTable = galleryLoading || loading;

  const getGallery = async (id) => {
    await dispatch(reqGetGallery(id));
  };

  useEffect(() => {
    //clear gallery response
    dispatch(
      actSetGallery({
        data: [],
      })
    );
  }, []);

  useEffect(() => {
    setGalleryId("");
  }, [typeAEntity]);

  useEffect(() => {
    galleryId && getGallery(galleryId);
  }, [galleryId]);

  const tabs = useMemo(() => {
    return typeAEntity?.tabs?.map((v) => ({
      id: v?.id,
      label: getValuesAfterUnderscore(v?.name),
      name: !v?.name?.includes("_") ? v?.name : v?.name?.split("_")[1],
      visible: v?.props?.visible,
      type: v?.props?.type,
    }));
  }, [typeAEntity]);

  const medias = useMemo(() => {
    const newMedias = !search
      ? gallery?.playlist?.medias || []
      : gallery?.playlist?.medias?.filter((media) =>
          media?.name?.toLowerCase()?.includes(search?.toLowerCase())
        ) || [];

    return sortMediasByPlaylistOverride(newMedias, gallery?.playlistOverride);
  }, [gallery, search]);

  return (
    <div>
      <div className="flex justify-between gap-2 pb-4">
        {tabs?.map((tab, i) => (
          <Button
            key={`tab-${i}`}
            className={cn(
              "flex gap-2 flex-1 items-center bg-black py-2 text-white rounded capitalize hover:bg-white hover:text-black border-[1px] border-black",
              {
                hidden: !tab?.visible,
              },
              {
                "bg-white text-black": galleryId === tab?.id,
              }
            )}
            onClick={() => setGalleryId(tab?.id)}
          >
            <span className="flex-1 text-left">{tab?.label}</span>
            <Modal
              maskClosable
              maskClassName="bg-opacity-50"
              zIndex="z-[9998]"
              title="Select playlist"
              triggerClassName="h-auto"
              trigger={
                <span className="underline flex-1 text-xs">Link playlist</span>
              }
              content={({ setIsShow }) => (
                <PlaylistSelect
                  playListId={gallery?.playlist?.id}
                  isGallery={Boolean(gallery)}
                  setIsShow={setIsShow}
                  pageOfGalleryId={tab?.id}
                  typeGallery={tab?.type}
                />
              )}
            />
          </Button>
        ))}
      </div>
      <div className="bg-white rounded-lg p-2 ">
        <div className="flex justify-between items-baseline">
          <Search
            callbackOnInputChange={setSearch}
            wrapperClassName="flex justify-start h-fit mb-2"
          />
          <p className="font-semibold text-lg">
            {gallery?.playlist?.name ?? "None"} playlist
          </p>
        </div>
        <div className="right-content flex-1 overflow-hidden flex relative">
          <div className="overflow-y-scroll h-[calc(100vh-300px)] overflow-x-hidden">
            <Table
              isLoadingTable={isLoadingTable}
              gallery={gallery}
              medias={medias}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryTypeA;
