import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import settingModuleApi from "../../../../apis/api/page-configuration";
import {
  reqDeleteModule,
  reqGetFeatureList,
  reqGetPageList,
} from "../../../../reduxs/page-configuration/action";
import { cn } from "../../../../helper/utils";
import Table from "../components/Table";
import useColumn from "./useColumn";
import Switch from "../components/switch";
import { Modal, Button } from "../../components/commons";
import TrashIcon from "../components/svgs/TrashIcon";
import { toast } from "react-toastify";

const TableConfiguration = ({ moduleState, setModuleState, pageState }) => {
  const dispatch = useDispatch();
  const features = useSelector((state) => state.configuration.features);
  const isLoading = useSelector((state) => state.configuration.featureLoading);

  const isBGalleyModule = pageState?.label?.includes("Gallery Page Type B");

  const { columns } = useColumn({
    features,
    pageState,
    moduleState,
  });

  const dataSource = features?.length
    ? _.flatMap(features, (feature) =>
        feature.components
          ?.sort((a, b) => a?.props?.order - b?.props?.order)
          ?.map((component) => ({
            ...component,
            nameFeature: feature.name,
            idModule: moduleState?.id,
          }))
      )?.reduce((acc, curr) => {
        if (
          curr.props?.group &&
          acc.some((item) => item.props?.group === curr.props.group)
        ) {
          return acc;
        }
        return acc.concat(curr);
      }, [])
    : [];

  useEffect(() => {
    if (moduleState?.id) {
      dispatch(reqGetFeatureList({ idModule: moduleState?.id }));
    }
  }, [moduleState?.id]);

  const toggleVisibleModule = async (visible) => {
    try {
      const res = await settingModuleApi.updateModules(moduleState.id, {
        props: {
          visible,
        },
      });

      if (res.data) {
        await Promise.all([
          dispatch(reqGetPageList({})),
          dispatch(reqGetFeatureList({ idModule: moduleState?.id })),
        ]);

        setModuleState({
          ...moduleState,
          visible,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="mx-auto max-w-[1000px] 2xl:max-w-[1200px]">
      <div className="flex my-4 justify-between items-center">
        <div className="flex gap-4 items-center">
          <h2>{moduleState?.label}</h2>
          <Switch
            onChange={(e) => toggleVisibleModule(e.target.checked)}
            defaultChecked={moduleState.visible}
            checked={moduleState.visible}
            value={moduleState.value}
            id={`${moduleState?.id}`}
          />
        </div>
        {isBGalleyModule && (
          <Modal
            maskClassName="bg-opacity-50"
            zIndex="z-[9998]"
            triggerClassName="text-red-500"
            trigger={
              <Button className="text-red-500 border-red-500" variant="text">
                <TrashIcon />
              </Button>
            }
            content={({ setIsShow }) => (
              <div>
                <h2 className="text-center text-lg pb-3 font-medium">
                  Are you sure you want to delete this module ?
                </h2>
                <div className="flex gap-4 justify-center">
                  <Button variant="text" onClick={() => setIsShow(false)}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      dispatch(
                        reqDeleteModule(moduleState?.id, () => {
                          setIsShow(false);
                          toast.success("Delete successfully");
                          setModuleState(null);
                        })
                      );
                    }}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            )}
          />
        )}

        {/*<ModalCreateAndEdit*/}
        {/*  disabled={!moduleState?.visible}*/}
        {/*  bodyClassName="px-4"*/}
        {/*  trigger={*/}
        {/*    <Button disabled={!moduleState?.visible} variant="dark">*/}
        {/*      Add new component*/}
        {/*    </Button>*/}
        {/*  }*/}
        {/*  content={({ setIsShow }) => <ComponentForm setIsShow={setIsShow} />}*/}
        {/*/>*/}
      </div>
      <div className="relative w-full h-full">
        {moduleState && !moduleState?.visible && (
          <div className="absolute z-50 top-0 left-0 w-full h-full bg-transparent cursor-not-allowed" />
        )}
        <Table
          manualPagination
          loading={isLoading}
          cellClassName="first:bg-gray-200 first:text-black border-l-[1px] border-stone-200"
          wrapperClassName={cn(
            "rounded-md mt-1 bg-white overflow-y-auto h-[60vh] 2xl:h-[65vh] border-[1px] border-gray-300",
            {
              "h-fit":
                !!dataSource?.length && dataSource?.length <= 5 && !isLoading,
            }
          )}
          classnamethead="sticky top-0 z-10 bg-white"
          data={dataSource || []}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default TableConfiguration;
