import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Button, Modal } from "../../../components/commons";
import NewCreatingForm from "./new-creating-form";
import { getListInfoInThreeDCanvas } from "../../../../../reduxs/page-configuration/action";
import List from "./list";

const ContentSideCanvas = ({ pageState }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    pageState?.name === "canvas" &&
      (async () => await dispatch(getListInfoInThreeDCanvas()))();
  }, [pageState]);

  return (
    <div className=" h-[calc(100vh-132px-80px)]  overflow-y-scroll custom-scrollbar-10">
      <div className="mx-auto max-w-[1000px] 2xl:max-w-[1500px] my-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-medium">3D Canvas Schema</h2>
          <Modal
            maskClosable
            maskClassName="bg-opacity-50"
            trigger={<Button>GENERATE DATA</Button>}
            content={(props) => <NewCreatingForm {...props} />}
          />
        </div>
        <List />
      </div>
    </div>
  );
};

export default ContentSideCanvas;
