import React, { useEffect, useState, useRef } from "react";
import iconArrowBg from "../../../../assets/images/bg-arrow.svg";
import { getS3FEMediaUrl, getS3BEMediaUrl } from "../../../../helper/media";
import { IconSwitchLevels } from "../../../svgs/icons";
import socket from "../../../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../../constants/options";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export const ContentFloor = ({ data, onClick }) => {
  data.sort((a, b) => (a.name === "PH" ? -1 : Number(b.name) - Number(a.name)));
  return (
    <div className="inline-flex flex-col items-center w-full">
      <div className="text-black text-center text-xs not-italic font-normal leading-[13.9px] uppercase">the tower</div>
      <div className="w-5 h-px bg-[#242422] mt-4 mb-[130px]" />
      <div className="grid gap-2.5 grid-cols-4 w-[1030px]">
        {data.map((floor) => (
          <div
            className="flex w-[250px] h-[250px] justify-center items-center shrink-0 border flex-col cursor-pointer px-[18px] py-3 border-solid border-[rgba(36,36,34,0.15)]"
            key={floor.name}
            onClick={() => onClick(floor)}
          >
            {floor.name === "PH" ? (
              <div className="text-[#242422] text-[52px] not-italic font-normal leading-[95%] tracking-[-0.52px] uppercase">PH</div>
            ) : (
              <>
                <div className="text-[#242422] text-center text-xs not-italic font-normal leading-[normal] tracking-[1.2px] uppercase">FLOOR</div>
                <div className="text-[#242422] text-center text-[52px] not-italic font-normal leading-[95%] tracking-[-0.52px] uppercase mt-4">{floor.name}</div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
const ContentPlans = ({
  numberFloorSelected,
  onSwitchFloor,
  onShowGalleryData,
  data,
  imgList,
}) => {
  const selectedItem = data?.find((i) => i.name == numberFloorSelected);
  const selectedItemMedia =
    selectedItem?.media?.length > 0 ? selectedItem?.media[0] : {};
  const _hotspots = selectedItemMedia?.hotspots
    ? Array.isArray(selectedItemMedia?.hotspots)
      ? selectedItemMedia?.hotspots
      : Object.values(selectedItemMedia?.hotspots)
    : [];

  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const handleLoad = (event) => {
    const naturalWidth = event.target?.naturalWidth,
      naturalHeight = event.target?.naturalHeight;
    setImageSize({
      width: naturalWidth,
      height: naturalHeight,
    });
  };

  return (
    <div className="inline-flex flex-col items-center h-full">
      <div className="text-black text-center text-[52px] not-italic font-normal leading-[95%] tracking-[-0.52px] uppercase mt-[70px]">
        {numberFloorSelected === "PH"
          ? "PENTHOUSE"
          : `FLOOR ${numberFloorSelected}`}
      </div>
      <div className={`w-full h-[436.546px] shrink-0 relative mb-[100px] mt-[73.2px]`}>
        <img
          onLoad={handleLoad}
          src={getS3FEMediaUrl(selectedItemMedia?.path)}
          alt="plans-floor"
        />
        <div className="plans-marker">
          {_hotspots?.map((marker, index) => {
            return (
              <div
                key={String(index)}
                onClick={() => {
                  const key = `im-${marker.floorplan}`;
                  onShowGalleryData(
                    key,
                    imgList.findIndex((img) => img.key === key)
                  );
                }}
                style={{
                  position: "absolute",
                  left: marker.x,
                  top: marker.y,
                  cursor: "pointer",
                }}
              >
                <img
                  src={getS3FEMediaUrl(marker?.image)}
                  style={{ width: 40 }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="cursor-pointer inline-flex items-center gap-2.5 mt-auto mb-[41px]" onClick={() => onSwitchFloor()}>
        <IconSwitchLevels />
        <div className="text-black text-right text-sm not-italic font-normal leading-[30px] tracking-[1.4px] uppercase">SWITCH FLOORS</div>
      </div>
    </div>
  );
};

const FloorPlans = ({ data, isInfinity, isPresentation }) => {
  const [isShowFloorModal, setShowFloorModal] = useState(false);
  const [numberFloorSelected, setNumberFloorSelected] = useState("0");
  const [isFloorSelected, setIsFloorSelected] = useState(false);
  const [isShowViewPlans, setIsShowViewPlans] = useState(false);
  const refProgressMoveImg = useRef(false);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.SHOW_FLOOR_MODAL) {
      onShowFloorModal();
    }
    if (content.action === ACTION_NAME.HIDE_FLOOR_MODAL) {
      onHideFloorModal();
    }
    if (content.action === ACTION_NAME.SHOW_GALLERY_DATA) {
      onShowGalleryData(content.data.id, content.data.idx);
    }
    if (content.action === ACTION_NAME.SELECT_FLOOR) {
      onSelectedFloor(content.data.floor);
    }
    if (content.action === ACTION_NAME.SWITCH_FLOOR) {
      onSwitchFloor();
    }
    if (content.action === ACTION_NAME.SHOW_VIEW_PLAN) {
      handleShowViewPlan();
    }
    if (content.action === ACTION_NAME.CLOSE_VIEW_PLAN) {
      handleCloseViewPlans();
    }
    if (content.action === ACTION_NAME.CLOSE_RENDER_GALLERY) {
      onCloseImage();
    }
    if (content.action === ACTION_NAME.NEXT_IMAGE) {
      onMoveImagePresentation(content.data.imgList, content.data.idxActive);
    }
    if (content.action === ACTION_NAME.PREV_IMAGE) {
      onMoveImagePresentation(content.data.imgList, content.data.idxActive);
    }
    if (content.action === ACTION_NAME.ZOOM_OUT_FLOORPLAN) {
      if (!transformComponentRef.current) return;
      if (content.data.state) {
        const ratioHeight = window.screen.height / content.data.height;
        const ratioWidth = (window.screen.width * 0.7) / content.data.width;
        const marginWidth = ratioHeight > 1 ? 0.98 : 1.03;
        const marginHeight = ratioHeight > 1 ? 1.3 : 0.98;
        return transformComponentRef.current.setTransform(
          content.data.state.positionX * ratioWidth * marginWidth,
          content.data.state.positionY * ratioHeight * marginHeight,
          content.data.state.scale === 1
            ? 1
            : content.data.state.scale * (1 / marginWidth)
        );
      }
    }
  };
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const onHideFloorModal = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.HIDE_FLOOR_MODAL);
    }
    setShowFloorModal(false);
  };

  const onShowFloorModal = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_FLOOR_MODAL);
    }
    setShowFloorModal(true);
  };

  const [showGalleryData, setShowGalleryData] = useState({
    idxActive: 0,
    key: null,
  });
  const refCurrentIdxActive = useRef(0);
  const selectedItem = data?.find((i) => i.name === numberFloorSelected);
  const imgList =
    (Array.isArray(selectedItem?.floorplans) ? selectedItem?.floorplans : [])
      ?.filter((currentValue) => currentValue.isActive)
      .map((currentValue) => {
        return { image: currentValue?.path, key: `im-${currentValue?.id}` };
      }) || [];

  const lengthImgList = imgList?.length || 0;

  const onShowGalleryData = (id = "", idx) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_GALLERY_DATA, {
        id,
        idx,
      });
    }
    setShowGalleryData({
      key: id,
      idxActive: idx,
    });
    refCurrentIdxActive.current = idx;
  };

  const onSelectedFloor = (floor) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SELECT_FLOOR, {
        floor,
      });
    }
    setIsFloorSelected(true);
    setNumberFloorSelected(floor.name);
    setShowFloorModal(false);
  };

  const onSwitchFloor = (floor) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SWITCH_FLOOR, {
        floor,
      });
    }
    setIsFloorSelected(false);
    setNumberFloorSelected("");
    setShowFloorModal(false);
  };

  const handleShowViewPlan = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_VIEW_PLAN);
    }
    setIsShowViewPlans(true);
  };

  const handleCloseViewPlans = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_VIEW_PLAN);
    }
    setIsShowViewPlans(false);
  };

  const onNextImage = (data) => {
    if (refCurrentIdxActive.current === data?.length - 1) {
      setShowGalleryData({
        idxActive: 0,
        key: data[0].key,
      });
      refCurrentIdxActive.current = 0;
    } else {
      setShowGalleryData((v) => ({
        idxActive: v?.idxActive + 1,
        key: data[v?.idxActive + 1].key,
      }));
      refCurrentIdxActive.current += 1;
    }
    let timeout = setTimeout(() => {
      refProgressMoveImg.current = false;
      clearTimeout(timeout);
    }, 1000);
    transformComponentRef.current.resetTransform();
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.NEXT_IMAGE, {
        imgList,
        idxActive: refCurrentIdxActive.current,
      });
    }
  };

  const onMoveImagePresentation = (data, idxActive) => {
    setShowGalleryData({
      idxActive: idxActive,
      key: data[idxActive].key,
    });
    transformComponentRef.current.resetTransform();
  };

  const onPrevImage = (data) => {
    if (refCurrentIdxActive.current === 0) {
      let idx = data?.length - 1;
      setShowGalleryData({
        idxActive: idx,
        key: data[idx].key,
      });
      refCurrentIdxActive.current = idx;
    } else {
      setShowGalleryData((v) => ({
        idxActive: v?.idxActive - 1,
        key: data[v?.idxActive - 1].key,
      }));
      refCurrentIdxActive.current -= 1;
    }
    let timeout = setTimeout(() => {
      refProgressMoveImg.current = false;
      clearTimeout(timeout);
    }, 1000);
    transformComponentRef.current.resetTransform();
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PREV_IMAGE, {
        imgList,
        idxActive: refCurrentIdxActive.current,
      });
    }
  };

  const onCloseImage = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_RENDER_GALLERY);
    }
    setShowGalleryData({
      idxActive: 0,
      key: null,
    });
  };

  const handleSwipte = (state) => {
    if (
      state.scale <= 1.15 &&
      transformComponentRef.current &&
      !refProgressMoveImg.current
    ) {
      if (state.positionX <= -90) {
        refProgressMoveImg.current = true;
        transformComponentRef.current.resetTransform();
        onPrevImage(imgList);
      } else if (state.positionX >= 50) {
        refProgressMoveImg.current = true;
        transformComponentRef.current.resetTransform();
        onNextImage(imgList);
      }
    }
  };
  const transformComponentRef = useRef(null);

  const renderViewImage = () => {
    return (
      <TransformWrapper
        ref={transformComponentRef}
        maxScale={2.5}
        minPositionX={window.screen.width * 1.55 * -1}
        minPositionY={window.screen.height * 1.7 * -1}
        maxPositionX={window.screen.width * 1.55}
        maxPositionY={window.screen.height * 1.7}
        wheel={{
          step: 0.1,
        }}
        pinch={{
          step: 0.1,
        }}
        onTransformed={(ref, state) => {
          if (!isPresentation) {
            socket.emitUIActionEvent(ACTION_NAME.ZOOM_OUT_FLOORPLAN, {
              state,
              height: window.screen.height,
              width: window.screen.width,
            });
            handleSwipte(state);
          }
        }}
      >
        {({ zoomIn, zoomOut, resetTransform }) => (
          <>
            <div className="z-[1000] absolute top-0">
              <TransformComponent
                contentStyle={{ height: "100%", width: "100vw" }}
                wrapperStyle={{
                  height: "100%",
                  width: "100vw",
                  background: "black",
                }}
              >
                {imgList?.length ? (
                  imgList.map((item, key) => {
                    if (item.key === showGalleryData.key)
                      return (
                        <img
                          key={key}
                          src={getS3BEMediaUrl(item.image)}
                          alt="floorplan"
                          className="h-full"
                        />
                      );
                  })
                ) : (
                  <div className="h-full img-not-found">
                    Image Not Found
                  </div>
                )}
              </TransformComponent>
            </div>
          </>
        )}
      </TransformWrapper>
    );
  };

  return (
    <div
      className={`w-full flex flex-col items-center overflow-y-scroll pt-20 pb-[100px] ${isInfinity ? "infinity" : ""}`}
    >
      {isFloorSelected ? (
        <ContentPlans
          numberFloorSelected={numberFloorSelected}
          setShowFloorModal={onShowFloorModal}
          onShowGalleryData={onShowGalleryData}
          onSwitchFloor={onSwitchFloor}
          data={data}
          imgList={imgList}
        />
      ) : (
        <ContentFloor onClick={onSelectedFloor} data={data} />
      )}
      {showGalleryData?.key && renderViewImage()}
      {!!showGalleryData?.key && (
        <>
          {lengthImgList !== 1 && (
            <div>
              <div
                className="fixed z-[1001] w-10 h-[26px] top-[calc(50%_-_13px)] cursor-pointer left-[100px]"
                onClick={() => onPrevImage(imgList)}
              >
                <img src={iconArrowBg} alt="next" className="next-arrow w-[60px] h-[60px] rotate-180" />
              </div>
              <div
                className="fixed z-[1001] w-10 h-[26px] top-[calc(50%_-_13px)] cursor-pointer right-[100px]"
                onClick={() => onNextImage(imgList)}
              >
                <img src={iconArrowBg} alt="next" className="pre-arrow w-[60px] h-[60px]" />
              </div>
            </div>
          )}

          <div className="fixed z-[1055] cursor-pointer right-[50px] top-[50px]">
            <div className="cursor-pointer" onClick={onCloseImage}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="24"
                viewBox="0 0 26 24"
                fill="none"
              >
                <path
                  d="M1 1L25 22.8182M25 1L1 22.8182"
                  stroke="black"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default FloorPlans;
