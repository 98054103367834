import FolderIcon from "../FolderIcon";

const CollapsedMenuItem = ({
  item,
  openMenu,
  toggleMenu,
  index,
  marginTop = false,
  folder,
  title = "Media",
  isShowAdd,
}) => {
  return folder ? (
    <button className={`flex`} onClick={() => toggleMenu(index)}>
      <svg
        className={`w-8 h-8 transition-transform transform ${
          openMenu === index ? "rotate-180" : ""
        }`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="black"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
      <FolderIcon title={title} isShowAdd />
    </button>
  ) : (
    <button
      className={`w-full flex items-center justify-between p-2 text-sm font-medium text-left bg-yellow-200 hover:bg-yellow-300 rounded ${
        marginTop ? "mt-2" : ""
      }`}
      onClick={() => toggleMenu(index)}
    >
      <span className="text-black">{item.title}</span>
      <svg
        className={`w-4 h-4 transition-transform transform ${
          openMenu === index ? "rotate-180" : ""
        }`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="black"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </button>
  );
};

export default CollapsedMenuItem;