import React, { useEffect, useState } from "react";
import { getS3BEMediaUrl } from "../../../helper/media";
import PopupGallery from "../../media/popup-gallery";
import galleryApi from "../../../apis/api/gallery";
import {
  ACTION_NAME,
  GALLERY_TYPE,
  WEBSOCKET_CHANNEL,
} from "../../../constants/options";
import socket from "../../../helper/socket";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const Interiors = ({ isPresentation }) => {
  const [data, setData] = useState([]);
  const mediaData =
    data
      ?.map((item) => (item?.media?.length > 0 ? item?.media[0] : null))
      .filter((item) => item.isActive) || [];
  mediaData.sort((a, b) => (a?.order < b?.order ? -1 : 1));

  const [showGallery, setShowGallery] = useState(null);

  const loadData = async () => {
    try {
      const data = {
        "type[equal]": GALLERY_TYPE.MEDIA_IMAGES,
        "category[equal]": "interiors",
      };
      const res = await galleryApi.getListGalleryFloor(data);
      if (res) {
        setData(res?.data);
      }
    } catch (e) {
      console.log("error get floor");
    }
  };

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.SHOW_GALLERY) {
      handleShowGallery(content.data.idx);
    }
    if (content.action === ACTION_NAME.CLOSE_GALLERY) {
      handleCloseGallery();
    }
  };
  useEffect(() => {
    loadData();

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const handleShowGallery = (idx) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_GALLERY, {
        idx,
      });
    }
    setShowGallery(idx);
  };

  const handleCloseGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_GALLERY);
    }
    setShowGallery(false);
  };

  const getCssMediaItem = (position) => {
    if (position === 0) {
      return 'h-[496px] w-full flex-[1_0_0]'
    }
    if (position === 1) {
      return 'w-[351px] h-[496px]'
    }
    if (position === 2) {
      return 'w-full h-[718.914px]'
    }
  
  }

  return (
    <div className="interiors mx-[108px] my-[100px]">
      <div className="flex flex-col items-center gap-[5px]">
        {mediaData?.map((item, index) => {
          const position = index % 3;
          switch (position) {
            case 1:
              return null;
            case 0:
              return (
                <div className={"flex flex-row gap-[5px] justify-center items-center w-[1150px]"} key={index}>
                  <div
                    key={item.id}
                    onClick={() => handleShowGallery(item?.path)}
                    className={getCssMediaItem(position)}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      alt={item?.name}
                      src={getS3BEMediaUrl(item?.path)}
                    />
                  </div>
                  {mediaData[index + 1] && (
                    <div
                      key={mediaData[index + 1].id}
                      onClick={() =>
                        handleShowGallery(mediaData[index + 1]?.path)
                      }
                      className={getCssMediaItem(position)}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        alt={mediaData[index + 1]?.name}
                        src={getS3BEMediaUrl(mediaData[index + 1]?.path)}
                      />
                    </div>
                  )}
                </div>
              );
            case 2:
              return (
                <div className={"flex flex-row gap-[5px] justify-center items-center w-[1150px]"} key={index}>
                  <div
                    key={item.id}
                    onClick={() => handleShowGallery(item?.path)}
                    className={getCssMediaItem(position)}
                  >
                    <LazyLoadImage
                      className="w-full h-full object-cover"
                      alt={item?.name}
                      src={getS3BEMediaUrl(item?.path?.replace(".", "-thumb."))}
                      onError={(e) => {
                        e.currentTarget.src = getS3BEMediaUrl(item?.path);
                      }}
                    />
                  </div>
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
      <PopupGallery
        isPresentation={isPresentation}
        show={showGallery}
        listImage={mediaData}
        onClose={handleCloseGallery}
      />
    </div>
  );
};
