import React from "react";
import { useDispatch } from "react-redux";

import pageConfiguration from "../../../../../apis/api/page-configuration";
import { reqGetFeatureList } from "../../../../../reduxs/page-configuration/action";

const CellFeature = ({ rowComponent, feature, idModule }) => {
  const dispatch = useDispatch();

  const groupComponents = rowComponent.props?.group
    ? (feature?.components || []).filter(
        (c) => c.props?.group === rowComponent.props?.group
      )
    : [];
  const component = groupComponents.find((i) => i.feature === feature.id);
  const isContainFeature =
    rowComponent?.nameFeature === feature?.name || component;

  const visibleFeature = feature && !feature?.props?.visible;

  const toggleVisibleComponent = async (idComponent, visible) => {
    try {
      const res = await pageConfiguration.updateComponent(idComponent, {
        props: {
          visible,
        },
      });
      if (res) {
        await dispatch(reqGetFeatureList({ idModule }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex justify-center relative">
      {visibleFeature && (
        <div className="absolute top-0 right-0 w-full h-full cursor-not-allowed" />
      )}
      {isContainFeature ? (
        <input
          type="checkbox"
          className="w-5 h-5 rounded-[5px]"
          checked={
            component ? component?.props?.visible : rowComponent?.props?.visible
          }
          onChange={(e) =>
            toggleVisibleComponent(
              component ? component?.id : rowComponent?.id,
              e.target.checked
            )
          }
        />
      ) : (
        "-"
      )}
    </div>
  );
};

export default CellFeature;
