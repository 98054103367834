import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as PANOLENS from "panolens";
import { ImageLoader } from "three";

import { reqSetIsShowVirtualModal } from "../../reduxs/gallery/action";
import { getS3FEMediaUrl } from "../../helper/media";
import { fakeMediaImg360 } from "../../components/gallery-landing/mock/data";

const VirtualModal = (props) => {
  const dispatch = useDispatch();

  const modal = fakeMediaImg360; //useSelector((state) => state.gallery.activeGallery);

  const loader = new ImageLoader();
  const [panos, setPanos] = useState([]);
  const [viewer, setViewer] = useState(undefined);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    onInitPanorama();

    return () => {
      // viewer?.dispose();
    };
  }, [modal]);

  const handleClickCancel = () => {
    viewer?.dispose();
    dispatch(reqSetIsShowVirtualModal(false));
  };

  const onInitPanorama = () => {
    let selector = document.querySelector("#wrap-panorama");
    if (selector != undefined) {
      selector.replaceChildren();
    }
    let testPanos = [];
    if (modal == undefined || modal.media == undefined) {
      return;
    }
    const length = modal.media.length;
    if (length <= 0) {
      return;
    }

    for (let index = 0; index < length; index++) {
      testPanos.push(undefined);
    }
    setPanos(testPanos);

    const image1stURL = getS3FEMediaUrl(modal.media[0].path);
    loader.load(
      image1stURL,
      function (image) {
        let pano = new PANOLENS.ImagePanorama(image);

        panos[0] = pano;
        viewer.add(pano);
        viewer.setPanorama(pano);
        setPanos(panos);

        for (let index = 1; index < modal.media.length; index++) {
          const imageURL = getS3FEMediaUrl(modal.media[index].path);

          loader.load(
            imageURL,
            function (image) {
              let pano = new PANOLENS.ImagePanorama(image);

              panos[index] = pano;
              viewer.add(pano);
              setPanos(panos);
            },
            undefined,
            function (error) {
              console.error("An error happened.", error);
            }
          );
        }
      },
      undefined,
      function (error) {
        console.error("An error happened.", error);
      }
    );

    let viewer = new PANOLENS.Viewer({
      container: document.querySelector("#wrap-panorama"),
      controlButtons: [],
      autoHideInfospot: false,
    });
    viewer.camera.fov = 80;
    viewer.camera.updateProjectionMatrix();

    setViewer(viewer);
  };

  const onClickDotItem = (index) => {
    if (panos[index] == undefined) return;

    const pano = panos[index];
    setActiveIndex(index);
    viewer.setPanorama(pano);
  };

  const renderDotted = () => {
    return (
      <div className="w-full bg-transparent pointer-events-none flex justify-center">
        <div className="absolute z-[300] flex flex-row bottom-10">
          {(modal?.media || []).map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => onClickDotItem(index)}
                className={`w-5 h-5 bg-transparent cursor-pointer mr-5 rounded-[50%] border-[3px] border-solid  ${activeIndex === index ? "bg-white border-white" : " bg-transparent border-[#b8b5b8]"}`}
              />
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="bg-white h-[calc(100vh_-_99px)] w-screen absolute z-[901] text-center inline-block justify-center items-center left-0 top-[49px]">
        <div className="bg-white w-full h-[calc(100%_-_0px)] top-0">
          <div id="wrap-panorama" className="w-full h-full" />
          <button onClick={handleClickCancel} className="w-10 h-10 absolute cursor-pointer z-[100] p-0 border-0 right-10 top-10">
            <img className="img-fluid" src="/icons/close.svg" alt="" />
          </button>
          {renderDotted()}
        </div>
        <div className="black-content" />
      </div>
    </>
  );
};

export default VirtualModal;
