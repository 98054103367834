import SideNav from "../../../sidenav";
import React, { useEffect } from "react";
import socket from "../../../../helper/socket";
import ListUnits from './list-units';
import { useDispatch, useSelector } from "react-redux";
import * as unitExploreAct from "../../../../reduxs/unit-explore/action";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../../constants/options";
import { COMPONENTS_ENUM } from "../../../../constants/modules";

const UnitList = (props) => {
  const {
    visible = false,
    handleUnitClick,
    isPresentation,
  } = props;

  const dispatch = useDispatch();

  // is-show
  const isShowFilter = useSelector((state) => state.unitExplore.isShowFilter);
  
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, []);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.EXPLORE_SHOW_FILTER) {
      onShowFilter();
    }
  };

  const onShowFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.EXPLORE_SHOW_FILTER)
    }
    dispatch(unitExploreAct.reqSetIsShowFilter(true));
  }

  if (
    !visible ||
    isPresentation
  ) return null;  // BN-1775

  return (
    <div className={`float-start sticky left-0 flex`}>
      <SideNav
        id="lots"
        isDark={false}
        position="left"
        heading={"RESIDENCES"}
        className='rounded-r-md px-[25px] py-[30px] bg-white'
      >
        <ListUnits
          handleUnitClick={handleUnitClick}
          isPresentation={isPresentation}
        />
        <div className="absolute bottom-0 left-0 z-10 leading-none cursor-pointer pl-[25px] pb-[30px]">
          <span
            onClick={() => { !isShowFilter && onShowFilter() }}
            className={`font-sans text-[#242422] text-sm font-normal tracking-[1.4px] uppercase text-center mt-1 ${isShowFilter ? "opacity-25" : ""}`}
          >
            SHOW FILTERS
          </span>
        </div>
      </SideNav>
    </div>
  );
};
UnitList.displayName = COMPONENTS_ENUM.LIST_UNITS;

export default UnitList;
