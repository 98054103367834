/** @format */

import React from "react";
import { config } from "../map-config";
import { GoogleMap } from "@react-google-maps/api";
import _ from "lodash";
import MapPolygon from "./MapPolygon";
import MapMarker from "./components/MapMarker";
import MapOverlay from "./MapOverlay";
import { FEATURES_ENUM } from "../../../constants/modules";

const Map = React.forwardRef((props, ref) => {
    const {
      onMapLoaded,
      onZoomChanged,
      onCenterChanged,
      activeCategory,
      activePoi,
      setActivePoi,
      activeMarkers,
      handleClickPoint,
      pois,
      activeType,
      clearActivePoi,
      // props for modularization
      visible = false,
      featureConfig = null,
    } = props;

    if (!visible) return null;

    return (
      <GoogleMap
        ref={ref}
        mapContainerStyle={{ width: "100%", height: "100%" }}
        center={config.center}
        zoom={config.zoom}
        onLoad={(map) => {
          onMapLoaded(map);
        }}
        options={{
          maxZoom: 18,
          minZoom: 2.5,
          disableDefaultUI: true,
          disableDoubleClickZoom: true,
          isFractionalZoomEnabled: true,
        }}
        onZoomChanged={onZoomChanged}
        onCenterChanged={onCenterChanged}
      >
        {pois.map((poi, index) => {
          if (
            (poi?.visibility?.default && activeType === "") ||
            poi?.visibility?.categories?.includes(activeType)
          )
            return (
              <React.Fragment key={index}>
                <MapMarker
                  poi={poi}
                  activeCategory={activeCategory}
                  activePoi={activePoi}
                  activeMarkers={activeMarkers}
                  handleClickPoint={handleClickPoint}
                  {...featureConfig?.components?.find((aComp) => aComp.name === MapMarker.displayName)?.props || {}}
                />
                <MapPolygon poi={poi} />
                <MapOverlay
                  poi={poi}
                  clearActivePoi={clearActivePoi}
                  setActivePoi={setActivePoi}
                />
              </React.Fragment>
            );
        })}
      </GoogleMap>
    );
  }
);
Map.displayName = FEATURES_ENUM.MAP_2D;

export default Map;
