/** @format */

import MediaDetail from "./content-detail";
import LeftPanel from "./left-panel";

const Media = () => {
  return (
    <section className="flex h-[calc(100vh-80px)] bg-[rgb(244,244,244)] p-4 gap-4">
      <LeftPanel />
      <div className="relative bg-white rounded-lg right-content flex-1 px-10 py-12 overflow-hidden">
        <MediaDetail />
      </div>
    </section>
  );
  };

export default Media;
