import _ from "lodash";

export const convertLabelSetting = (label, isCapitalize = true) => {
  if (!label) return;
  let words = _.split(label, "_");

  let capitalizedWords = isCapitalize ? _.map(words, _.capitalize) : words;

  let result = _.join(capitalizedWords, " ");

  return result;
};

export const REGEX = {
  NUMBER_INT: /^(100|[1-9]\d?)$/,
};

export const COMMON_MESSAGE = {
  NUMBER_INT: "Must be a number between 1 and 100",
};
