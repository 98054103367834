/** @format */

import React from "react";
import { ArrowCircleIcon, ArrowCircleLightIcon } from "../../svgs/icons";


const Tabs = ({ subTabActive, tabActive, onClickTab, onClickSubTab, modules }) => {
  const listComponents = (modules?.[tabActive]?.features?.[0]?.components || [])?.sort((a, b) => a?.props?.order - b?.props?.order)
  return (
    <div className="w-full h-fit flex justify-center items-center flex-col">
      <div className="inline-flex justify-center items-start gap-[50px]">
        {modules.map((t, idx) => {
          const isActive = idx === tabActive;
          if (t.props.visible) {
            return (
              <div
                key={`tab-${idx}`}
                className={`cursor-pointer flex flex-row items-center gap-2.5`}
                style={{ width: t?.width }}
                onClick={() => onClickTab(idx)}
              >
                <span className={`text-[#c7a446] text-right text-base font-light leading-[140%] ${isActive ? " text-[#c7a446] text-right text-base font-medium leading-[140%]" : ""}`}>
                  {t.name}
                </span>
                {isActive ? (
                  <ArrowCircleIcon style={{ width: 22, height: 22 }} />
                ) : (
                  <ArrowCircleLightIcon style={{ width: 22, height: 22 }} />
                )}
              </div>
            );
           }
        })}
      </div>
      <div className="inline-flex items-start mt-[30px] px-0 py-1">
        {listComponents.map((s, idx) => {
          const isActive = idx === subTabActive;
          if (s.props.visible) {
            return (
              <div
                key={`subTab-${idx}`}
                className="cursor-pointer"
                onClick={() => onClickSubTab(idx)}
              >
                <div className={`px-5 py-0`}>
                  <span
                    className={`text-white text-center text-base font-light ${isActive ? "active" : ""}`}
                  >
                    {s.props?.label}
                  </span>
                </div>
                <div className="h-0.5 flex flex-col justify-center">
                  <div
                    className={`bg-white ${
                      isActive ? "h-0.5" : "h-px"
                    }`}
                  />
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default Tabs;
