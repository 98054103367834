/** @format */

import React, { Fragment } from "react";

import {
  Dialog,
  Transition,
  TransitionChild,
  DialogPanel,
} from "@headlessui/react";
import { cn } from "../../../../helper/utils";
import Loading from "../../configuration/components/loading";

const Modal = (props) => {
  const {
    setShow,
    show,
    title,
    zIndex = "z-[70]",
    content,
    maskClosable = false,
    panelClassName = "w-[500px]",
    titleClassName,
    wrapperClassName,
    maskClassName,
    onToggle,
    classCustom,
    isLoading,
    triggerOnShow,
  } = props;
  const [isShow, setIsShow] = React.useState(false);

  const onShow = () => {
    !show && setIsShow(true);
    onToggle && onToggle(true);
    triggerOnShow && triggerOnShow();
  };

  const onClose = () => {
    setShow ? setShow(false) : setIsShow(false);
    onToggle && onToggle(false);
  };

  return (
    <Fragment>
      {props.trigger && (
        <div onClick={onShow} className={cn("h-full", props.triggerClassName)}>
          {typeof props.trigger === "function"
            ? props.trigger({ isShow, setIsShow })
            : props.trigger}
        </div>
      )}
      <Transition.Root show={show || isShow} as={Fragment}>
        <Dialog
          as="div"
          className={`relative ${zIndex} `}
          onClose={() => !maskClosable && !isLoading && onClose()}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={cn(
                "fixed inset-0 bg-black transition-opacity",
                maskClassName
              )}
            />
          </TransitionChild>

          <div
            className={cn("fixed inset-0 z-10 overflow-y-auto", classCustom)}
          >
            {typeof props?.iconCancelMark === "function"
              ? props?.iconCancelMark(isShow, setIsShow)
              : props?.iconCancelMark}
            {isLoading && (
              <div className="w-full h-full z-[9999] left-0 top-0 absolute bg-[rgba(0,0,0,0.5)] flex justify-center items-center pointer-events-none">
                <Loading width="w-10" height="h-10" />
              </div>
            )}

            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel
                  className={cn(
                    " relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-4 text-left shadow-xl transition-all sm:my-8 sm:p-6",
                    panelClassName
                  )}
                >
                  {title && (
                    <div className="text-center">
                      <div
                        className={cn(
                          "text-lg  font-semibold pb-4 text-left ",
                          titleClassName
                        )}
                      >
                        {title}
                      </div>
                    </div>
                  )}
                  <div className={wrapperClassName}>
                    {typeof content === "function"
                      ? content({ isShow, setIsShow })
                      : content}
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  );
};

export default Modal;
