/** @format */

export const GET_GALLERY_LIST = "GET_GALLERY_LIST";

export const GET_GALLERY = "GET_GALLERY";
export const GET_GALLERY_LOADING = "GET_GALLERY_LOADING";
export const SET_IS_SHOW_VIRTUAL_MODAL = "SET_IS_SHOW_VIRTUAL_MODAL";
export const SET_ACTIVE_GALLERY = "SET_ACTIVE_GALLERY";
export const SET_DATA_STATIC_TYPE_1 = "SET_DATA_STATIC_TYPE_1";
export const SET_DATA_STATIC_TYPE_2 = "SET_DATA_STATIC_TYPE_2";
