import React from "react";

import { flexRender } from "@tanstack/react-table";
import { cn } from "../../../../../helper/utils";
import { useTableContext } from "../baseTable";

const Body = ({
  loading,
  empty,
  renderExpandedRow,
  rowClassName,
  cellClassName,
  ...props
}) => {
  const table = useTableContext();

  return (
    <tbody {...props}>
      {!loading && table.getRowModel().rows.length === 0 && (
        <tr>
          <td
            colSpan={table.getAllFlatColumns().length}
            className="min-h-[200px]"
          >
            {<div>{empty}</div>}
          </td>
        </tr>
      )}

      {table.getRowModel().rows.map((row, index) => {
        return (
          <React.Fragment key={row.id}>
            <tr
              key={row.id}
              className={cn(
                "table-body-row bg-white border-b-[1px] border-solid last:border-0",
                {
                  "table-body-row-selected": row.getIsSelected(),
                  "table-body-row-expanded": row.getIsExpanded(),
                },
                rowClassName
              )}
            >
              {row.getVisibleCells().map((cell, index) => (
                <td
                  key={cell.id}
                  className={cn("p-4 text-sm cell", cellClassName)}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>

            {row.getCanExpand() && (
              <tr className="table-body-sub-row">
                <td
                  colSpan={row.getVisibleCells().length}
                  className={cn("p-0", cellClassName)}
                >
                  {row.getIsExpanded() && renderExpandedRow?.(row)}
                </td>
              </tr>
            )}
          </React.Fragment>
        );
      })}
    </tbody>
  );
};

export default Body;
