import React from 'react';
import FormResetPassword from '../../components/auth/form-pass-reset';
import HoldingPageWrapper from '../../components/holding/holding-wrapper';

const ResetPassword = () => {

  return (
    <HoldingPageWrapper>
      <FormResetPassword />
    </HoldingPageWrapper>
  );
};

export default ResetPassword;
