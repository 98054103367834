import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import PopupGallery from "../media/popup-gallery";
import {
  reqSetActiveGalleryId,
  reqSetIsTransparent,
  reqSetPage,
} from "../../reduxs/home/action";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import {
  ACTION_NAME,
  REACTUI_PAGES,
  WEBSOCKET_CHANNEL,
} from "../../constants/options";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import socket from "../../helper/socket";

const VirtualTour = ({ isPresentation, isHidden }) => {
  const dispatch = useDispatch();
  const unit = useSelector((state) => state.unitExplore.selectedUnit);
  const reactUiPage = useSelector((state) => state.home.reactUiPage);

  const isShowViewLine = useSelector(
    (state) => state.unitExplore.isShowViewLine
  );

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.ENABLE_TRANSPARENT_MODE) {
      enableTransparent();
    }
    if (content.action === ACTION_NAME.DISABLE_TRANSPARENT_MODE) {
      showFilter();
    }
    if (content.action === ACTION_NAME.CLOSE_UNIT_VIEWLINE) {
      hideViewLines();
    }
  };
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const showFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.DISABLE_TRANSPARENT_MODE);
    }
    dispatch(reqSetIsTransparent(false));
  };

  const hideViewLines = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_VIEWLINE);
    }
    dispatch(unitExploreAct.reqIsShowViewLine(false));
    dispatch(reqSetIsTransparent(false));
    dispatch(reqSetActiveGalleryId(""));

    if (reactUiPage === REACTUI_PAGES.VIEWS_LEVEL)
      dispatch(reqSetPage(REACTUI_PAGES.VIEWS_PAGE));
    else dispatch(reqSetPage(REACTUI_PAGES.UNIT_EXPLORER_PAGE));
  };

  const enableTransparent = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.ENABLE_TRANSPARENT_MODE);
    }
    dispatch(reqSetIsTransparent(true));
  };

  return (
    <>
      <div className={`w-screen absolute text-center inline-block z-[111] left-0 top-0 ${isShowViewLine ? '' : 'hidden'}`}>
        <PopupGallery
          isPresentation={isPresentation}
          listImage={unit?.view?.media}
          onClose={hideViewLines}
          isClientImages={true}
          show={isShowViewLine}
          isShowPreNextSlide
          isShowViewLine={isShowViewLine}
          nameUnit={unit?.name}
        >
          {/*<div className="viewlines-note">*/}
          {/*  *THESE VIEWLINE IMAGES ARE PER FLOOR*/}
          {/*</div>*/}
        </PopupGallery>
      </div>
    </>
  );
};

export default VirtualTour;
