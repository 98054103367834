import React, { Fragment } from "react";
import { useController } from "react-hook-form";

import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react";
import _ from "lodash";
import { cn } from "../../../../helper/utils";

import ChevronRightIcon from "./svgs/ChevronRightIcon";
import ChevronDownIcon from "./svgs/ChevronDownIcon";

const SelectField = (props) => {
  const {
    label,
    labelClassName,
    isRequired,
    controller,
    wrapperClassName,
    ...rest
  } = props;
  const { fieldState, field } = useController(controller);
  const { error } = fieldState;

  const onChange = (v) => {
    field.onChange(v);
    rest?.callbackOnChange && rest?.callbackOnChange(v);
  };

  return (
    <Fragment>
      {label && (
        <legend
          className={cn(
            "text-gray-800 mb-2 text-sm font-medium",
            labelClassName
          )}
        >
          {label} {isRequired && <span className="text-red-500">*</span>}
        </legend>
      )}
      <Listbox
        name={field.name}
        value={field.value || null}
        onChange={onChange}
      >
        {({ open }) => {
          const openOptions =
            typeof rest?.open === "boolean" ? rest?.open : open;

          return (
            <Fragment>
              <div
                className={cn("relative w-full", wrapperClassName)}
                onClick={() =>
                  typeof rest?.open === "boolean" && rest?.onOpen(!rest?.open)
                }
              >
                <ListboxButton
                  className={cn(
                    "relative w-full disabled:cursor-not-allowed text-black text-left rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600",
                    rest?.wrapperOptionsClassName,
                    {
                      "border-red-500": !!error,
                    }
                  )}
                  disabled={rest?.disabled}
                >
                  {({ value }) => (
                    <>
                      {value?.name || (
                        <span className="text-[#6a7380]">
                          {rest?.placeholder}
                        </span>
                      )}
                      <span className="absolute top-1/2 right-3 -translate-y-1/2">
                        {openOptions ? (
                          <ChevronRightIcon className="size-4" />
                        ) : (
                          <ChevronDownIcon className="size-4" />
                        )}
                      </span>
                    </>
                  )}
                </ListboxButton>
                <Transition
                  show={openOptions}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <ListboxOptions
                    anchor="bottom start"
                    className={cn(
                      "w-[var(--button-width)] z-50 mt-1 overflow-auto rounded-md bg-white text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm  overflow-y-auto",
                      {
                        "h-[150px]": rest.options?.length >= 4,
                      }
                    )}
                  >
                    {!!rest.options?.length &&
                      rest.options.map((option) => (
                        <ListboxOption
                          key={option.id}
                          value={option}
                          className="data-[focus]:bg-blue-100 px-3 py-2 cursor-pointer relative"
                        >
                          {option.name}
                        </ListboxOption>
                      ))}
                    {!rest.options?.length && (
                      <div className="text-center py-5">No data</div>
                    )}
                  </ListboxOptions>
                </Transition>
              </div>
            </Fragment>
          );
        }}
      </Listbox>
      {error && (
        <div>
          <p className={error ? "text-red-500 text-xs" : ""}>
            {_.get(error, "message")}
          </p>
        </div>
      )}
    </Fragment>
  );
};

export default SelectField;
