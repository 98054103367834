import { get } from 'lodash';
import * as yup from 'yup';

export const parseResidenceData = (data) => {
  return {
    id: get(data, 'id'),
    name: get(data, 'name'),
    level: get(data, 'level'),
    availabilityStatus: get(data, 'availabilityStatus'),
    areaSqm: get(data, 'areaSqm'),
    bedrooms: get(data, 'bedrooms'),
    bathrooms: get(data, 'bathrooms'),
    price: get(data, 'price'),
    hasPool: get(data, 'hasPool'),
  };
};

export const validationResidenceSchema = yup.object().shape({
  name: yup.string().trim().required('RESIDENCE is required'),
  level: yup.string().trim().required('FLOOR is required'),
  availabilityStatus: yup.string().trim().required('AVAILABILITY is required'),
  areaSqm: yup.number().required('SQ. FT. is required'),
  bedrooms: yup.number().required('BEDS is required'),
  bathrooms: yup.number().required('BATHS is required'),
  price: yup.number().required('PRICE is required'),
  hasPool: yup.string().trim().required('POOL is required'),
});
