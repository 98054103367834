/** @format */

export const SET_IS_SHOW_LIST_INVESTOR_MODAL =
  "SET_IS_SHOW_LIST_INVESTOR_MODAL";

export const SET_IS_SHOW_LIST_TENANT_MODAL = "SET_IS_SHOW_LIST_TENANT_MODAL";

export const SET_IS_SHOW_LIST_CONSULTANT_MODAL =
  "SET_IS_SHOW_LIST_CONSULTANT_MODAL";

export const SET_IS_SHOW_CREATE_INVESTOR_PROFILE_MODAL =
  "SET_IS_SHOW_CREATE_INVESTOR_PROFILE_MODAL";

export const SET_IS_SHOW_CREATE_TENANT_PROFILE_MODAL =
  "SET_IS_SHOW_CREATE_TENANT_PROFILE_MODAL";

export const SET_IS_SHOW_CREATE_CONSULTANT_PROFILE_MODAL =
  "SET_IS_SHOW_CREATE_CONSULTANT_PROFILE_MODAL";

export const SET_USER_SELECT_TYPE = "SET_USER_SELECT_TYPE";

export const SET_VIDEO_MUTED = "SET_VIDEO_MUTED";
