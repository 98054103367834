import React from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { Button, Modal } from "../../../components/commons";
import EditIcon from "../../components/svgs/EditIcon";
import ComponentForm from "./setting-component/component-form";
import TrashIcon from "../../components/svgs/TrashIcon";
import pageConfiguration from "../../../../../apis/api/page-configuration";
import { reqGetFeatureList } from "../../../../../reduxs/page-configuration/action";

const ActionRow = ({ field }) => {
  const dispatch = useDispatch();
  const visible = field?.props?.visible;

  const deleteComponent = async (component, onSuccess) => {
    try {
      const res = await pageConfiguration.deleteComponent(component.id);
      if (res) {
        toast.success("Delete component success");
        dispatch(reqGetFeatureList({ idModule: component?.idModule }));
        onSuccess && onSuccess();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateComponent = async (component, formValue, onSuccess) => {
    try {
      const res = await pageConfiguration.updateComponent(component.id, {
        name: formValue.name,
        props: {
          visible: formValue.visible,
          label: formValue.label,
          order: formValue.order,
        },
      });
      if (res) {
        toast.success("Update component success");
        dispatch(reqGetFeatureList({ idModule: component?.idModule }));
        onSuccess && onSuccess();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex items-center justify-center gap-3">
      <Modal
        maskClassName="bg-opacity-50"
        zIndex="z-[9998]"
        bodyClassName="px-4"
        trigger={
          <Button variant="text" className="text-blue-500 border-blue-500">
            <EditIcon />
          </Button>
        }
        content={({ setIsShow }) => (
          <ComponentForm
            defaultValues={field}
            setIsShow={setIsShow}
            handleUpdateComponent={(v) =>
              updateComponent(field, v, () => setIsShow(false))
            }
          />
        )}
      />
      <Modal
        maskClassName="bg-opacity-50"
        zIndex="z-[9998]"
        trigger={
          <Button variant="text" className="text-red-500 border-red-500">
            <TrashIcon />
          </Button>
        }
        content={({ setIsShow }) => (
          <div>
            <h2 className="text-center text-lg pb-3 font-medium">
              Are you sure you want to delete this filter ?
            </h2>
            <div className="flex gap-4 justify-center">
              <Button variant="text" onClick={() => setIsShow(false)}>
                Cancel
              </Button>
              <Button
                onClick={() => deleteComponent(field, () => setIsShow(false))}
              >
                Confirm
              </Button>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default ActionRow;
