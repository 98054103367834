/** @format */

import React from "react";
import Panorama from "../../../pages/360-pano/panorama";
import { useDispatch } from "react-redux";
import { reqSetIsTransparent } from "../../../reduxs/home/action";
import { ACTION_NAME } from "../../../constants/options";
import CMSExtraLeft from "../../../assets/images/cms-extra-left.svg";
import CMSExtraRight from "../../../assets/images/cms-extra-right.svg";
import classNames from "classnames";
import socket from "../../../helper/socket";
import * as unitExploreAct from "../../../reduxs/unit-explore/action";

const Gallery360 = ({ isPresentation, isShow360, isTransparent }) => {
  const dispatch = useDispatch();
  const showFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.DISABLE_TRANSPARENT_MODE);
    }
    dispatch(reqSetIsTransparent(false));
  };
  const enableTransparent = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.ENABLE_TRANSPARENT_MODE);
    }
    dispatch(reqSetIsTransparent(true));
  };

  const hide360 = () => {
    if (!isTransparent) {
      enableTransparent();
    } else {
      handleCloseBtn();
    }
  };

  const handleCloseBtn = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_360);
    }
    dispatch(unitExploreAct.reqIsShow360(false));
    dispatch(reqSetIsTransparent(false));
  };
  const renderPanorama = () => {
    const names = [
      "Location 1",
      "Location 2",
      "Location 3",
      "Location 4",
      "Location 5",
      "Location 6",
      "Location 7",
      "Location 8",
    ];

    const images = [
      "/uploads/panorama/day_night/PANO0021_Panorama_Day_0.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Day_1.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Day_2.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Day_3.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Day_4.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Day_5.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Day_6.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Day_7.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Night_33.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Night_34.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Night_35.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Night_36.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Night_37.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Night_38.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Night_39.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Night_40.jpg",
    ];

    return (
      <Panorama
        name={"Location 1"}
        isPresentation={isPresentation}
        names={names}
        images={images}
      />
    );
  };
    

  return (
    <div className="wrap-floor-plan-gallery">
      {isShow360 && renderPanorama()}
      <div className="absolute z-[90] flex gap-5 justify-center items-center w-full mx-auto bottom-[100px]">
        {isShow360 && (
          <>
            {isTransparent && (
              <div>
                <img src={CMSExtraLeft} alt="cms-extra-left" />
              </div>
            )}
            <div
              onClick={hide360}
              className={classNames({
                "m-none": !isTransparent,
              })}
            >
              <span className="text-[white] font-semibold cursor-pointer">{isTransparent ? "CLOSE 360" : "CLOSE PANELS"}</span>
            </div>
            {!isTransparent && (
              <div>
                <img
                  src={CMSExtraRight}
                  alt="cms-extra-right"
                  className={classNames({
                    "ml-0": !isTransparent,
                  })}
                />
              </div>
            )}
          </>
        )}
        {isTransparent && (
          <>
            <div onClick={showFilter}>
              <span className="text-[white] font-semibold cursor-pointer">{"REOPEN PANELS"}</span>
            </div>
            <div>
              <img src={CMSExtraRight} alt="cms-extra-right" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(Gallery360);
