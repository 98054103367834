import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, RadioGroupField } from "../components/commons";
import { useSelector } from "react-redux";
import { typeSection } from "./useForm";

const options = [
  {
    label: "Left Image",
    value: typeSection.left_image,
  },
  {
    label: "Right Image",
    value: typeSection.right_image,
  },
  {
    label: "Multi Left Image",
    value: typeSection.multiple_left_image,
  },
  {
    label: "Multi Right Image",
    value: typeSection.multiple_right_image,
  },
];

const AddSection = ({ setIsShow, addSection }) => {
  const valueRes = useSelector((state) => state.configuration.module);
  const isTemplate1 = valueRes?.props?.type === "template-1";

  const { control, formState, reset, handleSubmit } = useForm({});

  useEffect(() => {
    reset({
      type: null,
    });
  }, []);

  const handleAddSection = (v) => {
    addSection(v.type);
    setIsShow(false);
  };

  const filteredOptions = !isTemplate1
    ? _.filter(
        options,
        (option) =>
          option.value !== typeSection.multiple_left_image &&
          option.value !== typeSection.multiple_right_image
      )
    : options;

  return (
    <div>
      <h1 className="font-semibold text-xl mb-4">Add Section</h1>
      <RadioGroupField
        controller={{
          control,
          name: "type",
        }}
        wrapperClassName={"justify-start gap-5"}
        options={filteredOptions}
      />
      <div className="flex gap-3 mt-5">
        <Button
          variant="text"
          className="flex-1"
          onClick={() => setIsShow(false)}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(handleAddSection)}
          disabled={!formState.isDirty}
          className="flex-1"
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default AddSection;
