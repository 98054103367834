import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { toast } from "react-toastify";
import closeIcon from "../../../assets/images/close-white.svg";
import amenitiesAPI from "../../../apis/api/amenities";
import { Modal } from "../components/commons";

const validationSchema = yup.object().shape({
  name: yup.string().trim().required("Name is a required field"),
  description: yup.string().trim().required("Description is a required field"),
});

const EditAmenityModal = ({ amenity, show, setShow, onSaveSuccess }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (amenity) {
      setName(amenity.name);
      setDescription(amenity.description);
    }
  }, [amenity]);

  const handleUpdateAmanity = async () => {
    if (!amenity) return;

    try {
      const data = {
        name,
        description,
      };
      setErrors({});
      const result = await validationSchema.validate(data, {
        abortEarly: false,
      });
      const res = await amenitiesAPI.updateAmenity(amenity.id, result);
      if (res.data) {
        toast.success("Amenity updated successfully!");
        handleClose && handleClose();
        onSaveSuccess && onSaveSuccess();
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal
      iconCancelMark={() => (
        <div className="z-[9999] cursor-pointer fixed top-[50px] right-[80px]">
          <img src={closeIcon} alt="close-icon" />
        </div>
      )}
      wrapperClassName="wrap-update-amenity-modal"
      panelClassName="w-[698px] p-0 rounded-none"
      zIndex="z-[9998]"
      maskClassName="opacity-90"
      show={show}
      setShow={handleClose}
      content={
        <div className="p-[40px] modal-content">
          <h2 className="text-black text-[30px] font-[700] uppercase font-poppins">
            Update Amenity
          </h2>
          <div className="wrap-modal-body">
            <div className="flex flex-wrap mb-[15px]">
              <label className="w-full text-[14px] font-bold mb-[15px] uppercase text-[#000] font-poppins">
                Amenity Name*
              </label>
              <input
                id="name"
                name="name"
                value={name}
                className="w-full focus-visible:outline-none !focus-visible:shadow-none h-10 border-b-[1px] border-solid border-[#000] font-poppins text-[14px] font-[400] text-[#000] "
                placeholder="Amenity Name"
                onChange={(e) => setName(e.target.value)}
              />
              <span className="error">{errors?.name}</span>
            </div>
            <div className="flex flex-wrap mb-[15px]">
              <label className="w-full text-[14px] font-bold mb-[15px] uppercase text-[#000] font-poppins">
                Description*
              </label>
              <textarea
                id="description"
                name="description"
                value={description}
                className="w-full focus-visible:outline-none !focus-visible:shadow-none h-10 border-b-[1px] border-solid border-[#000] font-poppins text-[14px] font-[400] text-[#000] "
                placeholder="Description"
                onChange={(e) => setDescription(e.target.value)}
              />
              <span className="error">{errors?.description}</span>
            </div>
          </div>
          <div
            className="flex justify-center items-center flex-1 h-[37px] px-[18px] py-[12px] m-0 gap-[10px] border border-black font-poppins text-[14px] font-bold leading-[10px] hover:text-white hover:bg-black hover:cursor-pointer"
            onClick={handleUpdateAmanity}
          >
            Save
          </div>
        </div>
      }
    />
  );
};

export default EditAmenityModal;
