import React from 'react';
import FilterCheckbox from './sub-components/filter-checkbox';
import { COMPONENTS_ENUM, SUB_COMPONENTS_TYPES_ENUM } from '../../../constants/modules';

const FilterPool = (props) => {
  const {
    filterValue,
    onChange,
    visible = false,
    componentConfig = null,
    label = 'Filter Pool',
    order = 0,
  } = props;

  if (
    !visible ||
    !componentConfig?.subComponents?.some(c => c?.props?.visible)
  ) return null;

  const renderSubComponents = () => {
    const checkboxes = componentConfig?.subComponents
      ?.filter((sComp) => sComp?.props?.type === SUB_COMPONENTS_TYPES_ENUM.CHECKBOX)
      ?.sort((a, b) => a?.props?.order - b?.props?.order);

    return checkboxes?.map((item, key) => (
      <FilterCheckbox
        item={item}
        filterValues={filterValue}
        onChange={(checked, item, filterValue) => onChange(item?.value, filterValue)}
        className='w-[61px]'
        key={key}
        {...(item?.props || {})}
      />
    ))
  }

  return (
    <div className={`filter-group flex flex-col items-start self-stretch gap-[25px]`}>
      <h2 className="font-normal text-[#242422] font-sans text-[14px] leading-[12px] tracking-[1.4px] uppercase mb-0">
        {label}
      </h2>
      <ul className="flex flex-row justify-content-between flex-wrap w-full pl-3">
        {renderSubComponents()}
      </ul>
    </div>
  );
};
FilterPool.displayName = COMPONENTS_ENUM.FILTER_POOL;

export default FilterPool;
