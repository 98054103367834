/** @format */

import ApiClient from "../apiClient";
import { authFetcher, defaultFetcher } from "../utils/fetcher";
import endPoint from "../endPoint";
import _ from "lodash";

const client = new ApiClient(authFetcher);
const defaultClient = new ApiClient(defaultFetcher);

const getGalleryDetail = (galleryId, data) =>
  client.get(
    endPoint.GALLERY_GET_DETAIL.replace(":galleryId", galleryId),
    data
  );

const createGallery = (data) =>
  defaultClient.post(endPoint.GALLERY_CREATE, data);

const getListGallery = (data) =>
  defaultClient.get(endPoint.GALLERY_GET_LIST, data);

const getListGalleryFloor = (data) =>
  defaultClient.get(endPoint.GALLERY_GET_LIST, data);

const findOneGalleryDetail = (data) =>
  defaultClient.get(endPoint.GALLERY_FIND_ONE, data);

const updateGallery = (id, data) =>
  defaultClient.put(endPoint.GALLERY_UPDATE.replace(":id", id), data);

const deleteGallery = (id) =>
  defaultClient.delete(endPoint.GALLERY_DELETE.replace(":id", id));

const reorderGallery = (data) => {
  defaultClient.put(endPoint.GALLERY_REORDER, data);
};

//data: {
// "id": "123",
// "playlistId": "123"
// }
const createPlaylistForGallery = (data) =>
  defaultClient.post(endPoint.GALLERY_CREATE, data);

//data: {
// "galleryId": "123",
// "playlistId": "123"
//  "playlistOverride"?: ["66e23b9bee0532003b1796b4"]
// }
const updatePlaylistForGallery = (data) =>
  defaultClient.put(
    endPoint.GALLERY_UPDATE.replace(":id", data?.galleryId),
    _.omit(data, "galleryId")
  );

export default {
  getGalleryDetail,
  createGallery,
  getListGallery,
  updateGallery,
  deleteGallery,
  getListGalleryFloor,
  findOneGalleryDetail,
  reorderGallery,

  //gallery
  createPlaylistForGallery,
  updatePlaylistForGallery,
};
