// getS3BEMediaUrl
export const getS3FEMediaUrl = (path) => {
  if (!path) return '';

  return `https://${process.env.REACT_APP_AWS_S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/fe${path}`;
};

export const getS3BEMediaUrl = (path) => {
  if (!path) return '';

  return `https://${process.env.REACT_APP_AWS_S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/be${path}`;
};
