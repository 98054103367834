import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { getSrc, thCss } from "../utils";
import LoadingTable from "../../configuration/components/Table/LoadingTable";
import EmptyTable from "../../configuration/components/Table/EmptyTable";
import { refetchGetGallery } from "../../../../reduxs/gallery/action";
import icDragDrop from "../../../../assets/images/cms/icDragDrop.png";
import galleryApi from "../../../../apis/api/gallery";
import { cn } from "../../../../helper/utils";

const Table = ({ medias, isLoadingTable, gallery }) => {
  const dispatch = useDispatch();
  const { id: galleryId, playlist } = gallery || {};
  const [mediasState, setMediasState] = useState(medias);

  useEffect(() => {
    setMediasState(medias);
  }, [medias]);

  const onDragEnd = async (result) => {
    if (result) {
      const indexDestination = result?.destination?.index;
      const indexSource = result?.source?.index;

      if (indexDestination === indexSource) return;

      const newMedias = [...mediasState];
      const [reorderedItem] = newMedias.splice(indexSource, 1);
      newMedias.splice(indexDestination, 0, reorderedItem);

      setMediasState(newMedias);

      const playlistOverride = newMedias.map((item) => item.id);

      const res = await galleryApi.updatePlaylistForGallery({
        galleryId,
        playlistId: playlist?.id,
        playlistOverride,
      });

      if (res?.data) {
        toast.success("Update playlist successfully");
      } else {
        toast.error("Update playlist failed");
      }

      dispatch(refetchGetGallery(galleryId));
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <table
        className={cn("w-full h-auto border-collapse table-fixed", {
          "h-full": isLoadingTable || !mediasState?.length,
        })}
        id="my-table"
      >
        <thead>
          <tr className="text-sm font-semibold">
            <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>Image</th>
            <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>
              File Name
            </th>
            <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>
              Media Name
            </th>
            <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>
              Content Type
            </th>
            <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>
              Last Edited
            </th>
            <th
              className={`${thCss} sticky z-[1] w-[100px] top-0 bg-gray-100`}
            />
          </tr>
        </thead>
        {!!mediasState?.length && !isLoadingTable && (
          <>
            <Droppable droppableId="gallery-list">
              {(provided, snapshot) => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {mediasState?.map((v, index) => {
                    return (
                      <Draggable key={v?.id} draggableId={v?.id} index={index}>
                        {(provided, snapshot) => (
                          <tr
                            key={v?.id}
                            className={`text-sm transition-all hover:bg-gray-100 cursor-grab ${
                              snapshot.isDragging
                                ? "table w-full table-fixed h-full [&>td]:table-cell [&>td]:align-middle"
                                : ""
                            }`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <td className={thCss}>
                              {v?.type === "videos" && (
                                <video
                                  src={getSrc(v?.type, v?.path)}
                                  className="w-24 m-auto"
                                  controls
                                />
                              )}
                              {v?.type !== "videos" && (
                                <img
                                  src={getSrc(v?.type, v?.path)}
                                  alt="edit"
                                  className="w-24 m-auto"
                                />
                              )}
                            </td>
                            <td className={thCss}>{v?.fileName}</td>
                            <td className={thCss}>{v?.name}</td>
                            <td className={thCss}>{v?.type}</td>
                            <td className={thCss}>
                              {v?.updatedAt &&
                                dayjs(v?.updatedAt).format(
                                  "HH:mm - YYYY-MM-DD"
                                )}
                            </td>
                            <td className={`w-[100px] ${thCss}`}>
                              <img
                                className="w-full cursor-pointer"
                                src={icDragDrop}
                                alt=""
                              />
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </>
        )}
        {isLoadingTable && (
          <tbody className="h-full">
            <tr>
              <td colSpan="6" className="text-center py-4">
                <div className="flex justify-center items-center">
                  <LoadingTable />
                </div>
              </td>
            </tr>
          </tbody>
        )}
        {!isLoadingTable && (!mediasState || !mediasState?.length) && (
          <tbody className="h-full">
            <tr>
              <td
                colSpan="6"
                className="text-center py-4 border border-t-0 border-gray-300"
              >
                <div className="flex justify-center items-center">
                  <EmptyTable />
                </div>
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </DragDropContext>
  );
};

export default Table;
