export const MEDIA_TYPE = {
  IMAGE: "images",
  VIDEO: "videos",
  FLOORPLANS: "floorplans",
  VIRTUALTOURS: "virtualTours",
  PARONAMIC: "paronamics",
  MODELS: "models",
  VIEWLINES: "viewlines",
  "360IMAGE": "360image",
};
