import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { reqGetListPlaylists } from "../../../reduxs/cms/action";
import SelectField from "../configuration/components/selectField";
import { Button } from "../components/commons";
import gallery from "../../../apis/api/gallery";
import { reqGetGallery } from "../../../reduxs/gallery/action";

const PlaylistSelect = ({
  setIsShow,
  typeGallery,
  pageOfGalleryId,
  isGallery,
  playListId,
}) => {
  const dispatch = useDispatch();
  const playlists = useSelector((state) => state.cms.playlist);

  const playListOptions = playlists?.data?.map((playlist) => ({
    id: playlist?.id,
    name: playlist?.name,
  }));

  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues: {
      playlist: null,
    },
  });

  useEffect(() => {
    reset({
      playlist: playListOptions?.find((v) => v.id === playListId) ?? null,
    });
  }, [playListId, playlists]);

  const getListPlaylists = async () => {
    await dispatch(
      reqGetListPlaylists({
        "type[equal]": typeGallery,
      })
    );
  };

  useEffect(() => {
    typeGallery && (async () => await getListPlaylists())();
  }, []);

  const onSave = async (v) => {
    const action = playListId || isGallery ? "update" : "create";
    const method =
      playListId || isGallery
        ? "updatePlaylistForGallery"
        : "createPlaylistForGallery";

    const res = await gallery[method]({
      [playListId || isGallery ? "galleryId" : "id"]: pageOfGalleryId,
      playlistId: v.playlist.id,
    });

    if (res?.data) {
      await getListPlaylists();
      await dispatch(reqGetGallery(pageOfGalleryId));
      toast.success(
        `${
          action.charAt(0).toUpperCase() + action.slice(1)
        } playlist successfully`
      );
      setIsShow(false);
    } else {
      toast.error(
        `${action.charAt(0).toUpperCase() + action.slice(1)} playlist failed`
      );
    }
  };

  return (
    <div>
      <SelectField
        wrapperOptionsClassName="p-[10px] rounded-[8px] border-[#000]"
        labelClassName="text-[14px] text-[#5d5d5d] mb-[10px] font-[500]"
        controller={{ control, name: "playlist" }}
        required
        label="Playlist"
        options={playListOptions || []}
        placeholder="Please select playlist"
      />
      <div className="mt-5 flex gap-5">
        <Button
          disabled={formState.isSubmitting}
          onClick={() => setIsShow(false)}
          variant="text"
          className="flex-1"
        >
          Cancel
        </Button>
        <Button
          className="flex-1"
          disabled={formState.isSubmitting || !formState.isDirty}
          isLoading={formState.isSubmitting}
          onClick={handleSubmit(onSave)}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default PlaylistSelect;
