/** @format */

import React, { useState, useEffect } from "react";
import {
  ACTION_NAME,
  WEBSOCKET_CHANNEL,
} from "../../constants/options";
import Renders from "./components/renders";
import Films from "./components/films";
import PanaromaGallery from "./components/panaroma-gallery";
import FloorPlans from "./components/floor-plans/floor-plans"
import { useDispatch } from "react-redux";
import { reqGetGalleryList } from "../../reduxs/gallery/action";
import socket from "../../helper/socket";
import { fakeMediaImg, fakeMediaFilm, fakeMediaImg360, fakeMediaFloor } from "./mock/data";
import Tabs from "./components/tabs";
import { PAGES_ENUM } from "../../constants/modules";

const GalleryLanding = (props) => {
  const { isPresentation, pages } = props;
  const [tabActive, setTabActive] = useState(0);
  const [subTabActive, setSubTabActive] = useState(0);
  // const [floor, setFloor] = useState([]);
  // const [floorDetail, setFloorDetail] = useState({});
  // const [imageRender, setImgRender] = useState([]);
  // const [filmRender, setFilmRender] = useState([]);
  const dispatch = useDispatch();
  const data = pages?.find(i => i.name === PAGES_ENUM.GALLERY_TYPE_B);
  const componentActive = data?.modules?.[tabActive]?.features?.[0]?.components || [];

  useEffect(() => {
    dispatch(reqGetGalleryList());

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CHANGE_ACTIVE_GALLERY_TAB) {
          return onClickTab(content.data.idx);
        }
        if (content.action === ACTION_NAME.CHANGE_ACTIVE_SUB_GALLERY_TAB) {
          return onClickSubTab(content.data.idx);
        }
      });
    }
  }, []);

  // useEffect(() => {
  //   if (tabActive === TAB[0].name) {
  //     if (subTabActive === SUB_TAB[2].name) {
  //       handleGetImageRender(GALLERY_IMAGE_TYPE.ORLA_GALLERY_IMAGE);
  //     }
  //     if (subTabActive === SUB_TAB[0].name) {
  //       handleGetFilmRender(FILM_TYPE.ORLA_FILM);
  //     }
  //     if (subTabActive === SUB_TAB[1].name) {
  //       handleGetListLevel(LEVEL_TYPE.ORLA_LEVEL);
  //       handleGetGalleryDetail(FLOOR_TYPE.ORLA_FLOOR);
  //     }
  //   } else if (tabActive === TAB[1].name) {
  //     if (subTabActive === SUB_TAB[2].name) {
  //       handleGetImageRender(GALLERY_IMAGE_TYPE.ORLA_INFINITY_GALLERY_IMAGE);
  //     }
  //     if (subTabActive === SUB_TAB[0].name) {
  //       handleGetFilmRender(FILM_TYPE.ORLA_INFINITY_FILM);
  //     }
  //     if (subTabActive === SUB_TAB[1].name) {
  //       handleGetListLevel(LEVEL_TYPE.ORLA_INFINITY_LEVEL);
  //       handleGetGalleryDetail(FLOOR_TYPE.ORLA_INFINITY_FLOOR);
  //     }
  //   } else if (tabActive === TAB[2].name) {
  //     if (subTabActive === SUB_TAB[2].name) {
  //       handleGetImageRender(GALLERY_IMAGE_TYPE.ORLA_MANSION_GALLERY_IMAGE);
  //     }
  //     if (subTabActive === SUB_TAB[0].name) {
  //       handleGetFilmRender(FILM_TYPE.ORLA_MANSION_FILM);
  //     }
  //     if (subTabActive === SUB_TAB[1].name) {
  //       handleGetListLevel(LEVEL_TYPE.ORLA_MANSION_LEVEL);
  //       handleGetGalleryDetail(FLOOR_TYPE.ORLA_MANSION_FLOOR);
  //     }
  //   } else if (tabActive === TAB[3].name) {
  //     if (subTabActive === SUB_TAB[2].name) {
  //       handleGetImageRender(GALLERY_IMAGE_TYPE.ORLA_SKY_PLACES_GALLERY_IMAGE);
  //     }
  //     if (subTabActive === SUB_TAB[0].name) {
  //       handleGetFilmRender(FILM_TYPE.ORLA_SKY_PLACES_FILM);
  //     }
  //     if (subTabActive === SUB_TAB[1].name) {
  //       handleGetListLevel(LEVEL_TYPE.ORLA_SKY_PLACES_LEVEL);
  //       handleGetGalleryDetail(FLOOR_TYPE.ORLA_SKY_PLACES_FLOOR);
  //     }
  //   }
  // }, [tabActive, subTabActive]);

  useEffect(() => {
    if (data?.modules.length > 0) {
      const idxActive =data?.modules?.findIndex(
        (i) => i.props.visible
      );
      if (idxActive !== -1) {
        setTabActive(idxActive);
      }
    }
  }, [data?.modules]);

  useEffect(() => {
    if (data?.modules.length > 0) {
      if (componentActive.length > 0) {
        const idxActive = componentActive?.findIndex(
          (i) => i.props.visible
        );
        if (idxActive !== -1) {
          setSubTabActive(idxActive);
        }
      }
    }
  }, [tabActive, componentActive])

  // const handleGetImageRender = async (type) => {
  //   const res = await galleryApi.findOneGalleryDetail({
  //     "type[equal]": type,
  //   });
  //   if (res) {
  //     setImgRender(res.data);
  //   }
  // };

  // const handleGetFilmRender = async (type) => {
  //   const res = await galleryApi.findOneGalleryDetail({
  //     "type[equal]": type,
  //   });
  //   if (res) {
  //     setFilmRender(res.data);
  //   }
  // };

  // const handleGetListLevel = async (type) => {
  //   try {
  //     const data = {
  //       "type[equal]": type,
  //     };
  //     const res = await galleryApi.getListGalleryFloor(data);
  //     if (res) {
  //       setFloor(res.data);
  //     }
  //   } catch (e) {
  //     console.log("error get floor");
  //   }
  // };

  // const handleGetGalleryDetail = async (type) => {
  //   try {
  //     const data = {
  //       "type[equal]": type,
  //     };
  //     const floorDetailRes = await galleryApi.findOneGalleryDetail(data);
  //     if (floorDetailRes) {
  //       setFloorDetail(floorDetailRes.data);
  //     }
  //   } catch (e) {
  //     console.log("error get floor");
  //   }
  // };

  const onClickTab = (idx) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CHANGE_ACTIVE_GALLERY_TAB, {
        idx,
      });
    }
    setTabActive(idx);
    // if (props?.moduleConfig?.features?.[idx]?.components.length > 0) {
    //   const idxSubActive = props?.moduleConfig?.features?.[
    //     idx
    //   ]?.components.findIndex((i) => i.props.visible);
    //   if (idxSubActive !== -1) {
    //     onClickSubTab(
    //       props?.moduleConfig?.features?.[idx]?.components?.[
    //         idxSubActive
    //       ]?.name.toLowerCase()
    //     );
    //   } else {
    //     onClickSubTab("");
    //   }
    // }
  };

  const onClickSubTab = (idx) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CHANGE_ACTIVE_SUB_GALLERY_TAB, {
        idx,
      });
    }
    setSubTabActive(idx);
  };

  const renderContentGallery = () => {
    if (componentActive?.length > 0 && componentActive?.[subTabActive].props.visible) {
      //tabActive
      if (componentActive?.[subTabActive].name.includes("images")) {
        return (
          <Renders
            // data={imageRender}
            data={fakeMediaImg}
            isPresentation={isPresentation}
          />
        );
      }
      if (componentActive?.[subTabActive].name.includes("films")) {
        return (
          <Films
            // data={filmRender}
            data={fakeMediaFilm}
            isPresentation={isPresentation}
          />
        );
      }
      if (componentActive?.[subTabActive].name.includes("panorama")) {
        return (
          <PanaromaGallery
            // data={imageRender}
            data={fakeMediaImg360}
            isPresentation={isPresentation}
          />
        );
      }
      if (componentActive?.[subTabActive].name.includes("floor")) {
        return (
          <FloorPlans
            isPresentation={isPresentation}
            // data={floor}
            data={fakeMediaFloor}
            tabActive={tabActive}
            subTabActive={subTabActive}
          />
        );
      }
    }
  };

  return (
    <div className="absolute  bg-[#0c2032] w-screen h-[100vh] overflow-y-scroll overflow-x-hidden flex flex-row  mt-[49px] mb-[60px] pt-[123px] px-[90px] py-20 top-0">
      <div className="h-full w-full">
        <Tabs
          tabActive={tabActive}
          subTabActive={subTabActive}
          onClickTab={onClickTab}
          onClickSubTab={onClickSubTab}
          modules={data?.modules || []}
        />
        <div className="h-full">{renderContentGallery()}</div>
      </div>
    </div>
  );
};
export default GalleryLanding;
