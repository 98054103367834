import { get } from "lodash";
import UnitDetail from "./unit-detail";
import UnitFilter from "./unit-filter";
import socket from '../../helper/socket';
import VirtualTour from "../virtual-tour";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import React, { useEffect, useMemo } from "react";
import PopupGallery from '../media/popup-gallery';
import FloorPlanGallery from "../floor-plan-gallery";
import { reqSetIsTransparent } from '../../reduxs/home/action';
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import { MODULES_ENUM, PAGES_ENUM } from "../../constants/modules";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from '../../constants/options';
import { unitGalleryImages, unitGalleryPenthouseImages } from './unit-gallery-images';
import Gallery360 from './360/index';

const UnitExplore = ({
  visible = false,
  moduleConfig = null,
  handleUnitClick,
  setActiveObjectIds,
  isPresentation,
}) => {
  const dispatch = useDispatch();

  // isShow
  const isShowFloorplan = useSelector((state) => state.unitExplore.isShowFloorplan);
  const isShowGallery = useSelector((state) => state.unitExplore.isShowGallery);
  const isShowViewLine = useSelector((state) => state.unitExplore.isShowViewLine);
  const isShowUnitDetail = useSelector((state) => state.unitExplore.isShowUnitDetail);
  const isTransparent = useSelector((state) => state.home.isTransparent);
  const isShow360 = useSelector(
    (state) => state.unitExplore.isShow360
  );
  // filtered
  const filteredUnits = useSelector((state) => state.unitExplore.filteredUnits);
  const filterUnitBedroom = useSelector((state) => state.unitExplore.filterUnitBedroom);
  const filterUnitBathroom = useSelector((state) => state.unitExplore.filterUnitBathroom);
  const filterUnitAspect = useSelector((state) => state.unitExplore.filterUnitAspect);
  const filterUnitRoomType = useSelector((state) => state.unitExplore.filterUnitRoomType);
  const filterUnitPrice = useSelector((state) => state.unitExplore.filterUnitPrice);
  const filterUnitLotSize = useSelector((state) => state.unitExplore.filterUnitLotSize);
  const filterUnitExteriorSize = useSelector((state) => state.unitExplore.filterUnitExteriorSize);
  const filterUnitInteriorSize = useSelector((state) => state.unitExplore.filterUnitInteriorSize);
  const filterUnitAvailability = useSelector((state) => state.unitExplore.filterUnitAvailability);
  const filterUnitPool = useSelector((state) => state.unitExplore.filterUnitPool);
  // unit
  const unitQuery = useSelector((state) => state.unitExplore.unitQuery);
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);

  useEffect(() => {
    dispatch(unitExploreAct.reqGetUnitList(unitQuery));
  }, []);

  useEffect(() => {
    if (isFiltered) {
      setActiveObjectIds(filteredUnits.map((item) => item["3d_asset"]?.id));
    } else {
      dispatch(unitExploreAct.reqSetSelectedUnit(null));
      dispatch(unitExploreAct.reqSetIsShowUnitList(false));
      setActiveObjectIds([]);
    }
  }, [filteredUnits]);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
  }, [isPresentation]);

  const isFiltered = useMemo(() => {
    return (
      filterUnitPool !== undefined ||
      filterUnitAspect ||
      filterUnitBedroom.length ||
      filterUnitBathroom.length ||
      filterUnitRoomType.length ||
      filterUnitAvailability.length ||
      filterUnitPrice?.max || filterUnitPrice?.min ||
      filterUnitLotSize?.max || filterUnitLotSize?.min ||
      filterUnitExteriorSize?.max || filterUnitExteriorSize?.min ||
      filterUnitInteriorSize?.max || filterUnitInteriorSize?.min
    );
  }, [
    filterUnitPool,
    filterUnitAspect,
    filterUnitBedroom,
    filterUnitBathroom,
    filterUnitPrice,
    filterUnitRoomType,
    filterUnitLotSize,
    filterUnitExteriorSize,
    filterUnitInteriorSize,
    filterUnitAvailability,
  ]);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CLOSE_UNIT_GALLERY) {
      hideGallery();
    }
  };

  const getGalleryImages = () => {
    const isPenthouse = get(selectedUnit, 'name', '')?.includes('Penthouse');

    if (isPenthouse) {
      return unitGalleryPenthouseImages;
    } else {
      return unitGalleryImages;
    }
  };

  const hideGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_GALLERY);
    }
    dispatch(unitExploreAct.reqIsShowGallery(false));
    dispatch(reqSetIsTransparent(false));
  };

  const features = useMemo(() => moduleConfig?.features || [], [moduleConfig]);

  if (!visible) return null;

  return (
    <>
      {
        features?.some((f) => f.name === UnitFilter.displayName) &&
        <UnitFilter
          isPresentation={isPresentation}
          isTransparent={isTransparent}
          setActiveObjectIds={setActiveObjectIds}
          handleUnitClick={handleUnitClick}
          featureConfig={features?.find((f) => f.name === UnitFilter.displayName) || null}
          {...(features?.find((f) => f.name === UnitFilter.displayName)?.props || {})}
        />
      }

      {
        features?.some((f) => f.name === UnitDetail.displayName) &&
        <CSSTransition in={!!selectedUnit && isShowUnitDetail} timeout={500} classNames="fade-item" unmountOnExit>
          <UnitDetail
            isPresentation={isPresentation}
            isTransparent={isTransparent}
            featureConfig={features?.find((f) => f.name === UnitDetail.displayName) || null}
            {...(features?.find((f) => f.name === UnitDetail.displayName)?.props || {})}
          />
        </CSSTransition>
      }

      <CSSTransition in={isShowFloorplan} timeout={1000} classNames="fade-item" unmountOnExit>
        <FloorPlanGallery isPresentation={isPresentation} />
      </CSSTransition>

      <TransitionGroup>
        <CSSTransition in={isShowGallery} timeout={1000} classNames="fade-item" unmountOnExit>
          <PopupGallery isPresentation={isPresentation} show={isShowGallery} listImage={getGalleryImages()} isClientImages={true} onClose={hideGallery} />
        </CSSTransition>
      </TransitionGroup>

      <TransitionGroup>
        <CSSTransition in={isShowViewLine} timeout={1000} classNames="fade-item" unmountOnExit>
          <VirtualTour isPresentation={isPresentation} />
        </CSSTransition>
      </TransitionGroup>
      <CSSTransition in={isShow360} timeout={1000} classNames="fade-item" unmountOnExit>
        <Gallery360 isPresentation={isPresentation} isShow360={isShow360} isTransparent={isTransparent} />
      </CSSTransition>
    </>
  );
};
UnitExplore.displayName = MODULES_ENUM.UNIT_EXPLORE;
UnitExplore.pageGroup = PAGES_ENUM.INTERACTIVE_3D;

export default UnitExplore;
