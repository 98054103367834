/** @format */

import { useCallback, useEffect, useRef, useState } from "react";
import icImg from "../../../assets/images/cms/icImg.png";
import icVideo from "../../../assets/images/cms/icVideo.png";
import icEdit from "../../../assets/images/cms/edit.png";
import { useSelector } from "react-redux";
import playListApi from "../../../apis/api/playlist";
import mediaApi from "../../../apis/api/media";
import { Modal, Button } from "../components/commons";
import TrashIcon from "../configuration/components/svgs/TrashIcon";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import EditMediaContentModal from "./edit-modal";
import SelectMediaModal from "./select-media-modal";
import SearchIcon from "../components/Search";
import PlusIcon from "../configuration/components/svgs/PlusIcon";
import AddMeidaContentModal from "./add-modal";
import { MEDIA_TYPE } from "../utils";
import { getS3BEMediaUrl } from "../../../helper/media";
import { LazyLoadImage } from "react-lazy-load-image-component";
const thCss =
  "text-left border whitespace-nowrap overflow-hidden text-ellipsis p-2 border-solid border-[#ddd] cursor-pointer";

const MediaDetail = () => {
  const { mediaType, playlist } = useSelector((state) => state.cms);
  const [data, setData] = useState([]);
  const [isShowEditContentModal, setIsShowEditContentModal] = useState(false);
  const [seletedGallery, setSeletedGallery] = useState(null);
  const [isShowAddContentModal, setIsShowAddContentModal] = useState(false);
  const [search, setSearch] = useState("");
  const refData = useRef([]);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [isShowSelectMedia, setShowSelectMedia] = useState(false);
  const isFromMediaFolder = mediaType.split("_")[0] === "media";

  useEffect(() => {
    // trigger clear data after remove playlist
    if (!playlist?.length) setData([]);
  }, [playlist]);

  useEffect(() => {
    if (mediaType) {
      if (isFromMediaFolder) {
        loadDataMediaTab();
      } else {
        loadData();
      }
    }
  }, [mediaType]);

  useEffect(() => {
    if (search) {
      let result = [];
      result = refData.current.filter((item) =>
        item?.name?.toLowerCase().includes(search)
      );
      setData({
        medias: [...result],
      });
    } else {
      setData({
        medias: [...refData.current],
      });
    }
  }, [search]);

  const onSearch = (e) => {
    setSearch(e.target?.value);
  };

  const onEditContent = (gallery) => {
    setSeletedGallery(gallery);
    setIsShowEditContentModal(true);
  };

  const loadDataMediaTab = async () => {
    const data = {
      "type[equal]": mediaType.split("_")[1],
    };
    const res = await mediaApi.getMediaList(data);
    if (res && res.data) {
      setData({
        medias: [...res.data],
      });
      refData.current = [...res.data];
    }
  };

  const loadData = async () => {
    try {
      const res = await playListApi.getPlaylistDetail(mediaType.split("_")[0]);
      if (res && res.data) {
        refData.current = [...res.data?.medias];
        setData(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onCreateSuccess = (item) => {
    const newArray = [...data.medias];
    newArray.unshift(item);
    setData({
      ...data,
      medias: [...newArray],
    });
    setIsShowAddContentModal(false);
  };

  const onAddFileSuccess = (medias) => {
    setData({
      ...data,
      medias: [...medias],
    });
    setShowSelectMedia(false);
  };

  const getIcon = useCallback((type, url, thumb) => {
    switch (type) {
      case MEDIA_TYPE.VIDEO:
        return (
          <LazyLoadImage
            className="w-24 m-auto h-24 object-cover"
            alt={type}
            src={url ? getS3BEMediaUrl(thumb) : icVideo}
            onError={(e) => {
              e.currentTarget.src = icVideo;
            }}
          />
        );
      default:
        return (
          <LazyLoadImage
            className="w-24 m-auto h-24 object-cover"
            alt={type}
            src={url ? getS3BEMediaUrl(url) : icImg}
            onError={(e) => {
              e.currentTarget.src = icImg;
            }}
          />
        );
    }
  }, []);

  const onSaveSuccess = (item) => {
    const newArray = [...data.medias];
    const objIndex = newArray.findIndex((obj) => obj.id == item.id);
    newArray[objIndex] = { ...item };
    setData({
      ...data,
      medias: [...newArray],
    });
  };

  const onDeleteSuccess = (id) => {
    let newArray = [...data.medias];
    const objIndex = newArray.findIndex((obj) => obj.id == id);
    newArray.splice(objIndex, 1);
    setData({
      ...data,
      medias: [...newArray],
    });
  };

  const handleDelete = async ({ id, onSuccess, onError }) => {
    setLoadingDelete(true);
    try {
      const res = await playListApi.deleteMediaPlayList(id);
      if (res) {
        toast.success("Delete media successfully");
        onSuccess && onSuccess();
        onDeleteSuccess(id);
      }
    } catch (error) {
      onError && onError();
      toast.error("Delete playlist failed");
    } finally {
      setLoadingDelete(false);
    }
  };

  return (
    <>
      <section className="h-[calc(100vh-160px)] overflow-y-scroll overflow-x-hidden">
        <SearchIcon handleInputChange={onSearch} />
        <table className="w-full border-collapse table-fixed" id="media-table">
          <thead>
            <tr className="text-sm font-semibold">
              <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>
                Image
              </th>
              <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>
                File Name
              </th>
              <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>
                Media Name
              </th>
              <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>
                Content Type
              </th>
              <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>
                Last Edited
              </th>
              <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>
                Edit
              </th>
              <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>
                Delete
              </th>
            </tr>
          </thead>
          {data?.medias?.length > 0 && (
            <>
              <tbody>
                {data?.medias?.map((i, index) => {
                  return (
                    <tr
                      key={index}
                      className="text-sm transition-all hover:bg-gray-100 cursor-grab h-12"
                    >
                      <td className={thCss}>
                        {getIcon(i?.type, i?.path, i?.thumbnailPath)}
                      </td>
                      <td className={thCss}>{i?.fileName}</td>
                      <td className={thCss}>{i?.name}</td>
                      <td className={thCss}>{i?.type}</td>
                      <td className={thCss}>
                        {dayjs(i?.updatedAt).format("HH:mm - YYYY-MM-DD")}
                      </td>
                      <td className={`${thCss}`}>
                        <div className="flex-row flex justify-center items-center gap-5">
                          <img
                            src={icEdit}
                            alt="edit"
                            className="w-24 m-auto"
                            onClick={() => onEditContent(i)}
                          />
                        </div>
                      </td>
                      <td className={`${thCss}`}>
                        <div className="flex-row flex justify-center items-center gap-5">
                          <Modal
                            maskClassName="bg-opacity-50"
                            zIndex="z-[9998]"
                            maskClosable
                            trigger={
                              <span className="text-red-500 cursor-pointer">
                                <TrashIcon width={30} height={30} />
                              </span>
                            }
                            content={({ setIsShow }) => (
                              <div className="bg-white  w-full max-w-md">
                                <h2 className="text-md text-black font-semibold text-center">
                                  Are you sure you want to delete this media?
                                </h2>
                                <div className="flex justify-center mt-6 space-x-4">
                                  <Button
                                    disabled={loadingDelete}
                                    className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300 text-black"
                                    onClick={() => setIsShow(false)}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    disabled={loadingDelete}
                                    isLoading={loadingDelete}
                                    className="bg-yellow-200 text-black px-4 py-2 rounded hover:bg-yellow-300"
                                    onClick={() =>
                                      handleDelete({
                                        id: i.id,
                                        onSuccess: () => {
                                          setIsShow(false);
                                        },
                                        onError: () => setIsShow(false),
                                      })
                                    }
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <div
                className={`cursor-pointer bg-gray-100 rounded-lg absolute right-10 bottom-10`}
                onClick={() => setIsShowAddContentModal(true)}
              >
                <PlusIcon />
              </div>
            </>
          )}
        </table>
        {data?.medias?.length === 0 && (
          <div className="empty-data w-full justify-center items-center flex gap-10 mt-10 flex-col">
            <div className="italic text-gray-300">{`(Empty data)`}</div>
            <div
              className={`cursor-pointer bg-gray-100 rounded-lg `}
              onClick={() => setIsShowAddContentModal(true)}
            >
              <PlusIcon />
            </div>
          </div>
        )}
      </section>
      {isShowEditContentModal && (
        <EditMediaContentModal
          content={seletedGallery}
          mediaType={seletedGallery.type}
          setShow={setIsShowEditContentModal}
          show={isShowEditContentModal}
          gallery={data}
          thumbnailContent={seletedGallery?.thumbnailContent}
          onSaveSuccess={onSaveSuccess}
          isFromMediaFolder={isFromMediaFolder}
        />
      )}
      {isShowAddContentModal && (
        <AddMeidaContentModal
          mediaType={mediaType.split("_")[1].toLowerCase()}
          show={isShowAddContentModal}
          setShow={setIsShowAddContentModal}
          onCreateSuccess={onCreateSuccess}
          gallery={data}
          handleSelectMedia={() => {
            setShowSelectMedia(true);
            setIsShowAddContentModal(false);
          }}
          isFromMediaFolder={isFromMediaFolder}
        />
      )}
      {isShowSelectMedia && (
        <SelectMediaModal
          show={isShowSelectMedia}
          setShow={setShowSelectMedia}
          playListId={data.id}
          mediaType={mediaType.split("_")[1].toLowerCase()}
          onCreateSuccess={onAddFileSuccess}
        />
      )}
    </>
  );
};

export default MediaDetail;

//Todo: handle later, dont remvoe
// useEffect(() => {
//   if (data.length === 0) return;
//   // Get the table and its rows
//   const table = document.getElementById("media-table");
//   const rows = table.rows;
//   // Initialize the drag source element to null
//   let dragSrcEl = null;

//   // Loop through each row (skipping the first row which contains the table headers)
//   for (let i = 1; i < rows.length; i++) {
//     const row = rows[i];
//     // Make each row draggable
//     row.draggable = true;

//     // Add an event listener for when the drag starts
//     row.addEventListener("dragstart", function (e) {
//       // Set the drag source element to the current row
//       dragSrcEl = this;
//       // Set the drag effect to "move"
//       e.dataTransfer.effectAllowed = "move";
//       // Set the drag data to the outer HTML of the current row
//       e.dataTransfer.setData("text/html", this.outerHTML);
//       // Add a class to the current row to indicate it is being dragged
//       this.classList.add("bg-gray-100");
//     });

//     // Add an event listener for when the drag ends
//     row.addEventListener("dragend", function (e) {
//       // Remove the class indicating the row is being dragged
//       this.classList.remove("bg-gray-100");
//       // Remove the border classes from all table rows
//       table
//         .querySelectorAll(".border-t-2", ".border-blue-300")
//         .forEach(function (el) {
//           el.classList.remove("border-t-2", "border-blue-300");
//         });
//     });

//     // Add an event listener for when the dragged row is over another row
//     row.addEventListener("dragover", function (e) {
//       // Prevent the default dragover behavior
//       e.preventDefault();
//       // Add border classes to the current row to indicate it is a drop target
//       this.classList.add("border-t-2", "border-blue-300");
//     });

//     // Add an event listener for when the dragged row enters another row
//     row.addEventListener("dragenter", function (e) {
//       // Prevent the default dragenter behavior
//       e.preventDefault();
//       // Add border classes to the current row to indicate it is a drop target
//       this.classList.add("border-t-2", "border-blue-300");
//     });

//     // Add an event listener for when the dragged row leaves another row
//     row.addEventListener("dragleave", function (e) {
//       // Remove the border classes from the current row
//       this.classList.remove("border-t-2", "border-blue-300");
//     });

//     // Add an event listener for when the dragged row is dropped onto another row
//     row.addEventListener("drop", function (e) {
//       // Prevent the default drop behavior
//       e.preventDefault();
//       // If the drag source element is not the current row
//       if (dragSrcEl != this) {
//         // Get the index of the drag source element
//         const sourceIndex = dragSrcEl.rowIndex;
//         // Get the index of the target row
//         const targetIndex = this.rowIndex;

//         // If the source index is less than the target index
//         if (sourceIndex < targetIndex) {
//           // Insert the drag source element after the target row
//           table.tBodies[0].insertBefore(dragSrcEl, this.nextSibling);
//           console.log("targetIndex==1==", targetIndex);
//           console.log("sourceIndex==1==", sourceIndex);
//         } else {
//           // Insert the drag source element before the target row
//           console.log("targetIndex==2==", targetIndex);
//           console.log("sourceIndex==2==", sourceIndex);
//           table.tBodies[0].insertBefore(dragSrcEl, this);
//         }
//       }
//       // Remove the border classes from all table rows
//       table
//         .querySelectorAll(".border-t-2", ".border-blue-300")
//         .forEach(function (el) {
//           el.classList.remove("border-t-2", "border-blue-300");
//         });
//     });
//   }
// }, [data]);
