/** @format */

import React, { useEffect, useState, useCallback } from "react";
import { Modal } from "../components/commons";
import { toast } from "react-toastify";
import closeIcon from "../../../assets/images/close-white.svg";
import { MEDIA_TYPE } from "../utils";
import icImg from "../../../assets/images/cms/icImg.png";
import icVideo from "../../../assets/images/cms/icVideo.png";
import dayjs from "dayjs";
import media from "../../../apis/api/media";
import playListApi from "../../../apis/api/playlist";
import { getS3BEMediaUrl } from "../../../helper/media";
import { LazyLoadImage } from "react-lazy-load-image-component";

const thCss =
  "border text-center whitespace-nowrap overflow-hidden text-ellipsis p-2 border-solid border-[#ddd] cursor-pointer";

const SelectMediaModal = ({
  show,
  setShow,
  mediaType,
  playListId,
  onCreateSuccess,
}) => {
  const [data, setData] = useState([]);
  const [isShowLoading, setShowLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const getIcon = useCallback((type, url, thumb) => {
    switch (type) {
      case MEDIA_TYPE.VIDEO:
        return (
          <LazyLoadImage
            className="w-24 m-auto h-24 object-cover"
            alt={type}
            src={url ? getS3BEMediaUrl(thumb) : icVideo}
            onError={(e) => {
              e.currentTarget.src = icVideo;
            }}
          />
        );
      default:
        return (
          <LazyLoadImage
            className="w-24 m-auto h-24 object-cover"
            alt={type}
            src={url ? getS3BEMediaUrl(url) : icImg}
            onError={(e) => {
              e.currentTarget.src = icImg;
            }}
          />
        );
    }
  }, []);
  useEffect(() => {
    loadData();
  }, [mediaType]);

  const loadData = async () => {
    const data = {
      "type[equal]": mediaType,
    };
    const res = await media.getMediaList(data);
    if (res && res.data) {
      setData(res.data);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleAddMediaToPlayList = async () => {
    setShowLoading(true);
    try {
      const medias = [];
      selectedItem.map((i) => medias.push(i.id));
      const params = {
        medias: [...medias],
      };
      const res = await playListApi.addFilesToPlayList(playListId, params);
      if (res && res?.data) {
        toast.success("Add new content successfully!");
        onCreateSuccess && onCreateSuccess(res.data?.medias);
      }
    } catch (err) {
      if (err.inner) {
        toast.error("Add media failed!");
      }
    } finally {
      setShowLoading(false);
      handleClose();
    }
  };

  console.log("selectedItem", selectedItem);

  return (
    <Modal
      iconCancelMark={() => (
        <div className="z-[9999] cursor-pointer fixed top-[50px] right-[30px]">
          <img src={closeIcon} alt="close-icon" />
        </div>
      )}
      isLoading={isShowLoading}
      classCustom="overflow-hidden"
      wrapperClassName="wrap-update-content-modal"
      panelClassName="w-[80vw] p-0 rounded-none"
      zIndex="z-[9998]"
      maskClassName="opacity-90"
      show={show}
      setShow={handleClose}
      content={
        <div className="p-5 max-h-[85vh] gap-5 flex flex-col ">
          <h2 className="font-[700] text-[20px] text-black text-center">
            Add file to playlist
          </h2>
          <div className="absolute">
            {selectedItem.length > 0 && (
              <div className="flex h-12 items-center space-x-3 bg-white sm:left-12">
                <button
                  onClick={handleAddMediaToPlayList}
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                >
                  Add
                </button>
                <button
                  onClick={() => setSelectedItem([])}
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                >
                  Delete all
                </button>
              </div>
            )}
          </div>
          <div className="overflow-y-scroll overflow-x-hidden h-[80vh]">
            <table
              className="w-full border-collapse table-fixed"
              id="media-table"
            >
              <thead>
                <tr className="text-sm font-semibold">
                  <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>
                    {/* <input
                      type="checkbox"
                      className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      // ref={checkbox}
                      // checked={checked}
                      // onChange={toggleAll}
                    /> */}
                  </th>
                  <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>
                    Image
                  </th>
                  <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>
                    File Name
                  </th>
                  <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>
                    Media Name
                  </th>
                  <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>
                    Content Type
                  </th>
                  <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>
                    Last Edited
                  </th>
                </tr>
              </thead>
              {data?.length > 0 && (
                <>
                  <tbody>
                    {data?.map((i, index) => {
                      return (
                        <tr
                          key={index}
                          className="text-sm transition-all hover:bg-gray-100 cursor-grab h-12"
                        >
                          <td className={thCss}>
                            <input
                              type="checkbox"
                              className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              checked={selectedItem.includes(i)}
                              onChange={(e) =>
                                setSelectedItem(
                                  e.target.checked
                                    ? [...selectedItem, i]
                                    : selectedItem.filter((p) => p.id !== i.id)
                                )
                              }
                            />
                          </td>
                          <td className={thCss}>
                            {getIcon(i?.type, i?.path, i?.thumbnailPath)}
                          </td>
                          <td className={thCss}>{i?.fileName}</td>
                          <td className={thCss}>{i?.name}</td>
                          <td className={thCss}>{i?.type}</td>
                          <td className={thCss}>
                            {dayjs(i?.updatedAt).format("HH:mm - YYYY-MM-DD")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </>
              )}
            </table>
          </div>
        </div>
      }
    />
  );
};

export default SelectMediaModal;
