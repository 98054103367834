import ApiClient from "../apiClient";
import { authFetcher, defaultFetcher } from "../utils/fetcher";
import endPoint from "../endPoint";

const client = new ApiClient(authFetcher);
const defaultClient = new ApiClient(defaultFetcher);

const createComponent = (idFeature, data) =>
  defaultClient.post(`${endPoint.FEATURES}/${idFeature}/components`, data);

const getComponents = (idFeature) =>
  defaultClient.get(`${endPoint.FEATURES}/${idFeature}/components`);

//update component payload:
// data: {
// order: number
// }
const updateComponent = (componentId, data) =>
  defaultClient.put(`${endPoint.COMPONENTS}/${componentId}`, data);

const deleteComponent = (componentId) =>
  defaultClient.delete(`${endPoint.COMPONENTS}/${componentId}`);

//SUB-COMPONENTS

const createSubComponent = (componentId, data) =>
  defaultClient.post(
    `${endPoint.COMPONENTS}/${componentId}/sub-components`,
    data
  );

const getSubComponents = (componentId, data) =>
  defaultClient.get(
    `${endPoint.COMPONENTS}/${componentId}/sub-components`,
    data
  );

//update sub-component payload:
// data: {
// order: number
// }
const updateSubComponent = (idSubComponent, data) =>
  defaultClient.put(`${endPoint.SUB_COMPONENTS}/${idSubComponent}`, data);

const deleteSubComponent = (idSubComponent) =>
  defaultClient.delete(`${endPoint.SUB_COMPONENTS}/${idSubComponent}`);

//PAGES
const getPages = () => defaultClient.get(endPoint.PAGES);

const createPage = (data) => defaultClient.post(`${endPoint.PAGES}`, data);

const updatePages = (idPage, data) =>
  defaultClient.put(endPoint.PAGES + `/${idPage}`, data);

//MODULES

const getModules = (idPage) =>
  defaultClient.get(endPoint.PAGES + `/${idPage}/modules`);

const getModule = (idModule) =>
  defaultClient.get(`${endPoint.MODULES}/${idModule}`);

const createModule = (idPage, data) =>
  defaultClient.post(endPoint.PAGES + `/${idPage}/modules`, data);

const updateModules = (idModule, data) =>
  defaultClient.put(`${endPoint.MODULES}/${idModule}`, data);

const deleteModule = (idModule) =>
  defaultClient.delete(`${endPoint.MODULES}/${idModule}`);

//FEATURE

const getFeatures = (idModule) =>
  defaultClient.get(endPoint.MODULES + `/${idModule}/features`);

const createFeature = (idModule, data) =>
  defaultClient.post(endPoint.MODULES + `/${idModule}/features`, data);

const updateFeature = (idFeature, data) =>
  defaultClient.put(`${endPoint.FEATURES}/${idFeature}`, data);

//3D CANVAS

// type Data = {
//   "units": 20,
//   "levels": 8,
//   "amenities": 0,
//   "transportOptions": 0
// }

const create3DCanvas = (data) =>
  defaultClient.post(endPoint.MESHES_GENERATE, data);

const export3DCanvas = () => defaultClient.get(endPoint.MESHES_EXPORT_JSON);

const getMeshesList = () => defaultClient.get(endPoint.MESHES_LIST);

const purgeMeshes = () => defaultClient.delete(endPoint.MESHES_PURGE);

export default {
  createComponent,
  getComponents,
  updateComponent,
  deleteComponent,
  //SubComponent
  createSubComponent,
  getSubComponents,
  updateSubComponent,
  deleteSubComponent,
  //pages
  getPages,
  createPage,
  updatePages,
  //modules
  getModules,
  updateModules,
  createModule,
  getModule,
  deleteModule,
  //features
  getFeatures,
  updateFeature,
  createFeature,
  //3D CANVAS
  create3DCanvas,
  export3DCanvas,
  getMeshesList,
  purgeMeshes,
};
