/** @format */

import ApiClient from "../apiClient";
import { defaultFetcher } from "../utils/fetcher";
import endPoint from "../endPoint";

const defaultClient = new ApiClient(defaultFetcher);

const getFolders = (query) => defaultClient.get(endPoint.FOLDER_LIST, query);
const createFolder = (query) => defaultClient.post(endPoint.FOLDER_LIST, query);
const deleteFolder = (mediaTypeId) =>
  defaultClient.delete(
    endPoint.FOLDER_UPDATE.replace(":mediaTypeId", mediaTypeId)
  );
const editFolder = (mediaTypeId, query) =>
  defaultClient.put(
    endPoint.FOLDER_UPDATE.replace(":mediaTypeId", mediaTypeId),
    query
  );

export default {
  getFolders,
  createFolder,
  deleteFolder,
  editFolder,
};
