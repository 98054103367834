import React, { useState, useEffect, useRef, Fragment } from "react";
import * as yup from "yup";
import { toast } from "react-toastify";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import closeIcon from "../../../../assets/images/close-white.svg";
import uploadIcon from "../../../../assets/images/upload.svg";
import mediaAPI from "../../../../apis/api/media";
import galleryApi from "../../../../apis/api/gallery";
import { getS3BEMediaUrl } from "../../../../helper/media";
import { ContentSectionOptions, ContentSection } from "..";
import { MEDIA_TYPE_ENUM } from "../../../../constants/master-data";
import { reqGetListUnits } from "../../../../reduxs/cms/action";
import { Modal } from "../../components/commons";
import { MEDIA_TYPE } from "../../utils";

const validationSchema = yup.object().shape({
  order: yup.number().required("Order is a required field"),
  contentTitle: yup
    .string()
    .trim()
    .required("Content title is a required field"),
  contentSection: yup
    .string()
    .trim()
    .required("Content section is a required field"),
});

const EditContentModal = ({
  content,
  thumbnailContent,
  gallery,
  show,
  setShow,
  onSaveSuccess,
  contentType,
  mediaType,
}) => {
  const dispatch = useDispatch();
  const units = useSelector((state) => state.cms.units);

  const [file, setFile] = useState();
  const [thumbnail, setThumbnail] = useState();
  const [order, setOrder] = useState();
  const [contentTitle, setContentTitle] = useState(content?.name || "");
  const [contentSection, setContentSection] = useState(
    ContentSectionOptions.find((v) => v.label === contentType)?.label
  );
  const [unitId, setUnitId] = useState(gallery?.unit?.id);
  const [replaceContentType, setReplaceContentType] = useState("");
  const [isShowDeleteContentModal, setIsShowDeleteContentModal] =
    useState(false);
  const [isShowReplaceContentModal, setIsShowReplaceContentModal] =
    useState(false);
  const [errors, setErrors] = useState({});
  const [enableBtnSubmit, setEnableBtnSubmit] = useState(true);
  const fileRef = useRef();
  const thumbnailRef = useRef();

  useEffect(() => {
    if (content) {
      setOrder(content?.order);
    }
  }, [content]);

  useEffect(() => {
    if (file) {
      setContentTitle(file.name);
    }
  }, [file]);

  useEffect(() => {
    if (!unitId) {
      return;
    }
    const unit = units?.find((unit) => unit.id === unitId);

    if (unit?.floorPlan?.id && unit.floorPlan.id !== gallery.id) {
      setErrors({ unitId: "Associated Residence already had a floorplan" });
      return;
    }

    setErrors({ unitId: undefined });
  }, [unitId]);

  useEffect(() => {
    dispatch(reqGetListUnits());
  }, []);

  const handleUpdateContent = () => {
    if (!enableBtnSubmit) {
      toast.info("Please wait, media file uploading!");
      return;
    }
    switch (contentSection) {
      case ContentSection.Films:
        handleUpdateContentFilm();
        return;
      case ContentSection.Exteriors:
        handleUpdateContentExteriors();
        return;
      case ContentSection.Interiors:
        handleUpdateContentInteriors();
        return;
      case ContentSection.Amenities:
        handleUpdateContentAmenities();
        return;
      default:
        return;
    }
  };

  const handleUpdateContentAmenities = async () => {
    try {
      const data = {
        order,
        contentTitle,
        contentSection,
        unitId,
      };
      setErrors({});
      const result = await validationSchema.validate(data, {
        abortEarly: false,
      });
      const formData = new FormData();
      formData.append("type", "image");
      formData.append("order", result?.order);
      formData.append("name", result?.contentTitle);
      formData.append("path", "media/media_images/image_gallery");

      if (file) {
        formData.append("file", file);
      }

      const updated = await mediaAPI.updateMedia(content.id, formData);
      const { id, createdAt, updatedAt, media, ...rest } = gallery;
      const updatedGallery = await galleryApi.updateGallery(gallery.id, {
        ...rest,
        media: media.map((m) => m.id),
        order: result?.order,
        unitId: result?.unitId,
        category: contentSection.toLowerCase(),
      });
      if (updated?.data && updatedGallery?.data) {
        toast.success("Content updated successfully!");
        handleClose && handleClose();
        onSaveSuccess && onSaveSuccess();
      }
    } catch (err) {
      console.log("tf", err);
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
    }
  };

  const handleUpdateContentFilm = async () => {
    if (!content || !thumbnailContent) return;
    if (errors.unitId === "Associated Residence already had a floorplan") {
      return;
    }

    try {
      const data = {
        order,
        contentTitle,
        contentSection,
        thumbnail,
        unitId,
      };
      setErrors({});
      const result = await validationSchema.validate(data, {
        abortEarly: false,
      });

      setEnableBtnSubmit(false);
      const formData = new FormData();
      formData.append("type", MEDIA_TYPE.VIDEO);
      formData.append("order", result?.order);
      formData.append("name", result?.contentTitle);
      formData.append("path", "media/media_videos/films");
      if (file) formData.append("file", file);

      toast.info("Please wait, media file uploading!");
      const formDataThumbnail = new FormData();
      formDataThumbnail.append("type", "image");
      formDataThumbnail.append("name", `Thumbnail ${result?.contentTitle}`);
      formDataThumbnail.append("path", "media/media_videos/thumbnails");
      if (thumbnail)
        formDataThumbnail.append("file", thumbnail, `thumbnail_${Date.now()}`);

      const updatedContent = await mediaAPI.updateMedia(content.id, formData);
      const updatedThumbnail = await mediaAPI.updateMedia(
        thumbnailContent.id,
        formDataThumbnail
      );
      const { id, createdAt, updatedAt, media, ...rest } = gallery;
      const updatedGallery = await galleryApi.updateGallery(gallery.id, {
        ...rest,
        media: media.map((m) => m.id),
        order: result?.order,
        unitId: result?.unitId,
        category: contentSection.toLowerCase(),
      });
      if (
        updatedContent?.data &&
        updatedThumbnail?.data &&
        updatedGallery?.data
      ) {
        toast.success("Content updated successfully!");
        handleClose && handleClose();
        onSaveSuccess && onSaveSuccess();
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
      toast.error("Upload media failed!");
      setEnableBtnSubmit(true);
    }
  };

  const handleUpdateContentInteriors = async () => {
    if (!content) return;

    try {
      const data = {
        order,
        contentTitle,
        contentSection,
        unitId,
      };
      setErrors({});
      const result = await validationSchema.validate(data, {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append("type", "image");
      formData.append("order", result?.order);
      formData.append("name", result?.contentTitle);
      formData.append("path", "media/media_images/image_gallery");

      if (file) {
        formData.append("file", file);
      }

      const updated = await mediaAPI.updateMedia(content.id, formData);
      const { id, createdAt, updatedAt, media, ...rest } = gallery;
      const updatedGallery = await galleryApi.updateGallery(gallery.id, {
        ...rest,
        media: media.map((m) => m.id),
        order: result?.order,
        unitId: result?.unitId,
        category: contentSection.toLowerCase(),
      });
      if (updated?.data && updatedGallery?.data) {
        toast.success("Content updated successfully!");
        handleClose && handleClose();
        onSaveSuccess && onSaveSuccess();
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
    }
  };

  const handleUpdateContentExteriors = async () => {
    if (!content) return;

    try {
      const data = {
        order,
        contentTitle,
        contentSection,
        unitId,
      };
      setErrors({});
      const result = await validationSchema.validate(data, {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append("type", "image");
      formData.append("order", result?.order);
      formData.append("name", result?.contentTitle);
      formData.append("path", "media/media_images/image_gallery");

      if (file) {
        formData.append("file", file);
      }

      const updated = await mediaAPI.updateMedia(content.id, formData);
      const { id, createdAt, updatedAt, media, ...rest } = gallery;
      const updatedGallery = await galleryApi.updateGallery(gallery.id, {
        ...rest,
        media: media.map((m) => m.id),
        order: result?.order,
        unitId: result?.unitId,
        category: contentSection.toLowerCase(),
      });
      if (updated?.data && updatedGallery?.data) {
        toast.success("Content updated successfully!");
        handleClose && handleClose();
        onSaveSuccess && onSaveSuccess();
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
    }
  };

  const handleDeleteContent = async () => {
    if (!content) return;

    try {
      const deleted = await mediaAPI.deleteMedia(content.id);
      if (deleted.data) {
        await galleryApi.deleteGallery(gallery?.id);

        toast.success("Content deleted successfully!");
        handleOpenDeleteContentModal();
        handleClose && handleClose();
        onSaveSuccess && onSaveSuccess();
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleOpenDeleteContentModal = () => {
    setIsShowDeleteContentModal(true);
  };

  const handleOpenReplaceContentModal = (type) => () => {
    setIsShowReplaceContentModal(true);
    setReplaceContentType(type);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleCloseDeleteContentModal = () => {
    setIsShowDeleteContentModal(false);
  };

  const handleCloseReplaceContentModal = () => {
    setIsShowReplaceContentModal(false);

    setReplaceContentType("");
  };

  const onOpenFileInput = () => {
    fileRef.current.click();
  };

  const onOpenThumbnailInput = () => {
    thumbnailRef.current.click();
  };

  const handleBrowserFile = (e) => {
    setFile(e.target.files[0]);
    handleCloseReplaceContentModal();
  };

  const handleBrowseThumbnail = (e) => {
    setThumbnail(e.target.files[0]);
    handleCloseReplaceContentModal();
  };

  const renderImagePreview = () => {
    if (file) {
      return URL.createObjectURL(file);
    }

    if (content && content.path) {
      return getS3BEMediaUrl(content.path);
    }

    return "/images/image.png";
  };

  const renderThumbnailPreview = () => {
    if (thumbnail) {
      return URL.createObjectURL(thumbnail);
    }

    if (thumbnailContent && thumbnailContent.path) {
      return getS3BEMediaUrl(thumbnailContent.path);
    }

    return "/images/image.png";
  };

  if (isShowReplaceContentModal) {
    return (
      <Modal
        iconCancelMark={
          <div className="z-[10000] cursor-pointer fixed top-[50px] right-[80px]">
            <img
              src={closeIcon}
              alt="close-icon"
              onClick={handleCloseReplaceContentModal}
            />
          </div>
        }
        maskClassName="opacity-85"
        zIndex="z-[9998]"
        panelClassName="w-[630px] p-0 rounded-none"
        wrapperClassName="wrap-replace-content-modal p-[40px]"
        show={isShowReplaceContentModal}
        setShow={handleCloseReplaceContentModal}
        content={
          <div className="text-white relative flex flex-col gap-10 max-h-[calc(100vh_-_290px)] p-0 overflow-y-auto">
            <h2 className="text-black text-[30px] font-[700] uppercase font-poppins">
              REPLACE CONTENT
            </h2>
            <div className="fixed w-[26px] h-6 z-[110] right-20 top-[50px]">
              <img
                src={closeIcon}
                alt="close-icon"
                onClick={handleCloseReplaceContentModal}
              />
            </div>
            <div className="text-black text-sm not-italic font-bold leading-[normal] uppercase pb-2.5 border-b-[#ccc] border-b border-solid;">
              CONTENT
            </div>
            <div
              className="text-black text-sm not-italic font-bold leading-[normal] uppercase border text-center cursor-pointer mt-5 px-[18px] py-[7px] border-solid border-black mt-0"
              onClick={
                replaceContentType === "thumbnail"
                  ? onOpenThumbnailInput
                  : onOpenFileInput
              }
            >
              BROWSE FILES
              <input
                ref={
                  replaceContentType === "thumbnail" ? thumbnailRef : fileRef
                }
                accept={`${
                  replaceContentType === "video" ? "video" : "image"
                }/*`}
                id="file"
                className="hidden"
                type="file"
                onChange={(e) =>
                  replaceContentType === "thumbnail"
                    ? handleBrowseThumbnail(e)
                    : handleBrowserFile(e)
                }
              />
            </div>
            {mediaType !== MEDIA_TYPE_ENUM.VIDEO && (
              <div className="flex flex-col gap-[15px]" onClick={() => {}}>
                <span className="text-[rgba(45,41,39,0.5)] text-sm not-italic font-normal leading-[normal] text-left">
                  Or import from a URL
                </span>
                <div className="flex justify-endm">
                  <input
                    type="text"
                    placeholder="Add a URL"
                    className="w-full pl-[5px] border-transparent focus:border-transparent focus:ring-0 bg-[#f5f7f7"
                  />
                  <span className="text-black text-sm not-italic font-bold leading-[normal] uppercase border cursor-pointer px-[18px] py-3 border-solid border-black">
                    IMPORT
                  </span>
                </div>
              </div>
            )}
          </div>
        }
      />
    );
  }

  if (isShowDeleteContentModal) {
    return (
      <Modal
        iconCancelMark={
          <div className="z-[10000] cursor-pointer fixed top-[50px] right-[80px]">
            <img
              src={closeIcon}
              alt="close-icon"
              onClick={handleCloseDeleteContentModal}
            />
          </div>
        }
        wrapperClassName="wrap-delete-content-modal"
        panelClassName="w-[521px] p-0 rounded-none"
        maskClassName="opacity-90"
        zIndex="z-[9998]"
        show={isShowDeleteContentModal}
        setShow={handleCloseDeleteContentModal}
        content={
          <div className="p-[40px] space-y-5">
            <h2 className="text-black text-[30px] font-[700] uppercase font-poppins">
              Delete Content?
            </h2>
            <div className="text-white relative flex flex-col gap-10 max-h-[calc(100vh_-_290px)] p-0 overflow-y-auto">
              <div className="font-proxima text-[14px] text-[#000] font-[400] leading-[17.5px]">
                Are you sure you want to delete {content?.name}?
              </div>
            </div>
            <div className="mt-5">
              <div
                onClick={handleDeleteContent}
                className="px-[18px] py-[12px] text-center  text-[#E11736] cursor-pointer font-[700] font-poppins text-[14px] border-[1px] border-solid border-[#E11736] uppercase w-full"
              >
                YES, DELETE
              </div>
            </div>
          </div>
        }
      />
    );
  }

  return (
    <Modal
      iconCancelMark={() => (
        <div className="z-[9999] cursor-pointer fixed top-[50px] right-[80px]">
          <img src={closeIcon} alt="close-icon" />
        </div>
      )}
      wrapperClassName="wrap-update-content-modal"
      panelClassName="w-[698px] p-0 rounded-none"
      zIndex="z-[9998]"
      maskClassName="opacity-90"
      show={show}
      setShow={handleClose}
      content={
        <div className="p-[40px] max-h-[90vh]">
          <h2 className="text-black text-[30px] font-[700] uppercase font-poppins">
            Update Content
          </h2>
          <div className="text-white relative flex flex-col gap-10 max-h-[calc(100vh_-_290px)] p-0 overflow-y-auto">
            <div className="flex flex-col relative">
              <div className="text-black text-sm not-italic font-bold leading-[normal] uppercase pb-2.5 border-b-[#ccc] border-b border-solid;">
                CONTENT
              </div>
              <div div className="min-h-[330px] cursor-pointer">
                {mediaType === MEDIA_TYPE_ENUM.IMAGE && (
                  <img
                    style={{ width: "100%" }}
                    className="img-fluid"
                    src={renderImagePreview()}
                    alt=""
                  />
                )}
                {mediaType === MEDIA_TYPE_ENUM.VIDEO && (
                  <video className="img-fluid">
                    <source src={renderImagePreview()} type="video/mp4" />
                  </video>
                )}
                <div
                  onClick={handleOpenReplaceContentModal(mediaType)}
                  className={`absolute w-full cursor-pointer top-[32px] bottom-0 bg-[#2b2b2b5a] ${mediaType}`}
                >
                  <img
                    className="w-[50px] h-[50px] absolute -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4"
                    src={uploadIcon}
                    alt="upload-icon"
                  />
                </div>
              </div>
              <span className="text-[#ae6537] text-sm not-italic font-normal leading-[150%]">
                {errors?.file}
              </span>
            </div>
            {mediaType === MEDIA_TYPE_ENUM.VIDEO && (
              <div className="flex flex-col relative thumbnail">
                <div className="@apply text-black text-sm not-italic font-bold leading-[normal] uppercase pb-2.5 border-b-[#ccc] border-b border-solid">
                  THUMBNAIL
                </div>
                <div className="min-h-[330px]">
                  <img
                    className="img-fluid cursor-pointer h-[330px] object-cover mt-5"
                    src={renderThumbnailPreview()}
                    alt=""
                  />
                  <div
                    onClick={handleOpenReplaceContentModal("thumbnail")}
                    className={`absolute w-full cursor-pointer top-[32px] bottom-0 bg-[#2b2b2b5a] thumbnail ${
                      content || file ? "has-file" : ""
                    }`}
                  >
                    <img
                      className="w-[50px] h-[50px] absolute -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4"
                      src={uploadIcon}
                      alt="upload-icon"
                    />
                  </div>
                </div>
                <span className="text-[#ae6537] text-sm not-italic font-normal leading-[150%]">
                  {errors?.thumbnail}
                </span>
              </div>
            )}
            <div className="flex gap-5 flex-col mb-2.5">
              <div
                className="text-black text-sm not-italic font-bold leading-[normal] uppercase pb-2.5 border-b-[#ccc] border-b border-solid;
               mb-[15px]"
              >
                INFORMATION
              </div>
              <div className="flex flex-wrap mb-[15px]">
                <label className="w-full text-[14px] font-bold mb-[15px] uppercase text-[#000] font-poppins">
                  Content Title*
                </label>
                <input
                  id="contentTitle"
                  name="contentTitle"
                  type="input"
                  className="w-full focus-visible:outline-none !focus-visible:shadow-none h-10 border-b-[1px] border-solid border-[#000] font-poppins text-[14px] font-[400] text-[#000] "
                  value={contentTitle}
                  placeholder="Content Title"
                  onChange={(e) => setContentTitle(e.target.value)}
                />
                <span className="text-[#ae6537] text-sm not-italic font-normal leading-[150%]">
                  {errors?.contentTitle}
                </span>
              </div>
              <div className="flex flex-wrap">
                <label className="w-full text-[14px] font-bold mb-[15px] uppercase text-[#000] font-poppins">
                  Content Section*
                </label>
                <Select
                  className="content-section w-full"
                  classNamePrefix="select"
                  value={{ value: contentSection, label: contentSection }}
                  options={ContentSectionOptions}
                  name="contentSection"
                  isSearchable={false}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  onChange={(item) => setContentSection(item?.value)}
                />
                <span className="text-[14px] text-[#AE6537] font-proxima leading-[150%]">
                  {errors?.contentSection}
                </span>
              </div>
              <div className="flex flex-wrap mt-[15px]">
                <label className="w-full text-[14px] font-bold mb-[15px] uppercase text-[#000] font-poppins">
                  Associated Residence
                </label>
                <Select
                  className="content-section w-full"
                  classNamePrefix="select"
                  value={{
                    value: unitId || "",
                    label: `Residence ${
                      units.find((unit) => unit.id === unitId)?.name || ""
                    }`,
                  }}
                  options={units.map((unit) => ({
                    label: `Residence ${unit.name}`,
                    value: unit.id,
                  }))}
                  name="unitId"
                  isSearchable={false}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  onChange={(item) => setUnitId(item?.value)}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <span
              className="border-solid border-2 font-poppins font-[700] border-black text-center cursor-pointer m-0 text-[14px] px-[18px] py-[12px]"
              onClick={handleUpdateContent}
            >
              SAVE
            </span>
            <span
              className="text-[#CCC] font-poppins font-[400] cursor-pointer m-0 text-[14px]"
              onClick={handleOpenDeleteContentModal}
            >
              DELETE CONTENT
            </span>
          </div>
        </div>
      }
    />
  );
};

export default EditContentModal;
