import React from "react";
import { IconCloseButton } from "../svgs/icons";

const Modal = ({
                 open,
                 onHide,
                 isShowBtnClose = true,
                 children,
                 classNames,
                 isOutSideClose = true,
                 currentColor = "white",
               }) => {
  return (
    <>
      {open && (
        <div
          className={`fixed bg-[rgba(0,0,0,0.5)] z-[20000] justify-center items-center flex inset-0 ${classNames}`}
          onClick={isOutSideClose ? onHide : () => {}}
        >
          {isShowBtnClose && (
            <div className="absolute cursor-pointer z-[999] right-[35px] top-[100px]" onClick={onHide}>
              <IconCloseButton style={{width: 26}} fill={currentColor} />
            </div>
          )}
          <div className="h-full max-w-[900px] min-w-[500px] bg-transparent flex justify-center items-center">
            <div className="w-full h-[500px] bg-white overflow-y-scroll p-5 rounded-lg">{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
