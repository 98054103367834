import React, { useRef, useState, useEffect } from "react";
import * as yup from "yup";
import { toast } from "react-toastify";
import Select from "react-select";
import closeIcon from "../../../../assets/images/close-white.svg";
import uploadIcon from "../../../../assets/images/upload.svg";
import mediaAPI from "../../../../apis/api/media";
import galleryApi from "../../../../apis/api/gallery";
import { ContentSectionOptions, ContentSection } from "..";
import { GALLERY_TYPE } from "../../../../constants/options";
import { MEDIA_TYPE_ENUM } from "../../../../constants/master-data";
import { Modal } from "../../components/commons";
import { MEDIA_TYPE } from "../../utils";

const validationSchema = yup.object().shape({
  file: yup.mixed().required("File is a required field"),
  order: yup.number().required("Order is a required field"),
  contentTitle: yup
    .string()
    .trim()
    .required("Content title is a required field"),
  contentSection: yup
    .string()
    .trim()
    .required("Content section is a required field"),
});

const filmValidationSchema = yup.object().shape({
  file: yup.mixed().required("File is a required field"),
  order: yup.number().required("Order is a required field"),
  contentTitle: yup
    .string()
    .trim()
    .required("Content title is a required field"),
  contentSection: yup
    .string()
    .trim()
    .required("Content section is a required field"),
  thumbnail: yup.mixed().required("No thumbnail chosen"),
});

const AddContentModal = ({
  show,
  setShow,
  mediaType = MEDIA_TYPE_ENUM.IMAGE,
  onCreateSuccess,
}) => {
  const [file, setFile] = useState();
  const [order, setOrder] = useState();
  const [contentTitle, setContentTitle] = useState("");
  const [contentSection, setContentSection] = useState("");
  const [associatedResidence, setAssociatedResidence] = useState("");
  const [isShowReplaceContentModal, setIsShowReplaceContentModal] =
    useState(false);
  const [enableBtnSubmit, setEnableBtnSubmit] = useState(true);
  const [thumbnail, setThumbnail] = useState();
  const [replaceContentType, setReplaceContentType] = useState("");
  const [errors, setErrors] = useState({});
  const fileRef = useRef();
  const thumbnailRef = useRef();

  useEffect(() => {
    if (file) {
      setContentTitle(file.name);
    }
  }, [file]);

  const handleAddNewContent = () => {
    if (!enableBtnSubmit) {
      toast.info("Please wait, media file uploading!");
      return;
    }
    switch (contentSection) {
      case ContentSection.Exteriors:
        handleAddContentExteriors();
        return;
      case ContentSection.Interiors:
        handleAddContentInteriors();
        return;
      case ContentSection.Amenities:
        handleAddContentAmenities();
        return;
      case ContentSection.Films:
        handleAddNewContentFilms();
        return;
      default:
        setErrors({
          ...errors,
          contentSection: "Content section is a required field",
        });
        return;
    }
  };

  const handleAddNewContentFilms = async () => {
    try {
      const data = {
        order: 0,
        contentTitle,
        contentSection,
        file,
        thumbnail,
      };
      setErrors({});
      const result = await filmValidationSchema.validate(data, {
        abortEarly: false,
      });

      setEnableBtnSubmit(false);
      const formData = new FormData();
      formData.append("type", MEDIA_TYPE.VIDEO);
      formData.append("order", result?.order);
      formData.append("name", result?.contentTitle);
      formData.append("path", "media/media_videos/films");
      formData.append("file", file);

      const formDataThumbnail = new FormData();
      formDataThumbnail.append("type", "image");
      formDataThumbnail.append("name", `Thumbnail ${result?.contentTitle}`);
      formDataThumbnail.append("path", "media/media_videos/thumbnails");
      formDataThumbnail.append("file", thumbnail, `thumbnail_${Date.now()}`);

      toast.info("Please wait, media file uploading!");
      const uploaded = await mediaAPI.uploadMedia(formData);
      const uploadedThumbnail = await mediaAPI.uploadMedia(formDataThumbnail);

      if (uploaded?.data && uploadedThumbnail?.data) {
        await galleryApi.createGallery({
          type: GALLERY_TYPE.FILMS,
          category: GALLERY_TYPE.FILMS,
          media: [uploaded?.data?.id, uploadedThumbnail?.data?.id],
          order: result?.order,
        });
        toast.success("Add new content successfully!");
        handleClose && handleClose();
        onCreateSuccess && onCreateSuccess();
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
      toast.error("Upload media failed!");
      setEnableBtnSubmit(true);
    }
  };

  const handleAddContentInteriors = async () => {
    try {
      const data = {
        order: 0,
        contentTitle,
        contentSection,
        file,
      };
      setErrors({});
      const result = await validationSchema.validate(data, {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append("type", "image");
      formData.append("order", result?.order);
      formData.append("name", result?.contentTitle);
      formData.append("path", "media/media_images/image_gallery");
      formData.append("file", file);

      const uploaded = await mediaAPI.uploadMedia(formData);
      if (uploaded?.data) {
        await galleryApi.createGallery({
          type: "media_images",
          media: [uploaded?.data?.id],
          category: "interiors",
          order: result?.order,
        });
        toast.success("Add new content successfully!");
        handleClose && handleClose();
        onCreateSuccess && onCreateSuccess();
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
    }
  };

  const handleAddContentAmenities = async () => {
    try {
      const data = {
        order: 0,
        contentTitle,
        contentSection,
        file,
      };
      setErrors({});
      const result = await validationSchema.validate(data, {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append("type", "image");
      formData.append("order", result?.order);
      formData.append("name", result?.contentTitle);
      formData.append("path", "media/media_images/image_gallery");
      formData.append("file", file);

      const uploaded = await mediaAPI.uploadMedia(formData);
      if (uploaded?.data) {
        await galleryApi.createGallery({
          type: "media_images",
          media: [uploaded?.data?.id],
          category: "amenities",
          order: result?.order,
        });
        toast.success("Add new content successfully!");
        handleClose && handleClose();
        onCreateSuccess && onCreateSuccess();
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
    }
  };

  const handleAddContentExteriors = async () => {
    try {
      const data = {
        order: 0,
        contentTitle,
        contentSection,
        file,
      };

      setErrors({});
      const result = await validationSchema.validate(data, {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append("type", "image");
      formData.append("order", result?.order);
      formData.append("name", result?.contentTitle);
      formData.append("path", "media/media_images/image_gallery");
      formData.append("file", file);

      const uploaded = await mediaAPI.uploadMedia(formData);
      if (uploaded?.data) {
        await galleryApi.createGallery({
          type: "media_images",
          media: [uploaded?.data?.id],
          category: "exteriors",
          order: result?.order,
        });
        toast.success("Add new content successfully!");
        handleClose && handleClose();
        onCreateSuccess && onCreateSuccess();
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const onOpenFileInput = () => {
    if (!file || isShowReplaceContentModal) {
      fileRef.current.click();
    } else {
      handleOpenReplaceContentModal(mediaType);
    }
  };

  const onOpenThumbnailInput = () => {
    if (!thumbnail || isShowReplaceContentModal) {
      thumbnailRef.current.click();
    } else {
      handleOpenReplaceContentModal("thumbnail");
    }
  };

  const renderImagePreview = () => {
    if (file) {
      return URL.createObjectURL(file);
    }

    return "/images/image.png";
  };

  const renderThumbnailPreview = () => {
    if (thumbnail) {
      return URL.createObjectURL(thumbnail);
    }

    return "/images/image.png";
  };

  const handleBrowserFile = (e) => {
    setFile(e.target.files[0]);
    handleCloseReplaceContentModal();
  };

  const handleBrowseThumbnail = (e) => {
    setThumbnail(e.target.files[0]);
    handleCloseReplaceContentModal();
  };

  const handleOpenReplaceContentModal = (type) => {
    setIsShowReplaceContentModal(true);
    setReplaceContentType(type);
  };

  const handleCloseReplaceContentModal = () => {
    setIsShowReplaceContentModal(false);
    setReplaceContentType("");
  };

  if (isShowReplaceContentModal) {
    return (
      <Modal
        iconCancelMark={
          <div className="z-[10000] cursor-pointer fixed top-[50px] right-[80px]">
            <img src={closeIcon} alt="close-icon" />
          </div>
        }
        maskClassName="opacity-85"
        zIndex="z-[9998]"
        panelClassName="w-[630px] p-0 rounded-none"
        wrapperClassName="wrap-replace-content-modal p-[40px]"
        show={isShowReplaceContentModal}
        setShow={handleCloseReplaceContentModal}
        content={
          <div className="text-white relative flex flex-col gap-10 max-h-[calc(100vh_-_290px)] p-0 overflow-y-auto">
            <h2 className="text-black text-[30px] font-[700] uppercase font-poppins">
              REPLACE CONTENT
            </h2>
            <div className="fixed w-[26px] h-6 z-[110] right-20 top-[50px]">
              <img
                src={closeIcon}
                alt="close-icon"
                onClick={handleCloseReplaceContentModal}
              />
            </div>
            <div
              className="text-black text-sm not-italic font-bold leading-[normal] uppercase border text-center cursor-pointer mt-5 px-[18px] py-[7px] border-solid border-black"
              onClick={
                replaceContentType === "thumbnail"
                  ? onOpenThumbnailInput
                  : onOpenFileInput
              }
            >
              BROWSE FILES
              <input
                ref={
                  replaceContentType === "thumbnail" ? thumbnailRef : fileRef
                }
                accept={`${
                  replaceContentType === "video" ? "video" : "image"
                }/*`}
                id="file"
                className="hidden"
                type="file"
                onChange={(e) =>
                  replaceContentType === "thumbnail"
                    ? handleBrowseThumbnail(e)
                    : handleBrowserFile(e)
                }
              />
            </div>
            {mediaType !== MEDIA_TYPE_ENUM.VIDEO && (
              <div className="flex flex-col gap-[15px]" onClick={() => {}}>
                <span className="text-[rgba(45,41,39,0.5)] text-sm not-italic font-normal leading-[normal] text-left">
                  Or import from a URL
                </span>
                <div className="flex justify-end">
                  <input
                    type="text"
                    placeholder="Add a URL"
                    className="w-full pl-[5px] border-transparent focus:border-transparent focus:ring-0 bg-[#f5f7f7"
                  />
                  <span className="text-black text-sm not-italic font-bold leading-[normal] uppercase border cursor-pointer px-[18px] py-3 border-solid border-black">
                    IMPORT
                  </span>
                </div>
              </div>
            )}
          </div>
        }
      />
    );
  }

  return (
    <Modal
      iconCancelMark={() => (
        <div className="z-[9999] cursor-pointer fixed top-[50px] right-[80px]">
          <img src={closeIcon} alt="close-icon" />
        </div>
      )}
      wrapperClassName="wrap-update-content-modal"
      panelClassName="w-[630px] p-0 rounded-none"
      zIndex="z-[9998]"
      maskClassName="opacity-90"
      show={show}
      setShow={handleClose}
      content={
        <div className="p-[40px] max-h-[90vh] ">
          <h2 className="text-black text-[30px] font-[700] uppercase font-poppins">
            ADD NEW CONTENT
          </h2>
          <div className="text-white relative flex flex-col gap-10 max-h-[calc(100vh_-_290px)] p-0 overflow-y-auto">
            <div className="flex flex-col relative mt-5">
              <div className="text-black text-sm not-italic font-bold leading-[normal] uppercase pb-2.5 border-b-[#ccc] border-b border-solid;">
                CONTENT
              </div>
              {file ? (
                <>
                  {mediaType === MEDIA_TYPE_ENUM.IMAGE && (
                    <img
                      className="img-fluid cursor-pointer h-[330px] object-cover mt-5"
                      src={renderImagePreview()}
                      alt=""
                    />
                  )}
                  {mediaType === MEDIA_TYPE_ENUM.VIDEO && (
                    <video className="img-fluid cursor-pointer h-[330px] object-cover mt-5">
                      <source src={renderImagePreview()} type="video/mp4" />
                    </video>
                  )}
                  <input
                    ref={fileRef}
                    accept={`${mediaType}/*`}
                    id="file"
                    className="hidden"
                    type="file"
                    onChange={(e) => handleBrowserFile(e)}
                  />
                  <div
                    onClick={onOpenFileInput}
                    className="absolute w-full cursor-pointer top-[32px] bottom-0 bg-[#2b2b2b5a]"
                  >
                    <img className="m-0" src={uploadIcon} alt="upload-icon" />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="text-black text-sm not-italic font-bold leading-[normal] uppercase border text-center cursor-pointer mt-5 px-[18px] py-[7px] border-solid border-black"
                    onClick={onOpenFileInput}
                  >
                    BROWSE FILES
                    <input
                      ref={fileRef}
                      accept={`${mediaType}/*`}
                      id="file"
                      className="hidden"
                      type="file"
                      onChange={(e) => handleBrowserFile(e)}
                    />
                  </div>
                </>
              )}
              <span className="text-[#ae6537] text-sm not-italic font-normal leading-[150%]">
                {errors?.file}
              </span>
            </div>
            {mediaType === MEDIA_TYPE_ENUM.VIDEO && (
              <div className="content">
                <div className="text-black text-sm not-italic font-bold leading-[normal] uppercase pb-2.5 border-b-[#ccc] border-b border-solid;">
                  THUMBNAIL
                </div>
                <div div className="min-h-[330px]">
                  {thumbnail ? (
                    <>
                      <img
                        className="img-fluid cursor-pointer"
                        src={renderThumbnailPreview()}
                        alt=""
                      />
                      <input
                        ref={thumbnailRef}
                        accept={`image/*`}
                        id="file"
                        className="hidden"
                        type="file"
                        onChange={(e) => handleBrowseThumbnail(e)}
                      />
                      <div
                        onClick={onOpenThumbnailInput}
                        className={`absolute w-full cursor-pointer top-[32px] bottom-0 bg-[#2b2b2b5a] ${
                          file ? "has-file" : ""
                        }`}
                      >
                        <img
                          className="w-[50px] h-[50px] absolute -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4"
                          src={uploadIcon}
                          alt="upload-icon"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="text-black text-sm not-italic font-bold leading-[normal] uppercase border text-center cursor-pointer mt-5 px-[18px] py-[7px] border-solid border-black"
                        onClick={onOpenThumbnailInput}
                      >
                        BROWSE FILES
                        <input
                          ref={thumbnailRef}
                          accept={`image/*`}
                          id="file"
                          className="hidden"
                          type="file"
                          onChange={(e) => handleBrowseThumbnail(e)}
                        />
                      </div>
                    </>
                  )}
                </div>
                <span className="text-[#ae6537] text-sm not-italic font-normal leading-[150%]">
                  {errors?.thumbnail}
                </span>
              </div>
            )}

            <div className="flex gap-5 flex-col mb-2.5">
              <div className="text-black text-sm not-italic font-bold leading-[normal] uppercase pb-2.5 border-b-[#ccc] border-b border-solid; mb-[15px]">
                INFORMATION
              </div>
              <div className="flex flex-wrap mb-[15px]">
                <label className="w-full text-[14px] font-bold mb-[15px] uppercase text-[#000] font-poppins">
                  Content Title*
                </label>
                <input
                  id="contentTitle"
                  name="contentTitle"
                  type="input"
                  className="w-full focus-visible:outline-none !focus-visible:shadow-none h-10 border-b-[1px] border-solid border-[#000] font-poppins text-[14px] font-[400] text-[#000] "
                  value={contentTitle}
                  placeholder="Content Title"
                  onChange={(e) => setContentTitle(e.target.value)}
                />
                <span className="text-[#ae6537] text-sm not-italic font-normal leading-[150%]">
                  {errors?.contentTitle}
                </span>
              </div>
              <div className="flex flex-wrap">
                <label className="w-full text-[14px] font-bold mb-[15px] uppercase text-[#000] font-poppins">
                  Content Section*
                </label>
                <Select
                  className="content-section w-full"
                  classNamePrefix="select"
                  value={{
                    value: contentSection || "",
                    label: contentSection || "Select a content section",
                  }}
                  options={ContentSectionOptions}
                  name="contentSection"
                  isSearchable={false}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  onChange={(item) => setContentSection(item?.value)}
                />
                <span className="text-[14px] text-[#AE6537] font-proxima leading-[150%]">
                  {errors?.contentSection}
                </span>
              </div>
            </div>
            <div
              className="font-poppins text-[14px] font-[700] text-[#000] mt-[20px] px-[18px] py-[7px] uppercase border-solid border-[1px] border-[#000] w-full text-center"
              onClick={handleAddNewContent}
            >
              SAVE
            </div>
          </div>
        </div>
      }
    />
  );
};

export default AddContentModal;
