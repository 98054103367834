import React from "react";
import { useController } from "react-hook-form";
import { cn } from "../../../../helper/utils";

function InputField(props) {
  const {
    inputProps,
    controller,
    errorCol = "w-full",
    disabled,
    spanCol = "w-full",
    isRequired = false,
    label,
    wrapperClassName,
    inputClassName,
    leftChild,
    callbackOnchange,
    className,
  } = props;
  const { fieldState, field } = useController(controller);
  const { error } = fieldState;

  const onChange = (e) => {
    const value = e.target.value;
    field.onChange(value);
    callbackOnchange && callbackOnchange?.(value);
  };

  const onKeydown = (e) => {
    const isInt = inputProps?.isInt;
    let exceptThisSymbols = ["e", "E", "+", "-", ","];

    if (isInt) {
      exceptThisSymbols = ["e", "E", "+", "-", ",", "."];
    }

    if (inputProps?.type === "number") {
      exceptThisSymbols.includes(e.key) && e.preventDefault();
    }
  };

  return (
    <div className={cn(wrapperClassName)}>
      {label && (
        <legend className="text-[14px] text-[#5d5d5d] mb-[10px] font-[500]">
          {label} {isRequired && <span className="text-red-500">*</span>}
        </legend>
      )}

      <div className="flex flex-wrap w-full">
        <div className={`relative ${spanCol}`}>
          {leftChild}
          <input
            {...inputProps}
            {...field}
            type={inputProps?.type || "text"}
            value={field.value || ""}
            style={{ width: "100%" }}
            onChange={onChange}
            onKeyDown={onKeydown}
            disabled={disabled}
            className={cn(
              "focus:outline-0 border-[#000] focus:ring-0 border-[1px] border-solid focus-visible:outline-0 text-black p-[10px] rounded-[8px] flex-1",
              {
                "!border-[#e11736]": error,
              },
              inputClassName
            )}
          />
        </div>
        {error && (
          <div className={`${errorCol}`}>
            <p
              className={cn({
                "text-[#f44336] text-[12px]": error,
              })}
            >
              {error.message}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default InputField;
