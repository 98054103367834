/** @format */

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { reqGetListUnits } from "../../../../reduxs/cms/action";
import Tabs from "../Tabs";

const Sidebar = () => {
  const dispatch = useDispatch();

  const getListProperties = async () => {
    dispatch(
      reqGetListUnits({
        sortBy: JSON.stringify({
          name: 1,
        }),
      })
    );
  };

  useEffect(() => {
    getListProperties();
  }, []);

  return (
    <div className="flex flex-col w-64 text-white rounded-lg bg-white">
      <Tabs />
    </div>
  );
};

export default Sidebar;
