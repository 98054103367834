import React, { Fragment } from "react";
import { convertLabelSetting } from "../../utils";
import pageConfiguration from "../../../../../apis/api/page-configuration";
import { reqGetFeatureList } from "../../../../../reduxs/page-configuration/action";
import { useDispatch } from "react-redux";

const HeaderFeature = ({ feature, idModule }) => {
  const dispatch = useDispatch();

  const toggleVisibleFeature = async (idFeature, visible) => {
    try {
      const res = await pageConfiguration.updateFeature(idFeature, {
        props: {
          visible,
        },
      });
      if (res) {
        await dispatch(reqGetFeatureList({ idModule }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div>
        {"Feature " +
          `"${String(
            convertLabelSetting(feature?.name, false)
          ).toUpperCase()}"`}
      </div>
      <input
        type="checkbox"
        className="w-5 h-5 rounded-[5px]"
        checked={feature.props?.visible}
        onChange={(e) => toggleVisibleFeature(feature?.id, e.target.checked)}
      />
    </Fragment>
  );
};

export default HeaderFeature;
