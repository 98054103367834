import React from "react";
import icEdit from "../../../assets/images/cms/edit.png";
import TrashIcon from "../configuration/components/svgs/TrashIcon";
import PlusIcon from "../configuration/components/svgs/PlusIcon";
import { Modal } from "../components/commons";
import CreateFolderForm from "./left-panel/CreateFolderForm";
import EditFolderForm from "./left-panel/EditFolderForm";
import DeleteFolder from "./left-panel/DeleteFolder";

const FolderIcon = ({ title, isShowEdit, isShowAdd, item, onClick }) => (
  <div
    className="relative group/folder"
    style={{ marginTop: -10 }}
    onClick={onClick}
  >
    <span className="text-black text-sm absolute inset-0 flex justify-center items-center">
      {title}
    </span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100"
      height="100"
      viewBox="0 0 48 48"
    >
      <linearGradient
        id="WQEfvoQAcpQgQgyjQQ4Hqa_dINnkNb1FBl4_gr1"
        x1="24"
        x2="24"
        y1="6.708"
        y2="14.977"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#eba600"></stop>
        <stop offset="1" stop-color="#c28200"></stop>
      </linearGradient>
      <path
        fill="url(#WQEfvoQAcpQgQgyjQQ4Hqa_dINnkNb1FBl4_gr1)"
        d="M24.414,10.414l-2.536-2.536C21.316,7.316,20.553,7,19.757,7L5,7C3.895,7,3,7.895,3,9l0,30	c0,1.105,0.895,2,2,2l38,0c1.105,0,2-0.895,2-2V13c0-1.105-0.895-2-2-2l-17.172,0C25.298,11,24.789,10.789,24.414,10.414z"
      ></path>
      <linearGradient
        id="WQEfvoQAcpQgQgyjQQ4Hqb_dINnkNb1FBl4_gr2"
        x1="24"
        x2="24"
        y1="10.854"
        y2="40.983"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#ffd869"></stop>
        <stop offset="1" stop-color="#fec52b"></stop>
      </linearGradient>
      <path
        fill="url(#WQEfvoQAcpQgQgyjQQ4Hqb_dINnkNb1FBl4_gr2)"
        d="M21.586,14.414l3.268-3.268C24.947,11.053,25.074,11,25.207,11H43c1.105,0,2,0.895,2,2v26	c0,1.105-0.895,2-2,2H5c-1.105,0-2-0.895-2-2V15.5C3,15.224,3.224,15,3.5,15h16.672C20.702,15,21.211,14.789,21.586,14.414z"
      ></path>
    </svg>
    {isShowEdit && (
      <div className="invisible group-hover/folder:visible flex flex-col justify-between absolute right-[-35px] items-center top-[30px]">
        <Modal
          maskClassName="bg-opacity-50"
          zIndex="z-[9998]"
          maskClosable
          trigger={
            <span className="text-red-500 cursor-pointer">
              <TrashIcon />
            </span>
          }
          content={({ setIsShow }) => (
            <DeleteFolder onClose={() => setIsShow(false)} item={item} />
          )}
        />
        <Modal
          maskClosable
          maskClassName="bg-opacity-50"
          zIndex="z-[9998]"
          title="Edit folder"
          trigger={<img src={icEdit} alt="edit" className="w-12" />}
          content={({ setIsShow }) => (
            <EditFolderForm onClose={() => setIsShow(false)} item={item} />
          )}
        />
      </div>
    )}
    {isShowAdd && (
      <Modal
        maskClosable
        maskClassName="bg-opacity-50"
        zIndex="z-[9998]"
        title="Create new folder"
        trigger={
          <div
            className={`cursor-pointer bg-gray-100 rounded-lg invisible group-hover/folder:visible flex flex-col justify-between absolute right-[-20px] items-center top-[30px]`}
          >
            <PlusIcon className="text-black w-6 h-6" />
          </div>
        }
        content={({ setIsShow }) => (
          <CreateFolderForm onClose={() => setIsShow(false)} />
        )}
      />
    )}
  </div>
);
  
export default FolderIcon;