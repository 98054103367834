import React from "react";
import img1 from "../../assets/images/post-session-image-1.png";
import img2 from "../../assets/images/post-session-image-2.png";
import img3 from "../../assets/images/post-session-image-3.png";
import img4 from "../../assets/images/post-session-image-4.png";
import img5 from "../../assets/images/post-session-image-5.png";

const SessionImages = () => {
  return (
    <div className="bg-[#FDFBF6] flex flex-col items-center w-screen">
      <div className="flex items-end justify-center px-[109px] py-[40px] gap-[120px] w-[1366px]">
        <img className="h-[624px] w-[658px] object-cover" src={img1} />
        <img className="h-[400px] w-[370px] object-cover" src={img2} />
      </div>
      <div className="flex items-center px-[109px] py-[40px] gap-[90px] w-[1366px] flex-col">
        <img className="h-[673px] w-[1148px] object-cover" src={img3} />
      </div>
      <div className="flex items-end justify-center px-[109px] py-[40px] gap-[120px] w-[1366px]">
        <img className="w-[370px] h-[400px] object-cover" src={img4} />
        <img className="h-[624px] w-[658px] object-cover" src={img5} />
      </div>
    </div>
  );
};

export default SessionImages;
