import React, { useEffect, useRef, useState } from "react";
import {
  ACTION_NAME,
  GALLERY_TYPE,
  WEBSOCKET_CHANNEL,
} from "../../../constants/options";
import { getS3FEMediaUrl } from "../../../helper/media";
import Fancybox from "../../home-gallery/fancy-box";
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import {
  reqSetActiveGallery,
  reqSetIsShowVirtualModal,
} from "../../../reduxs/gallery/action";
import { useDispatch } from "react-redux";
import socket from "../../../helper/socket";
import {
  IconCloseButton,
  LeftArrowIcon,
  RightArrowIcon,
} from "../../svgs/icons";
import { classNames } from "../../../helper/utils";

const Renders = ({ data, isInfinity, isPresentation }) => {
  const [isShowFancyBox, setIsShowFancyBox] = useState(null);
  const dispatch = useDispatch();
  const pageRef = useRef(null);

  const onShowVirtualModal = (galleryId) => {
    dispatch(reqSetIsShowVirtualModal(true));
    dispatch(reqSetActiveGallery(galleryId));
  };

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action == ACTION_NAME.SHOW_RENDER_GALLERY) {
          return onShowFancyBox(content.data.item);
        }

        if (content.action == ACTION_NAME.CLOSE_RENDER_GALLERY) {
          return onCloseFancyBox();
        }

        if (content.action == ACTION_NAME.JUMP_TO_RENDER_IMAGE) {
          return onJumpToImage(content.data.index);
        }

        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          pageRef.current &&
            (pageRef.current.scrollTop =
              content.data.scrollTop * pageRef.current.scrollHeight);
        }
      });
    }
  }, [isPresentation]);

  const onNextImage = () => {
    NativeFancybox.getInstance().next();
  };

  const onPrevImage = () => {
    NativeFancybox.getInstance().prev();
  };

  const onShowFancyBox = (index) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_RENDER_GALLERY, {
        index,
      });
    }
    const el = document.getElementById(`im-${index}`);
    if (el) {
      el.click();
      setIsShowFancyBox(`im-${index}`);
    }
  };

  const onCloseFancyBox = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_RENDER_GALLERY);
    }
    NativeFancybox.close();
  };

  const onJumpToImage = (index) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.JUMP_TO_RENDER_IMAGE, {
        index,
      });
    }
    NativeFancybox.getInstance().jumpTo(index);
  };

  const mediaData = data?.media || [];

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop:
          event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  };

  return (
    <div ref={pageRef} className="overflow-y-auto h-full pt-[100px] pb-[260px]" onScroll={onScroll}>
      <div className="flex gap-[15px]">
        {[1, 2, 3].map((col) => {
          const startIndex =
            col <= 1 ? 0 : Math.ceil((mediaData?.length * (col - 1)) / 3);
          const endIndex = Math.ceil((mediaData?.length * col) / 3);
          return (
            <div key={String(col)} className="rbasis-[33.33%] w-[33.33%] flex flex-col gap-[15px]">
              {mediaData?.slice(startIndex, endIndex)?.map((item, index) => {
                if (item.type === GALLERY_TYPE.VIDEO) return null;
                return (
                  <div
                    key={`renders-${index}`}
                    className={"renders-content-item"}
                  >
                    {item.type === GALLERY_TYPE.VIRTUAL_TOUR ? (
                      <div
                        className={`bg-white w-full h-auto min-h-[200px] relative cursor-pointer ${
                          isInfinity ? "infinity" : ""
                        }`}
                        onClick={() => onShowVirtualModal(item.id)}
                      >
                        <img
                          className="h-full absolute w-full inset-y-0"
                          src={getS3FEMediaUrl(item.path)}
                          alt="virtual-img"
                        />
                        <div className="absolute text-xs not-italic font-bold leading-[116%] tracking-[0.3px] uppercase text-white left-[15px] bottom-[15px]">{item.name}</div>
                      </div>
                    ) : (
                        <Fancybox
                        key={`Fancybox-${index}`}
                        options={{
                          infinite: true,
                          mainClass: "pure-fancy-box",
                          showNavArrows: false,
                          on: {
                            "Carousel.change": (fancybox) => {
                              const el = fancybox.getSlide();
                              if (!isPresentation) {
                                socket.emitUIActionEvent(ACTION_NAME.JUMP_TO_FLOORPLAN, {
                                  index: el.index,
                                });
                              }
                              setIsShowFancyBox(`im-${el.index}`);
                            },
                            destroy: () => {
                              if (!isPresentation) {
                                socket.emitUIActionEvent(
                                  ACTION_NAME.CLOSE_RENDER_GALLERY
                                );
                              }
                              setIsShowFancyBox(null);
                            },
                          },
                        }}
                      >
                        <div
                          className={`gallery-item card-renders relative ${
                            isInfinity ? "infinity" : ""
                          }`}
                        >
                          <img
                            id={`im-${index}`}
                            onClick={() => onShowFancyBox(index)}
                            src={getS3FEMediaUrl(item.path)}
                            alt="normal-img"
                            data-fancybox="gallery"
                          />
                          <div className="absolute text-xs not-italic font-bold leading-[116%] tracking-[0.3px] uppercase text-white left-[15px] bottom-[15px]">{item.name}</div>
                        </div>
                      </Fancybox>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      {!!isShowFancyBox && (
        <div className="wrap-btn-fancy">
          <div className="btn-prev cursor-pointer" onClick={onPrevImage}>
            <LeftArrowIcon style={{ width: 21, height: 35 }} fill={"#C7A446"} />
          </div>
          <div className="wrap-slide-fancy">
            {mediaData?.map((item, index) => (
              <div
                key={`slide-${index}`}
                className={classNames(
                  "fancy-dot",
                  `im-${index}` === isShowFancyBox ? "" : "fancy-dot-o"
                )}
              />
            ))}
          </div>
          <div className="btn-next cursor-pointer" onClick={onNextImage}>
            <RightArrowIcon
              style={{ width: 21, height: 35 }}
              fill={"#C7A446"}
            />
          </div>
        </div>
      )}
      {!!isShowFancyBox && (
        <div className="wrap-fancy-close">
          <div className="cursor-pointer" onClick={onCloseFancyBox}>
            <IconCloseButton style={{ width: 26 }} fill={"#C7A446"} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Renders;
