import React, { useRef } from "react";
import { cn } from "../../../../helper/utils";
import Loading from "./loading";

const Switch = ({ onChange, ...rest }) => {
  const [loading, setLoading] = React.useState(false);
  const inputRef = useRef(null);

  const handleOnChange = (e) => {
    setLoading(true);
    onChange(e);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
  const handleLabelClick = (e) => {
    e.preventDefault();
    if (loading || !inputRef.current) return;

    // Kích hoạt click event trên input
    inputRef.current.click();
  };

  return (
    <div className=" flex flex-wrap items-center">
      <div className="relative flex cursor-pointer flex-wrap rounded-xl items-center gap-2 text-slate-500 bg-blue-200">
        <input
          ref={inputRef}
          className="peer bg-neutral-400 z-60 border-none checked:bg-none relative h-5 w-11 cursor-pointer appearance-none rounded-xl z-50 transition-colors after:absolute after:left-0 after:top-0 after:h-5 after:w-5 after:rounded-full after:bg-neutral-500 after:transition-all checked:bg-blue-200 checked:after:left-6 checked:after:bg-blue-500  after:hover:bg-neutral-500 checked:hover:bg-blue-300 checked:after:hover:bg-blue-500  focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-200 disabled:after:bg-blue-500"
          type="checkbox"
          onChange={handleOnChange}
          name={rest?.name}
          checked={rest?.checked}
          id={rest?.id}
          disabled={loading}
          defaultChecked={rest?.defaultChecked}
        />
        <label
          onClick={handleLabelClick}
          htmlFor={rest?.id}
          className={cn(
            "absolute z-50 cursor-pointer text-[14px] left-6 top-0  flex items-center justify-center text-slate-500",
            {
              "left-1 text-white": rest?.defaultChecked,
            }
          )}
        >
          {rest?.defaultChecked ? "on" : "off"}
        </label>
        {loading && (
          <Loading
            animation="border"
            width="w-4"
            height="h-4"
            className={cn(
              "w-4 h-4 text-gray-300 absolute left-[18px] z-[1000]",
              {
                "left-[-6px]": !rest?.defaultChecked,
              }
            )}
          />
        )}
      </div>
    </div>
  );
};

export default Switch;
