import React, { useState } from "react";
import SearchIcon from "../Search";
import _ from "lodash";
import { toast } from "react-toastify";
import icImg from "../../../../assets/images/cms/icImg.png";
import icEdit from "../../../../assets/images/cms/edit.png";
import UnitModal from "../../media/unit-modal";
import { useDispatch, useSelector } from "react-redux";
import LoadingTable from "../../configuration/components/Table/LoadingTable";
import EmptyTable from "../../configuration/components/Table/EmptyTable";
import UnitEditModal, { availabilityOptions } from "./edit-modal";
import TrashIcon from "../../configuration/components/svgs/TrashIcon";
import { Button, Modal } from "../../components/commons";
import unitApi from "../../../../apis/api/unit";
import { reqGetListUnits } from "../../../../reduxs/cms/action";

const UnitData = ({ onSearch, setSelectedData }) => {
  const { units, loadingUnits } = useSelector((state) => state.cms);
  const dispatch = useDispatch();

  const [isShowUnitModal, setIsShowUnitModal] = useState(false);
  const [isShowEditUnitModal, setIsShowEditUnitModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [unitId, setUnitId] = useState("");
  const [unitEditId, setUnitEditId] = useState("");
  const [loadingDelete, setLoadingDelete] = useState(false);

  const openUnitModal = (unitId, modalType) => {
    setIsShowUnitModal(true);
    setModalType(modalType);
    setUnitId(unitId);
  }

  const openEditUnitModal = (unitId) => {
    setIsShowEditUnitModal(true);
    setUnitEditId(unitId);
  }

  const handleDelete = async ({ id, onSuccess, onError }) => {
    setLoadingDelete(true);
    try {
      const res = await unitApi.deleteUnit(id);
      if (res) {
        toast.success("Delete unit successfully");
        onSuccess && onSuccess();
      }
    } catch (error) {
      onError && onError();
      toast.error("Delete unit failed");
    } finally {
      setLoadingDelete(false);
    }
  };

  return (
    <div className="bg-[rgb(244,244,244)] h-screen p-4">
      <div className="bg-white p-4 rounded-md">
        <SearchIcon
          callbackOnInputChange={onSearch}
          wrapperClassName="flex justify-end mb-2"
        />
        <div className=" h-[calc(100vh-260px)]  overflow-y-scroll">
          <table
            className="w-full border-collapse table-fixed"
            id="unit-data-table"
          >
            <thead>
              <tr className="text-sm font-semibold">
                <th
                  className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                >
                  Unit Number
                </th>
                <th
                  className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                >
                  Availability
                </th>
                <th
                  className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                >
                  Level
                </th>
                <th
                  className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                >
                  Aspect
                </th>
                <th
                  className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                >
                  Unit Type
                </th>
                <th
                  className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                >
                  Gallery
                </th>
                <th
                  className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                >
                  Floorplan
                </th>
                <th
                  className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                >
                  Viewline
                </th>
                <th
                  className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                >
                  360
                </th>
                <th
                  className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                >
                  Edit
                </th>
                <th
                  className={`${thCss} font-bold sticky z-[1] top-0 bg-gray-100`}
                >
                  Delete
                </th>
              </tr>
            </thead>
            {loadingUnits && (
              <tbody className="h-[500px]">
                <tr>
                  <td colSpan="10" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <LoadingTable />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
            {!loadingUnits && !units?.length && (
              <tbody className="h-[500px]">
                <tr>
                  <td
                    colSpan="10"
                    className="text-center py-4 border border-t-0 border-gray-300"
                  >
                    <div className="flex justify-center items-center">
                      <EmptyTable />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
            {!loadingUnits && (
              <tbody>
                {(units || [])?.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className="text-sm transition-all hover:bg-gray-100 cursor-grab"
                    >
                      <td className={thCss}>
                        {_.get(item, "name")}
                      </td>
                      <td className={`${thCss} capitalize`}>
                        {availabilityOptions.find(it => it.value === item?.availabilityStatus).name}
                      </td>
                      <td className={thCss}>{item?.level}</td>
                      <td className={thCss}>-</td>
                      <td className={thCss}>{item?.view?.type}</td>
                      <td className={thCss}>
                        <img
                          onClick={() => openUnitModal(item.id, 'gallery')}
                          src={icImg}
                          alt="edit"
                          className="w-20 m-auto"
                        />
                      </td>
                      <td className={thCss}>
                        <img
                          onClick={() => openUnitModal(item.id, 'floorplan')}
                          src={icImg}
                          alt="edit"
                          className="w-20 m-auto"
                        />
                      </td>
                      <td className={thCss}>
                        <img
                          onClick={() => openUnitModal(item.id, 'viewline')}
                          src={icImg}
                          alt="edit"
                          className="w-20 m-auto"
                        />
                      </td>
                      <td className={thCss}>
                        <img
                          onClick={() => openUnitModal(item.id, '360')}
                          src={icImg}
                          alt="edit"
                          className="w-20 m-auto"
                        />
                      </td>
                      <td className={thCss}>
                        <img
                          src={icEdit}
                          alt="edit"
                          className="w-24 m-auto"
                          onClick={() => openEditUnitModal(item.id)}
                        />
                      </td>
                      <td className={thCss}>
                        <Modal
                          maskClassName="bg-opacity-50"
                          zIndex="z-[9998]"
                          maskClosable
                          trigger={
                            <span className="text-red-500 cursor-pointer flex justify-center">
                              <TrashIcon width={30} height={30}/>
                            </span>
                          }
                          content={({ setIsShow }) => (
                            <div className="bg-white  w-full max-w-md">
                              <h2 className="text-md text-black font-semibold text-center">
                                Are you sure you want to delete this unit?
                              </h2>
                              <div className="flex justify-center mt-6 space-x-4">
                                <Button
                                  disabled={loadingDelete}
                                  className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300 text-black"
                                  onClick={() => setIsShow(false)}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  disabled={loadingDelete}
                                  isLoading={loadingDelete}
                                  className="bg-yellow-200 text-black px-4 py-2 rounded hover:bg-yellow-300"
                                  onClick={() =>
                                    handleDelete({
                                      onSuccess: () => {
                                        setIsShow(false);
                                        dispatch(reqGetListUnits());
                                      },
                                      onError: () => setIsShow(false),
                                      id: item.id
                                    })
                                  }
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          )}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
        {isShowUnitModal && <UnitModal unitId={unitId} modalType={modalType} show={isShowUnitModal} setShow={setIsShowUnitModal} />}
        {isShowEditUnitModal && <UnitEditModal show={isShowEditUnitModal} unitId={unitEditId} setShow={setIsShowEditUnitModal} />}
      </div>
    </div>
  );
};

const thCss =
  "text-left border whitespace-nowrap  overflow-hidden text-ellipsis p-2 border-solid border-[#ddd] cursor-pointer";

export default UnitData;
