import React from "react";
import { getS3FEMediaUrl } from "../../helper/media";

const VideoIntro = ({ onSkipReplayVideo, isPresentation }) => {
  const onSkipVideo = () => {
    onSkipReplayVideo();
  };

  return (
    <div className="absolute h-full w-full z-[99] bg-[black] left-0 top-0">
      {/* <video
        ref={videoRef}
        autoPlay={true}
        onEnded={onSkipVideo}
        preload="auto"
        id="intro-video-2"
        muted={isPresentation}
      >
        <source src="/uploads/videos/2269289e.mp4" type="video/mp4" />
      </video> */}
      <img className="w-full h-full object-cover" src={getS3FEMediaUrl("/intro.png")} alt="intro" />
      <img className="absolute top-[calc(50%_-_80px)] -translate-x-2/4 -translate-y-2/4 left-2/4" src={getS3FEMediaUrl("/play.svg")} alt="play" />
      <div onClick={onSkipVideo} className="-translate-x-2/4 absolute flex gap-2.5 bg-transparent border-[none] left-2/4 bottom-[106px] hover:bg-[unset] btn btn-light">
        <span className="text-white text-sm not-italic font-normal leading-[30px] tracking-[1.4px] uppercase">SKIP FILM</span>
        <img src={getS3FEMediaUrl("/arrow-right.svg")} alt="arrow" />
      </div>
    </div>
  );
};

export default VideoIntro;
