import React, { useEffect, useRef } from "react";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../constants/options";
import { getS3FEMediaUrl } from "../../../helper/media";
import {
  reqSetActiveGallery,
  reqSetIsShowVirtualModal,
} from "../../../reduxs/gallery/action";
import { useDispatch } from "react-redux";
import socket from "../../../helper/socket";

const PanaromaGallery = ({ data, isInfinity, isPresentation }) => {
  const dispatch = useDispatch();
  const pageRef = useRef(null);

  const onShowVirtualModal = (galleryId) => {
    dispatch(reqSetIsShowVirtualModal(true));
    dispatch(reqSetActiveGallery(galleryId));
  };

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          pageRef.current &&
            (pageRef.current.scrollTop =
              content.data.scrollTop * pageRef.current.scrollHeight);
        }
      });
    }
  }, [isPresentation]);

  const mediaData = data?.media || [];

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop:
          event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  };

  return (
    <div ref={pageRef} className="overflow-y-auto h-full pt-[100px] pb-[260px]" onScroll={onScroll}>
      <div className="flex gap-[15px]">
        {[1, 2, 3].map((col) => {
          const startIndex =
            col <= 1 ? 0 : Math.ceil((mediaData?.length * (col - 1)) / 3);
          const endIndex = Math.ceil((mediaData?.length * col) / 3);
          return (
            <div key={String(col)} className="basis-[33.33%] w-[33.33%] flex flex-col gap-[15px]">
              {mediaData?.slice(startIndex, endIndex)?.map((item, index) => {
                return (
                  <div
                    key={`renders-${index}`}
                    className={"renders-content-item"}
                  >
                    <div
                      className={`bg-white w-full h-auto min-h-[200px] relative cursor-pointer ${isInfinity ? "infinity" : ""}`}
                      onClick={() => onShowVirtualModal(item.id)}
                    >
                      <img className="h-full absolute w-full inset-y-0" src={getS3FEMediaUrl(item.path)} alt="virtual-img" />
                      <div className="absolute text-xs not-italic font-bold leading-[116%] tracking-[0.3px] uppercase text-white left-[15px] bottom-[15px]">{item.name}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PanaromaGallery;
