import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { toast } from "react-toastify";

import SelectField from "../../../components/selectField";
import { Button } from "../../../../components/commons";
import {
  refetchListUnits,
  updateUnit,
} from "../../../../../../reduxs/page-configuration/action";

const EditLevelUnit = ({ field, setIsShow }) => {
  const dispatch = useDispatch();
  const { units: unitsRes } = useSelector((state) => state.configuration);

  const options = _.sortBy(
    _.map(_.uniqBy(unitsRes, "level"), (item) => ({
      id: item.level,
      name: String(item.level),
    })),
    "id"
  );

  const validationSchema = yup.object().shape({
    level: yup.object().required("Level is a required field"),
  });

  const { control, formState, reset, handleSubmit } = useForm({
    defaultValues: {
      level: {
        id: field.level,
        name: String(field.level),
      },
    },
    resolver: yupResolver(validationSchema),
  });

  const updateComponent = async (formValue) => {
    await dispatch(
      updateUnit(
        {
          level: formValue.level.id,
          id: field.id,
        },
        () => {
          dispatch(
            refetchListUnits({
              autogen: true,
            })
          );
          setIsShow(false);
          toast.success("Update unit level successfully");
        },
        () => toast.error("Update unit level failed")
      )
    );
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        formState?.isDirty && handleSubmit(updateComponent)();
      }}
    >
      <SelectField
        options={options || []}
        controller={{
          control,
          name: "level",
        }}
        label="Level"
      />
      <div className="flex gap-3 flex-1 mt-10">
        <Button
          type="button"
          disabled={formState?.isSubmitting}
          variant="text"
          className="flex-1"
          onClick={() => setIsShow(false)}
        >
          Cancel
        </Button>
        <Button
          type={formState?.isDirty ? "submit" : "button"}
          disabled={formState?.isSubmitting || !formState?.isDirty}
          onClick={handleSubmit(updateComponent)}
          isLoading={formState?.isSubmitting}
          className="flex-1"
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export default EditLevelUnit;
