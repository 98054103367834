import React from "react";

const FAQTabs = ({ tabs = [], activeTab, setActiveTab }) => {
  const onTabClick = (type) => {
    setActiveTab(type)
  }

  return (
    <div className="bg-black">
      <div className="text-center bg-white border-b-[1px] border-b-[#24242226]">
        <div className="row no-gutters">
          {tabs.map((tab, idx) => (
            <div key={tab.type} className="col !px-0">
              <div
                onClick={() => onTabClick(tab.type)}
                className={`h-[51px] basis-full flex items-center justify-center text-[14px] font-normal ${idx === 1 ? 'border-x-[1px] border-x-[#24242226]' : ''}`}
              >
                {tab.title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default FAQTabs;
