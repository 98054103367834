import * as yup from "yup";
import Select from "react-select";
import { toast } from "react-toastify";
import supportApi from "../../../../apis/api/support";
import React, { useEffect, useState } from "react";
import closeIcon from "../../../../assets/images/close-white.svg";
import { SUPPORT_QUESTION_TYPES } from "../../../../constants/master-data";
import { Modal } from "../../components/commons";

const validationSchema = yup.object().shape({
  questionType: yup
    .string()
    .trim()
    .required("Question type is a required field"),
  subject: yup.string().trim().required("Subject is a required field"),
  message: yup.string().trim().required("Message cannot be empty"),
});

const ErrorMessageText = ({ msg = "", className = "", style = {} }) => (
  <span
    className={`${className} font-nova text-[#AE6537] text-sm font-normal leading-[150%] mt-[4px]`}
    style={{ ...style }}
  >
    {msg}
  </span>
);

const RequestSupportModal = (props) => {
  const { show, setShow, user, onSubmitSuccess = () => {} } = props;
  const [questionType, setQuestionType] = useState();
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setQuestionType("");
  }, []);

  const handleSubmit = async () => {
    try {
      const data = {
        questionType,
        subject,
        message,
      };
      setErrors({});
      const result = await validationSchema.validate(data, {
        abortEarly: false,
      });

      const payload = {
        request: {
          requester: {
            name: user.name,
          },
          subject: result.subject,
          comment: {
            body: `${result.questionType}\n${result.message}`,
          },
        },
      };

      const res = await supportApi.sendSupportTicket(payload);
      if (res?.request?.id) {
        toast.success("Request submitted successfully!");
        handleClose && handleClose();
        onSubmitSuccess && onSubmitSuccess();
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      } else {
        toast.error("Failed to send request. Please try again!");
      }
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal
      iconCancelMark={() => (
        <div className="z-[9999] cursor-pointer fixed top-[50px] right-[80px]">
          <img src={closeIcon} alt="close-icon" />
        </div>
      )}
      wrapperClassName="wrap-request-support-modal"
      panelClassName="w-[609px] p-0 rounded-none"
      zIndex="z-[9998]"
      maskClassName="bg-opacity-50"
      show={show}
      setShow={handleClose}
      content={
        <div className="p-[40px]">
          <h2 className="text-black text-[30px] font-[700] uppercase font-poppins">
            REQUEST SUPPORT
          </h2>
          <div className="pt-[40px] flex flex-col gap-[20px] overflow-y-hidden">
            <div className="flex-1 pb-[20px]">
              <label className="self-start text-black text-center font-poppins text-sm font-bold leading-none uppercase mb-[5px]">
                Question Type*
              </label>
              <Select
                placeholder="Question Type"
                name="questionType"
                value={
                  questionType
                    ? {
                        value: questionType,
                        label: `About ${questionType} ?`,
                      }
                    : null
                }
                onChange={(e) => setQuestionType(e.value)}
                options={SUPPORT_QUESTION_TYPES}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  control: (base) => ({
                    ...base,
                    borderBottom: "1px solid black",
                    borderRadius: 0,
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                    padding: 0,
                    boxShadow: 'none',
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    paddingLeft: 0
                  }),
                  input: (base) => ({
                    ...base,
                    '& > input:focus': { boxShadow: 'none' }
                  }),
                  indicatorsContainer: (base) => ({ ...base, display: "none" }),
                }}
              />
              <ErrorMessageText msg={errors?.questionType} />
            </div>
            <div className="flex-1 pb-[20px]">
              <label className="mb-[15px] self-start text-black inline-block font-poppins text-sm font-bold leading-none uppercase">
                Subject*
              </label>
              <input
                id="surname"
                name="surname"
                className="border-b-[1px] border-solid pb-[10px] border-black border-t-0 border-l-0 border-r-0 text-black mt-[15px] w-full font-poppins text-sm font-normal leading-[10px] border-b border-[#1A1A1A] rounded-none shadow-none p-0 m-0 h-[30px] [&>*]:bg-white [&>*]:w-[100px]"
                value={subject}
                placeholder="Subject"
                onChange={(e) => setSubject(e.target.value)}
              />
              <ErrorMessageText msg={errors?.subject} />
            </div>
            <div className="flex-1 pb-[20px]">
              <label className="self-start mb-0 text-black text-center font-poppins text-sm font-bold leading-none uppercase">
                Message*
              </label>
              <input
                id="address"
                name="address"
                className="border-b-[1px] border-solid pb-[10px] border-black border-t-0 border-l-0 border-r-0 text-black mt-[15px] w-full font-poppins text-sm font-normal leading-[10px] border-b border-[#1A1A1A] rounded-none shadow-none p-0 m-0 h-[30px] [&>*]:bg-white [&>*]:w-[100px]"
                value={message}
                placeholder="What do you need help with?"
                onChange={(e) => setMessage(e.target.value)}
              />
              <ErrorMessageText msg={errors?.message} />
            </div>
          </div>
          <div
            onClick={handleSubmit}
            className="flex justify-center items-center flex-1 h-[37px] px-[18px] py-[12px] mt-[40px] gap-[10px] border border-black font-poppins text-[14px] font-bold leading-[10px] hover:text-white hover:bg-black hover:cursor-pointer"
          >
            SUBMIT
          </div>
        </div>
      }
    />
  );
};

export default RequestSupportModal;
