import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { reqGetModuleDetail } from "../../../reduxs/page-configuration/action";
import CmsPageHeader from "../components/PageHeader";
import { Button } from "../components/commons";
import { Modal } from "../components/commons";
import AddSection from "./add-section";
import PlusIcon from "../configuration/components/svgs/PlusIcon";
import NoImage from "./Form/no-image";
import LeftImage from "./Form/left-image";
import RightImage from "./Form/right-image";
import MultipleRightImage from "./Form/multiple-right-image";
import useFormStatic, { typeSection } from "./useForm";
import MultipleLeftImage from "./Form/multiple-left-image";

const DynamicStaticPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const valueRes = useSelector((state) => state.configuration.module);

  const { form, addSection, fieldArrayForm, loadingSave, onSave } =
    useFormStatic({
      defaultValues: valueRes,
    });

  const { fields, move, remove } = fieldArrayForm;
  const { control, handleSubmit, formState } = form;

  useEffect(() => {
    if (id) {
      dispatch(
        reqGetModuleDetail({
          idModule: id,
        })
      );
    }
  }, [id]);

  return (
    <div className="page-container">
      <CmsPageHeader title={valueRes?.name} />
      <div className="overflow-y-scroll h-[calc(100vh-200px)] disable-scroll-bar">
        <div className="my-5 max-w-[800px] mx-auto">
          <div className="flex justify-between items-center">
            <h1 className="font-semibold text-xl">{valueRes?.name}</h1>
            <Button
              isLoading={loadingSave}
              disabled={!formState.isDirty}
              onClick={handleSubmit(onSave)}
              className="px-4"
            >
              Save
            </Button>
          </div>
          {fields.map((field, index) => {
            const commonProps = {
              index,
              disableUp: index === 1,
              disableDown: index === fields.length - 1,
              move,
              remove,
              control,
            };

            return (
              <div key={field.id || `section-${index}`}>
                {field.type === typeSection.no_image && (
                  <NoImage {...commonProps} />
                )}
                {field.type === typeSection.left_image && (
                  <LeftImage {...commonProps} />
                )}
                {field.type === typeSection.right_image && (
                  <RightImage {...commonProps} />
                )}
                {field.type === typeSection.multiple_left_image && (
                  <MultipleLeftImage {...commonProps} />
                )}
                {field.type === typeSection.multiple_right_image && (
                  <MultipleRightImage {...commonProps} />
                )}
              </div>
            );
          })}
          {formState?.errors?.sections?.root?.message && !fields.length && (
            <p className="text-center text-red-500">
              {formState.errors.sections.root.message}
            </p>
          )}
          <div className="text-center mt-5">
            <Modal
              zIndex="z-[9998]"
              maskClassName="bg-opacity-50"
              disabled={loadingSave}
              content={({ setIsShow }) => (
                <AddSection addSection={addSection} setIsShow={setIsShow} />
              )}
              triggerClassName="inline-block"
              trigger={
                <Button disabled={loadingSave} icon={<PlusIcon />}>
                  Add Section
                </Button>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicStaticPage;
