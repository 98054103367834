/** @format */
export const MESH_PREFIXES = {
  UNIT: {
    NAME_PREFIX: 'unit',
    CAM_POS_PREFIX: 'unit_cam_pos',
    CAM_TARGET_PREFIX: 'unit_cam_target',
  },
  AMENITY: {
    NAME_PREFIX: 'amenity',
    CAM_POS_PREFIX: 'amenity_cam_pos',
    CAM_TARGET_PREFIX: 'amenity_cam_target',
    HOTSPOT_HELPER_POSITION: 'amenity_hotspot_position',
    HOTSPOT_HELPER_LINE_POSITION: 'amenity_hotspot_line_position',
  },
  TRANSPORT_OPTION: {
    NAME_PREFIX: 'transport_option',
    CAM_POS_PREFIX: 'transport_option_cam_pos',
    CAM_TARGET_PREFIX: 'transport_option_cam_target',
  }
};
export const UNIT_ENUM = {
  SQUARE: 'square',
  SQUARE_METER: 'squareMeter',
  ACRE: 'acre',
  HECTARE: 'hectare',
};
export const PROPERTY_TYPE_ENUM = {
  HOUSE: 'house',
  UNIT: 'unit',
  APARTMENT: 'apartment',
  OFFICE: 'office',
  VILLA: 'villa',
  LAND: 'land',
  TOWNHOUSE: 'townhouse',
};
export const AVAILABILITY_STATUS_ENUM = {
  AVAILABLE: 'Available',
  AVAILABLE_NOW: 'Available now',
  RELEASING_SOON: 'Releasing soon',
  SOLD: 'Sold',
  RESERVED: 'Reserved',
  MUTILPLE_OFFERS: 'Multiple offers',
};
export const MEDIA_TYPE_ENUM = {
  INTERIOR_ID: 'interior_id',
  FLOORPLAN: 'floorplan',
  IMAGE: 'media_images',
  VIDEO: 'video',
  '360_PANO_TOUR': '360_pano_tour',
};
export const USER_GROUP = {
  INVESTOR: 'investor',
  TENANT: 'tenant',
  ADMIN: 'admin',
  CONSULTANT: 'consultant',
};

export const USER_GROUP_ID = {
  INVESTOR: '606d6f356af09f50f367ee23',
  TENANT: '610a4a3351400773f94bfa89',
};

export const FLOOR_TYPE = {
  BAKU_FLOOR: 'baku_floorplan',
};

export const LEVEL_TYPE = {
  BHT_LEVEL: 'bht_level',
};

export const FILM_TYPE = {
  BAKU_FILM: 'baku_film',
};

export const GALLERY_IMAGE_TYPE = {
  BAKU_GALLERY_IMAGE: 'baku_image',
  ORLA_GALLERY_IMAGE: 'oral_image',
};

export default {
  UNIT_ENUM,
  PROPERTY_TYPE_ENUM,
  AVAILABILITY_STATUS_ENUM,
  MEDIA_TYPE_ENUM,
  USER_GROUP,
};

export const SUPPORT_QUESTION_TYPES = [
  {
    value: 'Hardware & TV Screen',
    label: 'Hardware & TV Screen',
  },
  {
    value: 'Physical Model',
    label: 'Physical Model',
  },
  {
    value: 'Apple Mac & iPad',
    label: 'Apple Mac & iPad',
  },
  {
    value: 'Internet Connectivity',
    label: 'Internet Connectivity',
  },
  {
    value: 'App 3D Content, Floorplans & Images',
    label: 'App 3D Content, Floorplans & Images',
  },
  {
    value: 'Login & Passwords',
    label: 'Login & Passwords',
  },
  {
    value: 'New feature Request',
    label: 'New feature Request',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];
