import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import authApi from "../../apis/api/auth";
import ROUTE_PATH from "../../routers/path";

const FormInput = ({
  type = 'text',
  onChange =  () => {},
  className =  '',
  placeholder =  'Input something...',
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (email || password) {
      setErrorMessage("")
    }
  }, [email, password]);

  const handleForgotPassword = () => {
    navigate(ROUTE_PATH.FORGOT_PASSWORD);
  };

  const handleLogin = async () => {
    const data = {
      email: email,
      password: password,
    };

    const res = await authApi.signIn(data);

    if (res?.data) {
      jwtAuth.setAccessToken(res.data.token);
      navigate("/");
    } else {
      setErrorMessage("Email not found or password does not match");
    }
  };

  return (
    <input
      type={type}
      onChange={onChange}
      className={`!px-5 !py-3 w-full !font-sans text-xs font-normal leading-normal tracking-[1.2px] outline-none border-none h-16 bg-[#F4F4F4] text-[#242422] focus:shadow-none placeholder:text-[#242422] placeholder:uppercase ${className}`}
      placeholder={placeholder}
    />
  );
};

export default FormInput;
