import React from "react";
import { IconCloseButton } from "../svgs/icons";
import { classNames } from "../../helper/utils";

export const AwModal = (
  {
    show, onClose, children, cn = '', isShowBtnClose = true, isOutSideClose = true,
    currentColor = "white",
  }
) => {
  if (!show) return null
  return (
    <div
      className={classNames('fixed bg-black z-[20000] inset-0', cn)}
      onClick={isOutSideClose ? onClose : () => null}
    >
      <div className=" w-full h-full">
        {isShowBtnClose && (
          <div className="absolute z-[9999] right-20 top-[50px]" onClick={onClose}>
            <IconCloseButton style={{width: 26}} fill={currentColor}/>
          </div>
        )}
        <div className="h-full overflow-y-auto">
          {children}
        </div>
      </div>
    </div>
  );
};
