/** @format */

import * as type from "./type";

const initialState = {
  galleries: [],
  gallery: null,
  galleryLoading: false,
  isShowVirtualModal: false,
  activeGallery: false,
  contentStaticType1: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.SET_ACTIVE_GALLERY:
      return {
        ...state,
        activeGallery: action?.data?.data,
      };
    case type.SET_IS_SHOW_VIRTUAL_MODAL:
      return {
        ...state,
        isShowVirtualModal: action?.data,
      };
    case type.GET_GALLERY_LIST:
      return {
        ...state,
        galleries: action?.data?.data,
      };
    case type.SET_DATA_STATIC_TYPE_1:
      return {
        ...state,
        contentStaticType1: action?.data,
      };
    case type.SET_DATA_STATIC_TYPE_2:
      return {
        ...state,
        contentStaticType2: action?.data,
      };
    case type.GET_GALLERY:
      return {
        ...state,
        gallery: action?.data?.data,
      };
    case type.GET_GALLERY_LOADING:
      return {
        ...state,
        galleryLoading: action?.data,
      };
    default:
      return state;
  }
};
