import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import jwtAuth from "../../apis/utils/jwtAuth";
import icHome from "../../assets/images/logo-bay-harbor-white.svg";
import { cn } from "../../helper/utils";

const TopNavCMS = (props) => {
  const location = useLocation();
  const staticPage = props?.staticPage?.modules;
  const visiblePage = props?.staticPage?.props?.visible;

  const navigate = useNavigate();
  const getActiveClass = (path) => {
    return path === location?.pathname ? "opacity-100 " : "";
  };

  const logout = () => {
    jwtAuth.removeToken();
    navigate("/holding");
  };

  return (
    <div className="sticky top-0 bg-[#000] text-[#fff] h-[80px] p-[13px_57px_12px_58px]">
      <div className="flex justify-between items-center">
        <a
          className="w-[65px] h-[61px] perspective-[1000px] transition-[top] duration-200 relative cursor-grab active:transition-none"
          href="/"
        >
          <img src={icHome} alt="icon-cube" />
        </a>
        <ul className="flex gap-[35px] flex-wrap">
          {!!staticPage?.length &&
            visiblePage &&
            staticPage?.map(
              (module) =>
                module?.props?.visible && (
                  <li
                    key={module?.id}
                    className={cn(
                      "list-none",
                      getActiveClass(
                        `/cms/${module?.props?.path}/${module?.id}`
                      )
                    )}
                  >
                    <Link
                      to={`/cms/${module?.props?.path}/${module?.id}`}
                      className="text-decoration-none"
                    >
                      <div className="text-white font-poppins text-sm font-bold uppercase">
                        {module?.name}
                      </div>
                    </Link>
                  </li>
                )
            )}
          <li className={cn("list-none", getActiveClass("/cms/residence"))}>
            <Link to="/cms/units" className="text-decoration-none">
              <div className="text-white font-poppins text-sm font-bold uppercase">
                Units
              </div>
            </Link>
          </li>
          <li className={cn("list-none", getActiveClass("/cms/customers"))}>
            <Link to="/cms/customers" className="text-decoration-none">
              <div className="text-white font-poppins text-sm font-bold uppercase">
                CUSTOMERS
              </div>
            </Link>
          </li>
          <li className={cn("list-none", getActiveClass("/cms/content"))}>
            <Link to="/cms/content" className="text-decoration-none">
              <div className="text-white font-poppins text-sm font-bold uppercase">
                GALLERY
              </div>
            </Link>
          </li>
          <li className={cn("list-none", getActiveClass("/cms/media"))}>
            <Link to="/cms/assets" className="text-decoration-none">
              <div className="text-white font-poppins text-sm font-bold uppercase">
                Assets
              </div>
            </Link>
          </li>
          <li className={cn("list-none", getActiveClass("/cms/configuration"))}>
            <Link to="/cms/configuration" className="text-decoration-none">
              <div className="text-white font-poppins text-sm font-bold uppercase">
                CONFIGURATION
              </div>
            </Link>
          </li>
          <li className={cn("list-none", getActiveClass("/cms/faqs"))}>
            <Link to="/cms/faqs" className="text-decoration-none">
              <div className="text-white font-poppins text-sm font-bold uppercase">
                FAQS
              </div>
            </Link>
          </li>
          <li onClick={() => logout()}>
            <div className="text-white font-poppins text-sm font-bold uppercase">
              LOG OUT
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default TopNavCMS;
