import React, { useEffect } from 'react';
import socket from '../../../helper/socket';
import { useDispatch, useSelector } from 'react-redux';
import AccordionSidebar from '../../accordion-sidebar';
import { numberWithCommas } from '../../../helper/unit';
import { COMPONENTS_ENUM } from '../../../constants/modules';
import * as unitExploreAct from "../../../reduxs/unit-explore/action";
import { AVAILABILITY_STATUS_ENUM } from '../../../constants/master-data';
import { ACTION_NAME, WEBSOCKET_CHANNEL } from '../../../constants/options';
import { orderBy } from 'lodash';

const UnitDetailCollapsible = (props) => {
  const {
    components = [],
    isPresentation,
  } = props;

  const dispatch = useDispatch();

  const unit = useSelector((state) => state.unitExplore.selectedUnit);
  const activeKeys = useSelector((state) => state.unitExplore.activeDetailKeys);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CLICK_UNIT_DETAIL_INFO) {
      handleClickUnitDetail(content.data.currentActiveKeys, content.data.key);
    }
  };

  const handleClickUnitDetail = (currentActiveKeys, key) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_DETAIL_INFO, {
        currentActiveKeys,
        key
      })
    }

    if (currentActiveKeys.includes(key)) {
      dispatch(unitExploreAct.reqCloseUnitDetailInfo(key))
    } else {
      dispatch(unitExploreAct.reqShowUnitDetailInfo(key))
    }
  }

  const renderDetailComponents = () => {
    const componentsData = orderBy(components, c => c?.props?.order, ['asc'])
      ?.map((c, i) => {
        const cProps = c.props;
        
        if (!cProps?.visible) {
          return null;
        }

        // console.log(c.name, c.props.order)

        switch (c.name) {
          case COMPONENTS_ENUM.DETAIL_STATUS:
            return {
              title: cProps.label,
              value: <span>{unit?.availabilityStatus ? AVAILABILITY_STATUS_ENUM[String(unit.availabilityStatus).toUpperCase()] : ''}</span>
            };
          case COMPONENTS_ENUM.DETAIL_PRICE:
            return {
              title: cProps.label,
              value: <span>{unit?.price}</span>
            };
          case COMPONENTS_ENUM.DETAIL_SIZE:
            return {
              title: cProps.label,
              value: <span>{numberWithCommas(unit?.areaSqm)}</span>
            };
          case COMPONENTS_ENUM.DETAIL_SIZE_EXTERIOR:
            return {
              title: cProps.label,
              value: <span>{numberWithCommas(unit?.exterior)}</span>
            };
          case COMPONENTS_ENUM.DETAIL_SIZE_INTERIOR:
            return {
              title: cProps.label,
              value: <span>{numberWithCommas(unit?.interier)}</span>
            };
          case COMPONENTS_ENUM.DETAIL_BEDROOM:
            return {
              title: cProps.label,
              value: <span>{unit?.bedrooms}</span>
            };
          case COMPONENTS_ENUM.DETAIL_BATHROOM:
            return {
              title: cProps.label,
              value: <span>{unit?.bathrooms}</span>
            };
          case COMPONENTS_ENUM.DETAIL_POOL:
            return {
              title: cProps.label,
              value: <span>{unit?.hasPool ? 'Yes' : 'No'}</span>
            };
          default:
            return null;
        }
      })
      ?.filter(c => !!c) || [];

    return [
      {
        title: 'DETAILS',
        items: componentsData,
      },
    ]
  }

  return (
    <div className="details">
      <AccordionSidebar
        id="unit-detail-detail"
        onToggle={handleClickUnitDetail}
        activeKeys={activeKeys}
        data={renderDetailComponents()}
      />
    </div>
  )
};

export default UnitDetailCollapsible;
