import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reqSetPage } from "../../reduxs/home/action";
import { REACTUI_PAGES } from "../../constants/options";
import {
  reqGetCustomerProfile,
  reqSetCustomerProfile,
} from "../../reduxs/user/action";
import { useLocation, useNavigate } from "react-router-dom";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import socket from "../../helper/socket";
import SessionFooter from "./SessionFooter";
import { reqGetCustomerPreference } from "../../reduxs/precinct-explore/action";
import { useParams } from "react-router-dom";
import FloorplanModal from "./FloorplanModal";
import PreferencesList from "./PreferencesList";
import SessionImages from "./SessionImages";
import SessionHeader from "./SessionHeader";
import { FEATURES_ENUM, MODULES_ENUM, PAGES_ENUM } from "../../constants/modules";

const Session = ({ isPresentation }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const pages = useSelector((state) => state.configuration.pages);
  const customer = useSelector((state) => state.user.customer);
  const customerPreference = useSelector(
    (state) => state.precinctExplore.customerPreference
  );
  const paths = location?.pathname?.split("/");
  const params = useParams();
  const customerId = params.customerId || paths[paths.length - 1];

  const pageRef = useRef(null);

  const [activeFloorplan, setActiveFloorplan] = useState();

  useEffect(() => {
    if (!isPresentation) {
      if (!customer) {
        dispatch(reqGetCustomerProfile(customerId));
      } else {
        socket.emitUIActionEvent(ACTION_NAME.SYNC_CUSTOMER_END_SESSION, {
          customerId: customer.id,
        });
      }
    }
    if (customer?.id) {
      dispatch(reqGetCustomerPreference(customer?.id));
    }
  }, [customer]);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.PAGE_SCROLL) {
      if (pageRef.current && content.data) {
        pageRef.current.scrollTop =
          content.data.scrollPercentage *
          (pageRef.current.scrollHeight - pageRef.current.clientHeight);
      }
    }
    if (content.action === ACTION_NAME.SYNC_CUSTOMER_END_SESSION) {
      dispatch(reqGetCustomerProfile(content.data.customerId));
    }
    if (content.action === ACTION_NAME.CLICK_END_SESSION) {
      handleCloseSession();
    }
    if (content.action === ACTION_NAME.SET_ACTIVE_FLOORPLAN) {
      handleOpenFloorplan(content.data.floorplan);
    }
    if (content.action === ACTION_NAME.CLOSE_ACTIVE_FLOORPLAN) {
      handleCloseFloorplan();
    }
  };
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const onScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollPercentage: scrollTop / (scrollHeight - clientHeight),
      });
    }
  };

  const onCloseSession = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_END_SESSION);
    }
    handleCloseSession();
  };

  const handleCloseSession = () => {
    dispatch(reqSetPage(REACTUI_PAGES.ONBOARD_PAGE));
    navigate(isPresentation ? "/presentation" : "/");
    dispatch(reqSetCustomerProfile(""));
  };

  const handleOpenFloorplan = (floorplan) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SET_ACTIVE_FLOORPLAN, { floorplan });
    }
    setActiveFloorplan(floorplan);
  };

  const handleCloseFloorplan = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_ACTIVE_FLOORPLAN);
    }
    setActiveFloorplan(undefined);
  };

  const renderFeatures = () => {
    const feature = pages
      ?.find(p => p.name === PAGES_ENUM.POST_SESSION && !!p?.props?.visible)
      ?.modules
      ?.find(m => m.name === MODULES_ENUM.POST_SESSION && !!m?.props?.visible)
      ?.features
      ?.find(f => f.name === FEATURES_ENUM.PREFERENCE_DETAIL && !!f?.props?.visible);

    if (!feature) return <></>;

    return (
      <PreferencesList
        components={feature?.components?.filter(c => !!c?.props?.visible)}
        preferences={customerPreference}
        handleOpenFloorplan={handleCloseFloorplan}
      />
    );
  }

  return (
    <>
      <FloorplanModal
        floorplan={activeFloorplan}
        onClose={handleCloseFloorplan}
        isPresentation={isPresentation}
      />
      <div
        ref={pageRef}
        onScroll={onScroll}
        className="z-[1000] bg-center w-screen mb-60 overflow-y-scroll overflow-x-hidden absolute top-0 h-[100dvh] mt-0 bg-black bg-contain bg-no-repeat sessionPage"
      >
        <div className="flex flex-col items-center justify-between text-white w-full text-center relative bg-white">
          <SessionHeader
            onSave={onCloseSession}
            customer={customer}
            customerId={customerId}
          />
          {renderFeatures()}
          <SessionImages />
          <SessionFooter />
        </div>
      </div>
    </>
  );
};

export default Session;
