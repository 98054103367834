import React, { Fragment } from "react";
import { convertLabelSetting } from "../utils";

import FileIcon from "../components/svgs/fileIcon";
import PlusIcon from "../components/svgs/PlusIcon";
import { Button, Modal } from "../../components/commons";
import AddModule from "./add-module";
import AddStaticPage from "./add-static-page";

const Module = ({ setModuleState, moduleState, totalData }) => {
  const modules = totalData?.modules?.map((module) => ({
    label: convertLabelSetting(module?.name),
    value: module?.id,
    visible: module?.props?.visible,
    id: module?.id,
    features: module?.features,
    props: module?.props,
  }));
  const isVisible = totalData?.visible;
  const isGalleryModuleB = totalData?.label?.includes("Gallery Page Type B");
  const isStaticPage = totalData?.label?.includes("Static Page");

  return (
    <div className="my-2 w-full relative">
      {!isVisible && (
        <div className="absolute w-full z-10 h-full bg-transparent cursor-not-allowed" />
      )}
      {modules.map((module) => (
        <div
          className={`flex w-full justify-between border-[1px] border-gray-300 mt-2  flex-1 p-2 mb-2 hover:cursor-pointer hover:bg-gray-300 rounded w-[100px] mx-2 ${
            moduleState?.value === module.value ? "bg-gray-300" : ""
          }`}
          key={`module-${module.value}`}
          onClick={() => setModuleState(module)}
        >
          <div className="flex items-center gap-2">
            <FileIcon />
            <p className="flex-1 ">{module.label}</p>
          </div>
        </div>
      ))}
      {isGalleryModuleB && (
        <Modal
          maskClassName="bg-opacity-50"
          zIndex="z-[9998]"
          trigger={
            <Button
              className="bg-white text-black border rounded border-gray-300 w-full py-1 mx-2 hover:bg-gray-300 hover:text-black"
              icon={<PlusIcon />}
            >
              <span className="font-medium text-sm">Add Module</span>
            </Button>
          }
          content={({ setIsShow }) => (
            <AddModule setIsShow={setIsShow} idPages={totalData?.id} />
          )}
        />
      )}
      {isStaticPage && (
        <Modal
          maskClassName="bg-opacity-50"
          zIndex="z-[9998]"
          bodyClassName="px-4"
          trigger={
            <Button
              className="bg-white text-black border rounded border-gray-300 w-full py-1 mx-2 hover:bg-gray-300 hover:text-black"
              icon={<PlusIcon />}
            >
              <span className="font-medium text-sm">Add Static Page</span>
            </Button>
          }
          content={({ setIsShow }) => (
            <AddStaticPage setIsShow={setIsShow} idPages={totalData?.id} />
          )}
        />
      )}
    </div>
  );
};

export default Module;
