/** @format */

import React from "react";
import { COMPONENTS_ENUM } from "../../../../constants/modules";

const LocationGroup = React.forwardRef(
  (props, ref) => {

    const {
      category,
      pois,
      onClickCategory,
      onClickItem,
      activeCategory,
      activePoi,
      visible = false,
    } = props;

    if (!visible) return null;

    return (
      <div className="flex w-[215px] flex-col items-start gap-[25px]">
        <div className="text-white flex flex-col justify-end items-start self-stretch cursor-pointer">
          <span
            className={`text-[#242422] font-sans text-[14px] font-normal leading-normal tracking-[1.4px] uppercase
            ${activeCategory && activeCategory !== category
                ? "opacity-40"
                : ""
              }`}
            onClick={() => onClickCategory(category)}
          >
            {category}
          </span>
        </div>
        <div className="flex flex-col items-start gap-3 self-stretch">
          {pois.map((poi) =>
            poi.category === category ? (
              <li
                className={`list-none text-white flex items-start gap-3 cursor-pointer ${(activePoi && activePoi !== poi.id) || activeCategory
                    ? "opacity-40"
                    : ""
                  }`}
                key={poi.id}
                onClick={() => onClickItem(poi)}
              >
                <span className="text-[#242422] font-suisse-intl text-xs font-normal leading-normal tracking-wider uppercase">-</span>
                <span className="text-[#242422] font-suisse-intl text-base font-normal leading-[20.8px] tracking-[ -0.36px]">{poi.title}</span>
              </li>
            ) : null
          )}
        </div>
      </div>
    );
  }
);
LocationGroup.displayName = COMPONENTS_ENUM.LOCATION_GROUP;

export default LocationGroup;
