import React, { useRef } from "react";
import { getS3FEMediaUrl } from "../../../helper/media";
import { ReactComponent as Close } from "../../../assets/images/close.svg";
import socket from "../../../helper/socket";

const PlayVideo = ({ urlBg, urlVideo, onStopVideo, isPresentation }) => {
  const videoRef = useRef(null);

  const onSkipVideo = () => {
    onStopVideo();
    if (!isPresentation) {
      const messageId = Date.now();
      socket.shareMedia({ FG: { FG_0000: "MOV", MSG_ID: messageId } });
    }
  };

  return (
    <div className="absolute h-full w-full z-[99] bg-[black] flex items-center left-0 top-0">
      <video
        loop
        ref={videoRef}
        autoPlay={true}
        muted={false}
        preload="auto"
        id="intro-video-2"
        style={{ backgroundImage: urlBg && `url(${getS3FEMediaUrl(urlBg)})` }}
      >
        <source src={getS3FEMediaUrl(urlVideo)} type="video/mp4" />
      </video>
      <div className="fixed cursor-pointer right-[45px] top-[120px]" onClick={onSkipVideo}>
        <Close fill="white" />
      </div>
    </div>
  );
};

export default PlayVideo;
