import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import { thunk as thunkMiddleware } from "redux-thunk";
import auth from "./auth/reducer";
import unit from "./unit/reducer";
import err from "./error/reducer";
import routing from "./routing/reducer";
import amenities from "./amenities/reducer";
import scene from "./scene/reducer";
import user from "./user/reducer";
import poi from "./locations/reducer";
import media from "./media/reducer";
import exploreModal from "./explore-modal/reducer";
import unitExplore from "./unit-explore/reducer";
import home from "./home/reducer";
import LogRocket from "logrocket";
import districtdetail from "./district-future-detail/reducer";
import transportdetail from "./transport-detail/reducer";
import precinctExplore from "./precinct-explore/reducer";
import transportOption from "./transport-options/reducer";
import guideSession from "./guide-session/reducer";
import configuration from "./page-configuration/reducer";
import cms from "./cms/reducer";
import gallery from "./gallery/reducer";

// Enable in Development
const actionSanitizer = (action) => {
  return action.type === "RECEIVE_FBX_FILES" && action.payload
    ? { ...action, payload: "<<LONG_BLOB>>" }
    : action;
};
const store = createStore(
  combineReducers({
    auth,
    unit,
    err,
    routing,
    amenities,
    scene,
    user,
    poi,
    exploreModal,
    unitExplore,
    home,
    media,
    districtdetail,
    transportdetail,
    precinctExplore,
    transportOption,
    guideSession,
    cms,
    gallery,
    configuration,
  }),
  {},
  compose(applyMiddleware(thunkMiddleware, LogRocket.reduxMiddleware()))
  // window.__REDUX_DEVTOOLS_EXTENSION__ &&
  //   window.__REDUX_DEVTOOLS_EXTENSION__({
  //     actionSanitizer,
  //     stateSanitizer: (state) => {
  //       return state.scene.fbxs
  //         ? { ...state, scene: { ...state.scene, fbxs: '<<LONG_BLOB>>' } }
  //         : state;
  //     },
  //   })
  // )
);

export default store;
