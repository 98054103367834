import * as type from './type';

const initialState = {
  isShowFutureDetail: false,
  isShowGalleryModal: false,
  activeGalleryModal: '',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.SET_IS_SHOW_FUTURE_DETAIL:
      return {
        ...state,
        isShowFutureDetail: action.data,
      };
    case type.SET_IS_SHOW_GALLERY_MODAL:
      return {
        ...state,
        isShowGalleryModal: action.data,
      };
    case type.SET_ACTIVE_GALLERY_MODAL:
      return {
        ...state,
        activeGalleryModal: action.data.data,
      };
    default:
      return state;
  }
};
