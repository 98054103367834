import React, { useState } from "react";
import { cn } from "../../../helper/utils";

const SlidingSearchInput = ({ wrapperClassName, callbackOnInputChange }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleSearchClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
    callbackOnInputChange && callbackOnInputChange(e.target.value);
  };

  const handleClearClick = () => {
    setSearchValue("");
    setIsExpanded(false);
    callbackOnInputChange && callbackOnInputChange("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className={cn("relative", wrapperClassName)}>
      <form onSubmit={handleSubmit} className="flex items-center">
        {!isExpanded && (
          <button
            type="button"
            onClick={handleSearchClick}
            className="py-1 px-2 bg-gray-300 text-gray-500 rounded-xl text-sm w-[150px] hover:text-gray-700 focus:outline-none text-right"
          >
            Search
          </button>
        )}
        <div
          className={`overflow-hidden transition-all duration-300 ${
            isExpanded ? "w-64" : "w-0"
          }`}
        >
          <div className="relative">
            <input
              type="text"
              value={searchValue}
              onChange={handleInputChange}
              placeholder="Search..."
              className="w-full py-2 pl-4 pr-10 text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-black focus:ring-0 "
            />
            <button
              type="button"
              onClick={handleClearClick}
              className="absolute right-2 top-1/2 -translate-y-1/2 p-1 text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SlidingSearchInput;
