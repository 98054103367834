import React from "react";
import useQuery from "../../../hooks/useQuery";
import { cn } from "../../../helper/utils";

const Header = () => {
  const { push, query } = useQuery();

  return (
    <div className="flex gap-10 justify-center items-center">
      <span
        onClick={() => push({ tab: "unit-data" })}
        className={cn("text-white text-base cursor-pointer font-light", {
          "font-bold": !query?.tab || query?.tab === "unit-data",
        })}
      >
        Unit Data
      </span>
      <span className="text-white">|</span>
      <span
        onClick={() => push({ tab: "unit-content" })}
        className={cn("text-white text-base cursor-pointer font-light", {
          "font-bold": query?.tab === "unit-content",
        })}
      >
        Unit Content
      </span>
    </div>
  );
};

export default Header;
