import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import customerAPi from "../../../apis/api/customer";
import { toast } from "react-toastify";
import profileImage from "../../../assets/images/profile.png";
import newProfileImage from "../../../assets/images/new-profile.png";
import Select from "react-select";
import { reqSetIsShowListInvestorModal } from "../../../reduxs/guide-session/action";
import socket from "../../../helper/socket";
import {
  ACTION_NAME,
  REACTUI_PAGES,
  WEBSOCKET_CHANNEL,
} from "../../../constants/options";
import { LongRightArrowIcon } from "../../svgs/icons";

import { reqSetPage } from "../../../reduxs/home/action";
import { getS3FEMediaUrl } from "../../../helper/media";

const Profile = (props) => {
  const { startDiscover, handleCancel, agents, isPresentation } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emailAddress, setEmailAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [agent, setAgent] = useState();
  const [isCreate, showCreate] = useState(false);
  const [isErrFirstName, setErrFirstName] = useState(false);
  const [isErrLastName, setErrLastName] = useState(false);
  const [isErrEmail, setErrEmail] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isErrAgent, setErrAgent] = useState(false);
  const [listUserSelect, setListUserSelect] = useState([]);
  const isShowListInvestorModal = useSelector(
    (state) => state.guideSession.isShowListInvestorModal
  );
  const isShowListConsultantModal = useSelector(
    (state) => state.guideSession.isShowListConsultantModal
  );
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    dispatch(reqSetIsShowListInvestorModal(true));

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, []);

  useEffect(() => {
    if (isShowListInvestorModal) {
      getListInvestor();
    }
    if (isShowListConsultantModal) {
      getListConsultant();
    }
  }, [isShowListInvestorModal, isShowListConsultantModal]);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CREATE_PROFILE_SUCCESS) {
      onStartUserSession(content?.data?.user);
    }
  };

  const getListInvestor = async () => {
    const result = await customerAPi.getInvestorList();
    setListUserSelect(result?.data || []);
  };

  const getListConsultant = async () => {
    const result = await customerAPi.getInvestorList();
    setListUserSelect(result?.data || []);
  };

  const userSelectType = useSelector(
    (state) => state.guideSession.userSelectType
  );

  const onStartUserSession = (user) => {
    user?.id &&
      navigate({
        search: `?customer=${user.id}`,
      });
    analytics.track("Start Session", {
      customerType: userSelectType,
      customerFirstName: user?.firstname,
      customerLastName: user?.surname,
      customerEmail: user?.email,
    });

    return startDiscover(user?.id);
  };

  const handleSubmitUser = () => {
    handleValidate();
  };

  const handleValidate = async (isCreate) => {
    if (isCreate) {
      onCreateNewProfile();
    } else {
      const selectedUser = listUserSelect.find(
        (user) => user.id === customerSelected
      );
      await onStartUserSession(selectedUser);
    }
  };

  const onCreateNewProfile = async () => {
    try {
      let userGroup = "606d6f356af09f50f367ee23";

      const data = {
        userGroup: userGroup,
        email: emailAddress,
        firstname: firstName,
        surname: lastName,
        // name: name,
        // company: company,
        address: "",
        mobile: "",
        countryCode: "",
        agent,
      };

      const res = await customerAPi.createCustomer(data);
      if (res && !res.data) {
        return toast.error("Customer's email exists!");
      }

      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.CREATE_PROFILE_SUCCESS, {
          user: res.data,
        });
      }

      analytics.track("Agent Created Client", {
        agentId: res.data?.id,
        clientId: res.data?.id,
        clientFistName: res.data?.firstname,
        clientEmail: res.data?.email,
        clientSurname: res.data?.surname,
      });

      onStartUserSession(res?.data);

      toast.success(`Created successfully!`);
    } catch (err) {
      toast.error(`Customer's email already exists`);
    }
  };

  const handleCreateCustomer = async () => {
    handleValidate(true);
  };

  const handleExploreNow = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_CUBE_MENU);
    }
    dispatch(reqSetPage(REACTUI_PAGES.LANDING_PAGE));
  };

  const handleResetData = () => {
    setEmailAddress("");
    setFirstName("");
    setLastName("");
    setAgent("");
    setErrEmail(false);
    setIsEmailInvalid(false);
    setErrFirstName(false);
    setErrLastName(false);
    setErrAgent(undefined);
  };

  const createProfile = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!firstName) setErrFirstName(true);
    if (!lastName) setErrLastName(true);
    if (!emailAddress) setErrEmail(true);
    if (!agent) setErrAgent(true);

    if (!emailPattern.test(emailAddress)) {
      setIsEmailInvalid(true);
      return;
    }

    if (firstName && lastName && emailAddress) handleCreateCustomer();
    return;
  };

  const [customerSelected, setCustomerSelected] = useState(null);

  const ErrMessage = () => (
    <p className="text-left text-[#c02b0a] text-[12px] font-suisse mt-[5px]">This field is required</p>
  );
  const InvalidEmailMessage = () => (
    <p className="text-left text-[#c02b0a] text-[12px] font-suisse mt-[5px]">Email not valid</p>
  );

  const renderCreateProfile = () => {
    return (
      <div id="select-profile-wrapper" className="absolute top-0 left-0 w-screen h-screen bg-white text-[#242422] p-[80px_110px] flex justify-between overflow-y-scroll">
        <div className="flex flex-col w-[534px] h-[846px] relative justify-between before:content-[''] before:block before:absolute before:top-0 before:left-[574px] before:h-full before:w-[1px] before:bg-[#24242226]">
          <div className="pt-[42px] h-[307px] text-left">
            <h2 className="text-[75px] !font-normal font-domaine">NEW PROFILE</h2>
          </div>
          <div className="border-t border-[#24242226] flex flex-col items-start w-full pt-[20px] pb-[30px]">
            <p className="text-[22px] mb-[5px] font-normal text-[#000] text-black text-left leading-[30px] tracking-tight font-suisse">First time at Bay Harbor Towers? Enter your details below to create a new customer profile.</p>
            <div className="w-full grid gap-y-[25px] gap-x-[15px] grid-cols-2 mb-[30px]">
              <div className="relative flex flex-col">
                <input
                  className={`outline-none placeholder:text-[12px] placeholder:font-normal placeholder:text-[#242422] placeholder:tracking-[0.5px] placeholder:font-suisse w-full h-[64px] border-none bg-[#f4f4f4] pl-[20px]`}
                  type="text"
                  placeholder="FIRST NAME"
                  name="firstName"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setErrFirstName(false);
                  }}
                  style={{ border: isErrLastName ? '1px solid #c02b0a' : 'none'}}
                />
                {isErrFirstName && <ErrMessage />}
                {firstName && <span className="place-holder absolute left-[20px] top-[7px] text-[8px] text-[#24242299] font-normal">FIRST NAME</span>}
              </div>
              <div className="relative flex flex-col">
                <input
                  className={`outline-none placeholder:text-[12px] placeholder:font-normal placeholder:text-[#242422] placeholder:tracking-[0.5px] placeholder:font-suisse w-full h-[64px] bg-[#f4f4f4] pl-[20px]`}
                  type="text"
                  placeholder="LAST NAME"
                  name="lastName"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setErrLastName(false);
                  }}
                  style={{ border: isErrLastName ? '1px solid #c02b0a' : 'none'}}
                />
                {isErrLastName && <ErrMessage />}
                {lastName && <span className="place-holder absolute left-[20px] top-[7px] text-[8px] text-[#24242299] font-normal">LAST NAME</span>}
              </div>
              <div className="relative flex flex-col">
                <input
                  className={`outline-none placeholder:text-[12px] placeholder:font-normal placeholder:text-[#242422] placeholder:tracking-[0.5px] placeholder:font-suisse w-full h-[64px] border-none bg-[#f4f4f4] pl-[20px]`}
                  type="text"
                  placeholder="EMAIL"
                  name="email"
                  value={emailAddress}
                  onChange={(e) => {
                    setEmailAddress(e.target.value);
                    setErrEmail(false);
                    setIsEmailInvalid(false);
                  }}
                  style={{ border: isErrEmail || isEmailInvalid ? '1px solid #c02b0a' : 'none'}}
                />
                {isErrEmail ? (
                  <ErrMessage />
                ) : (
                  <>{isEmailInvalid && <InvalidEmailMessage />}</>
                )}
                {emailAddress && <span className="place-holder absolute left-[20px] top-[7px] text-[8px] text-[#24242299] font-normal">EMAIL</span>}
              </div>
              <div className="relative flex flex-col">
                <Select
                  className={`w-full h-full bg-[#f4f4f4] focus:outline-none`}
                  classNamePrefix={isErrAgent? "select-error" : "select"}
                  defaultValue={{ value: "", label: "SALES AGENT" }}
                  isDisabled={false}
                  isSearchable={false}
                  options={agents?.map(({ id, firstname }) => ({
                    value: id,
                    label: firstname,
                  }))}
                  name="type"
                  onChange={(item) => {
                    setAgent(item.value);
                    setErrAgent(false);
                  }}
                  style={{ border: isErrAgent ? '1px solid #c02b0a' : 'none' }}
                />
                {isErrAgent && <ErrMessage />}
              </div>
            </div>
            <button onClick={createProfile} className="w-full h-[60px] bg-black text-white border-none text-[14px] font-normal mb-[25px]">SUBMIT</button>
            <div className="button-group flex">
              <button onClick={() => { showCreate(false); handleResetData(); }} className="text-[12px] font-normal">CANCEL</button>
            </div>
          </div>
        </div>
        <img className="md:block h-auto object-cover" width="534" height="846" src={newProfileImage} alt="new-profile-image" />
      </div>

    );
  };

  const renderInputProfile = () => {
    return (
      <div id="select-profile-wrapper" class="absolute inset-0 w-full h-full bg-white text-[#242422] p-[80px_110px] flex justify-between overflow-y-scroll">
        <div class="flex flex-col w-[534px] h-[846px] relative justify-between before:content-[''] before:block before:absolute before:top-0 before:left-[574px] before:h-full before:w-[1px] before:bg-[#24242226]">
          <div class="h-[307px] text-left w-full pt-[42px]">
            <h2 class="font-domaine text-[75px] !font-normal">
              SELECT<br />PROFILE
            </h2>
          </div>
          <div class="border-t border-[#24242226] flex flex-col items-start w-full pt-[20px] pb-[30px] px-0">
            <p class="font-['Suisse Intl'] text-[22px] font-normal text-black tracking-tight text-left">
              Select the customer profile for today's session
            </p>
            <input
              type="text"
              class="w-full h-[64px] border-none bg-[#f4f4f4] pl-[20px] placeholder:font-['Suisse Intl'] placeholder:text-[#242422] placeholder:text-[12px] placeholder:font-normal placeholder:tracking-wide focus-visible:outline-none"
              placeholder="SEARCH ..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <ul class="p-0 w-full mt-4 h-[250px] overflow-scroll">
              {listUserSelect
                ?.filter((user) => {
                  if (searchInput == "") {
                    return user;
                  } else if (
                    user?.name
                      .toLowerCase()
                      .includes(searchInput.toLocaleLowerCase())
                  ) {
                    return user;
                  }
                })
                .map(({ id, name }) => (
                  <li
                    className={`first:border-t list-none flex items-center h-[40px] pl-[20px] border-b border-[#24242212] ${customerSelected === id ? "border-2 border-[#242422]" : ""}`}
                    onClick={() => setCustomerSelected(id)}
                    key={id}
                  >
                    {name}
                  </li>
                ))}
            </ul>
            <button class="w-full h-[60px] bg-black text-white text-[14px] font-normal mb-[25px]" onClick={handleSubmitUser}>
              SELECT PROFILE
            </button>
            <div class="button-group flex justify-between w-full">
              <div>
                <button class="bg-transparent text-[12px] font-normal p-0 mr-[24px]" onClick={() => handleCancel()}>CANCEL</button>
                <button class="bg-transparent text-[12px] font-normal p-0" onClick={() => showCreate(true)}>
                  CREATE A NEW PROFILE
                </button>
              </div>
              <div class="explore-btn cursor-pointer flex items-center gap-[10px]" onClick={handleExploreNow}>
                <button class="bg-transparent text-[12px] font-normal p-0 mr-0" onClick={() => handleCancel()}>EXPLORE NOW</button>
                <LongRightArrowIcon fill="#242422" />
              </div>
            </div>
          </div>
        </div>
        <img
          class="object-cover"
          src={profileImage}
          alt="create-profile-image"
          width="534px"
          height="846px"
        />
      </div>
    );
  };

  return (
    <div
      id="customer-profile"
      style={{ backgroundImage: `url(${getS3FEMediaUrl("/bg-holding.png")})` }}
    >
      {isCreate ? renderCreateProfile() : renderInputProfile()}
    </div>
  );
};

export default Profile;
