import Markdown from "react-markdown";
import React, { useState } from 'react';
import { FAQSArrowDown, FAQSArrowUp } from '../svgs/icons';

export const CollapsibleContent = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const onToggle = () => setIsCollapsed(!isCollapsed);
  return (
    <div>
      <a
        className="no-underline flex items-center justify-between mb-[10px]"
        onClick={onToggle}
      >
        <span className='text-black text-sm font-bold font-poppins leading-[1] uppercase max-w-[95%]'>
          {props.title}
        </span>
        {isCollapsed ? (
          <FAQSArrowUp style={{ width: 31 }} fill={"#2D2927"} />
        ) : (
          <FAQSArrowDown style={{ width: 31 }} fill={"#2D2927"} />
        )}
      </a>
      <div className="h-[1px] w-full bg-[#2d292780]"/>
      <div className={`tg-body mt-[30px] w-[950px] collapse ${isCollapsed ? "show visible" : ''}`}>
        <Markdown className='*:font-poppins *:font-normal *:text-sm *:leading-[21px] *:tracking-[0.5px] *:whitespace-pre-line'>
          {props.content}
        </Markdown>
      </div>
    </div>
  );
};
