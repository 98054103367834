import React from "react";
import {
  Button,
  InputField,
  TextareaField,
  UploadField,
} from "../../components/commons";
import ArrowUp from "../../configuration/components/svgs/ArrowUp";
import ArrowDown from "../../configuration/components/svgs/ArrowDown";
import TrashIcon from "../../configuration/components/svgs/TrashIcon";

const RightImage = ({
  index,
  control,
  move,
  disableDown,
  disableUp,
  remove,
}) => {
  return (
    <div className="my-5">
      <div className="border-[1px] py-5 relative rounded-lg border-solid">
        <div className="px-10 py-5 flex items-center justify-center">
          <div className="flex gap-5 w-full items-center">
            <div className="flex items-center flex-[0_0_70%] flex-wrap w-full">
              <div className="w-full">
                <InputField
                  controller={{
                    control,
                    name: `sections.${index}.tag`,
                  }}
                  inputProps={{
                    placeholder: "Tag",
                  }}
                  wrapperClassName="w-full mb-4"
                />
                <InputField
                  controller={{
                    control,
                    name: `sections.${index}.title`,
                  }}
                  inputProps={{
                    placeholder: "Title",
                  }}
                  wrapperClassName="w-full mb-4"
                />
                <TextareaField
                  className="w-full"
                  controller={{
                    control,
                    name: `sections.${index}.description`,
                  }}
                  textareaProps={{
                    placeholder: "Description",
                    rows: 4,
                  }}
                />
              </div>
            </div>
            <UploadField
              description="Media"
              controller={{
                control,
                name: `sections.${index}.media`,
              }}
            />
          </div>
        </div>
        <div className="absolute top-2 right-[-10px]">
          <Button
            onClick={() => remove(index)}
            className="!border-none text-red-500"
            variant="icon"
            icon={<TrashIcon width={20} height={20} />}
          />
        </div>

        <div className="absolute top-1/2 right-[-50px] -translate-y-1/2">
          <div className="pb-5 ">
            <Button
              disabled={disableUp}
              onClick={() => move(index, index - 1)}
              className="!border-none"
              variant="icon"
              icon={<ArrowUp width={20} height={20} />}
            />
          </div>
          <div className="cursor-pointer">
            <Button
              disabled={disableDown}
              onClick={() => move(index, index + 1)}
              className="!border-none"
              variant="icon"
              icon={<ArrowDown width={20} height={20} />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightImage;
