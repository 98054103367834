/** @format */

import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import socket from "../../helper/socket";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { reqGetUserProfile } from "../../reduxs/user/action";
import Panorama from "./panorama";
import { useNavigate } from "react-router-dom";
import { PAGES_ENUM } from "../../constants/modules";

const PanoramaPage = (props) => {
  const { roles, authMiddleware } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = useSelector((state) => state.user.data);
  const pages = useSelector((state) => state.configuration.pages);
    const [configPage, setConfigPage] = useState();
    const [isReady, setReady] = useState(false);

  useEffect(() => {
    dispatch(reqGetUserProfile());
    authMiddleware(roles, navigate);
  }, []);

  useEffect(() => {
    if (pages && pages?.length > 0) {
      const idx = pages?.findIndex((i) => i.name === PAGES_ENUM.PANORAMA_PAGE);
      if (idx !== -1) {
        setConfigPage(pages[idx]);
      }
        setReady(true);
    }
  }, [pages]);
  useEffect(() => {
    if (authUser && authUser.id) {
      socket.auth = {
        userId: authUser.id,
      };
      socket.connect();

      return () => {
        socket.disconnect();
      };
    }
  }, [authUser]);

  const renderPanorama = () => {
    const names = [
      "Location 1",
      "Location 2",
      "Location 3",
      "Location 4",
      "Location 5",
      "Location 6",
      "Location 7",
      "Location 8",
    ];

    const images = [
      "/uploads/panorama/day_night/PANO0021_Panorama_Day_0.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Day_1.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Day_2.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Day_3.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Day_4.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Day_5.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Day_6.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Day_7.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Night_33.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Night_34.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Night_35.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Night_36.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Night_37.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Night_38.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Night_39.jpg",
      "/uploads/panorama/day_night/PANO0021_Panorama_Night_40.jpg",
    ];

    return (
      <Panorama
        name={"Location 1"}
        isPresentation={false}
        names={names}
        images={images}
      />
    );
  };

  if (configPage && !configPage?.props.visible && pages?.length > 0) {
    navigate('/', { replace: true });
  }
    if(!isReady) return <div />

  return <div>{renderPanorama()}</div>;
};

export default React.memo(PanoramaPage);
