import React, { useEffect, useState } from "react";
import { getS3FEMediaUrl } from "../../../helper/media";
import socket from "../../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../constants/options";
import PlayVideo from "./play-video";

const Films = ({ data, isInfinity, isPresentation }) => {
  const [isShowVideo, setShowVideo] = useState(false);
  const [urlVideo, setUrlVideo] = useState("");
  const [urlThumbVideo, setUrlThumbVideo] = useState("");

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action == ACTION_NAME.PLAY_VIDEO) {
          return handlePlayVideo(content.data.item);
        }
        if (content.action == ACTION_NAME.STOP_VIDEO) {
          return onStopVideo();
        }
      });
    }
  }, [isPresentation]);

  const handlePlayVideo = (item) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PLAY_VIDEO, {
        item: item,
      });

      if (item?.message) {
        const message = item.message;
        message.FG.MSG_ID = Date.now();
        socket.shareMedia(message);
      }
    }

    setShowVideo(true);
    setUrlVideo(item.path);
    setUrlThumbVideo(item.thumbnail);
  };

  const onStopVideo = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.STOP_VIDEO);
    }
    setShowVideo(false);
    setUrlVideo("");
  };

  return (
    <div className="overflow-y-auto h-full pt-[104px]">
      <div className="grid gap-x-[15px] gap-y-[15px] grid-cols-[repeat(2,1fr)] pb-[260px]">
        {data?.media?.map((i, idx) => {
          return (
            <div
              key={`films-${idx}`}
              data-fancybox="gallery"
              className={`h-[286px] bg-white flex flex-col bg-cover bg-no-repeat cursor-pointer ${isInfinity && "infinity"}`}
              style={{
                backgroundImage: `url(${getS3FEMediaUrl(i.thumbnail)}`,
              }}
              onClick={() => handlePlayVideo(i)}
            />
          );
        })}
      </div>
      {isShowVideo && (
        <PlayVideo
          isPresentation={isPresentation}
          urlVideo={urlVideo}
          onStopVideo={onStopVideo}
        />
      )}
    </div>
  );
};

export default Films;
