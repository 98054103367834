import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div className="h-screen w-screen flex justify-center items-center bg-white">
      <div className="text-center uppercase">
        <h1 className='text-6xl font-[bold]'>404</h1>
        <h4>Page not found</h4>
        <Link className="btn-text" to="/">
          Go to home page
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
