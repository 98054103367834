import React from "react";

import { useTable } from "./useTable";

const TableContext = React.createContext(null);

export function useTableContext() {
  const table = React.useContext(TableContext);

  if (!table) {
    throw new Error("useTableContext must be used within a TableContext");
  }

  return table;
}

export function BaseTable({ children, ...rest }) {
  const table = useTable(rest);

  const value = React.useMemo(
    () => {
      return Object.assign({}, table);
    },
    // Only re-run this hook when table state changes
    // Why using memo here?
    // Because table did not change, but the table state changed
    [table.getState()]
  );

  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
}
