import React, {memo, useRef, useState} from 'react';
import * as PANOLENS from 'panolens';
import { useSelector } from 'react-redux';
import { ImageLoader } from 'three';

const Panorama = (props) => {
  const {  names, images } = props;
  const authUser = useSelector((state) => state.user.data);
  const isLoading = true;
  const refCurrentIndex = useRef(0);
  const [isDay, setDay] = useState(true);

  let currentIndex = 0;

  if (authUser == null || authUser == undefined) {
    return (
      <>
        <div id="wrap-panorama" />
      </>
    );
  }

  let viewer;

  let panos = [undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined, undefined];

  const loader = new ImageLoader();

  const onInitPanorama = () => {
    panos = [];
    const length = images.length;
    if (length <= 0) {
      return;
    }
    for (let index = 0; index < length; index++) {
      panos.push(undefined);
    }

    const image1stURL = images[0];

    loader.load(image1stURL, function ( image ) {

      let pano = new PANOLENS.ImagePanorama(image);

      panos[0] = pano;
      viewer.add(pano);
      viewer.setPanorama(pano);

      for (let index = 1; index < images.length; index++) {
        const imageURL = images[index];

        loader.load(imageURL, function ( image ) {
          let pano = new PANOLENS.ImagePanorama(image);

          panos[index] = pano;
          viewer.add(pano);

        }, undefined, function (error) {
          console.error( 'An error happened.' , error);
        });
      };

    }, undefined, function (error) {
      console.error( 'An error happened.' , error);
    });

    viewer = new PANOLENS.Viewer({
      container: document.querySelector("#wrap-panorama-view")
    });
    let cameraPos = {};

    if (viewer.camera != null && authUser != null) {
      viewer.camera.userData.id = authUser.id;
    }
    viewer.camera.fov = 80;
    viewer.camera.updateProjectionMatrix();

    let control = viewer.getControl();
    control.noZoom = true;
    viewer.onChange = () => {
      viewer.update();
      viewer.render();

      let position = {
        x: viewer.camera.position.x,
        y: viewer.camera.position.y,
        z: viewer.camera.position.z
      };

      let quaternion = {
        x: viewer.camera.quaternion.x,
        y: viewer.camera.quaternion.y,
        z: viewer.camera.quaternion.z,
        w: viewer.camera.quaternion.w,
      }
      let content = {
        position: position,
        quaternion: quaternion,
        zoom: viewer.camera.zoom,
        fov: viewer.camera.fov,
        authUserId: viewer.camera.userData.id,
        index: currentIndex
      };
      if (cameraPos !== JSON.stringify(content)) {
        cameraPos = JSON.stringify(content);
      }
    }
  }


  onInitPanorama();


  const renderListPanorama = () => {
    let i = 0;
    const loading = () => {
      if (i == 0) {
        i = 1;
        let bar = document.getElementById('myBar');
        let progress = document.getElementById('myProgress');
        bar.style.display = 'block';
        progress.style.display = 'block';
        let width = 1;
        let id = setInterval(frame, 10);
        function frame() {
          if (width >= 100) {
            bar.style.display = 'none';
            progress.style.display = 'none';
            clearInterval(id);
            i = 0;
          } else {
            width++;
            bar.style.width = width + '%';
          }
        }
      }
    };
    const select = () => {
      let item = document.getElementsByClassName('pano-item-name');
      for (let i = 0; i < item.length; i++) {
        item[i].addEventListener('click', function () {
          let current = document.getElementsByClassName('text-white font-bold opacity-100');
          current[0].className = current[0].className.replace(' text-white font-bold opacity-100', '');
          this.className += ' text-white font-bold opacity-100';
        });
      }
    }
    return (
      <div className="absolute z-[1] h-[calc(100vh_-_191px)] overflow-y-auto flex justify-center flex-col p-[18px] top-[94px]">
        <div className="pano-item-name flex flex-col justify-around px-5 py-[5px] bg-[rgba(0,0,0,0.35)]">
          {names.map((item, index) => (
            <div key={index} className="cursor-pointer px-0 py-2.5">
              <span
                id={`e${index}`}
                className={`text-[#efecec] opacity-80 uppercase font-semibold text-sm leading-[18px] text-center ${index === 0 ? 'text-white font-bold opacity-100' : ''}`}
                onClick={() => {
                  refCurrentIndex.current = index;
                  loading();
                  select();
                  if (panos[index] == undefined) {
                    return;
                  }
                  changeDayNight(true)
                }}
              >
                {item}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const changeDayNight = (val) => {
    setDay(val);
    if(val){
      viewer.setPanorama(panos[refCurrentIndex.current]);
    }else {
      viewer.setPanorama(panos[refCurrentIndex.current + 8]);
    }
  }

  const renderBtnDayNight = () => {
    return(
      <div className='absolute z-[1] -translate-x-2/4 -translate-y-2/4 flex flex-row left-2/4 top-[150px]'>
        <button id='btnDay' className={`flex flex-row justify-center items-center gap-[15px]  mx-[5px] my-0 px-5 py-[5px] rounded-[50px] border-[none] ${ isDay ? 'bg-black' : 'bg-white'}`} onClick={() => changeDayNight(true)}>
          <span className={isDay ? 'text-white' : 'text-black'}>
              Day
          </span>
        </button>
        <button id='btnNight' className={`flex flex-row justify-center items-center gap-[15px]   mx-[5px] my-0 px-5 py-[5px] rounded-[50px] border-[none] ${ !isDay ? 'bg-black' : 'bg-white'}`} onClick={() => changeDayNight(false)}>
        <span className={!isDay ? 'text-white' : 'text-black'}>
              Night
          </span>
        </button>
      </div>
    )
  }

  return (
    <>
      {isLoading &&
        <div className="position-absolute top-50 start-50 translate-middle intro-content">
          <div className='w-[620px] h-[223px] flex justify-center items-center bg-black shadow-[0_10px_20px_rgba(0,0,0,0.25)]'>
            <span className='font-semibold text-2xl leading-[31px] text-center uppercase text-black'>
              VIRTUAL TOUR LOADING ...
            </span>
          </div>
        </div>
      }
      <div id="wrap-panorama-view z-[1] absolute w-full h-full" />
      {renderBtnDayNight()}
      {renderListPanorama()}
      <div id="myProgress" className='w-full absolute z-[100] h-2.5 bg-[black] hidden bottom-0'>
        <div id="myBar" className='absolute w-[1%] h-2.5 bg-[grey] hidden' />
      </div>
    </>
  );
};

export default memo(Panorama);
