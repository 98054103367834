/** @format */

import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
} from "react";
import { Modal } from "../components/commons";
import closeIcon from "../../../assets/images/close-white.svg";
import { toast } from "react-toastify";
import mediaAPI from "../../../apis/api/media";
import galleryApi from "../../../apis/api/gallery";
import unitApi from "../../../apis/api/unit";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import SearchIcon from "../components/Search";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import PlusIcon from "../configuration/components/svgs/PlusIcon";
import icImg from "../../../assets/images/cms/icImg.png";
import icVideo from "../../../assets/images/cms/icVideo.png";
import icVirtual from "../../../assets/images/cms/icVirtour.png";
import ic3D from "../../../assets/images/cms/ic3d.png";
import dayjs from "dayjs";
import { MEDIA_TYPE } from "../utils";
import { reqGetListUnits } from "../../../reduxs/cms/action";
import { isEmpty } from "lodash";

const typeMapping = {
  "gallery": "imagePlaylistOverride",
  "floorplan": "floorplanPlaylistOverride",
  "viewline": "viewlinePlaylistOverride",
  "360": "360ImagePlaylistOverride",
}

const thCss =
  "text-left border whitespace-nowrap overflow-hidden text-ellipsis p-2 border-solid border-[#ddd] cursor-pointer";

const getIcon = (type) => {
  switch (type) {
    case MEDIA_TYPE.VIDEO:
      return <img src={icVideo} alt="edit" className="w-24 m-auto" />;
    case MEDIA_TYPE.MODELS:
      return <img src={ic3D} alt="edit" className="w-24 m-auto" />;
    case MEDIA_TYPE.VIRTUALTOURS:
      return <img src={icVirtual} alt="edit" className="w-24 m-auto" />;
    default:
      return <img src={icImg} alt="edit" className="w-24 m-auto" />;
  }
};

const UnitModal = ({ show, setShow, modalType, unitId }) => {
  const { units } = useSelector((state) => state.cms);
  const [category, setCategory] = useState("amenities");
  const [isShowEditContentModal, setIsShowEditContentModal] = useState(false);
  const [seletedGallery, setSeletedGallery] = useState(null);
  const [override, setOverride] = useState([]);
  const [search, setSearch] = useState("");
  const refData = useRef([]);
  const dispatch = useDispatch();

  const [galleries, setGalleries] = useState([]);

  useEffect(() => {
    if (search) {
      let result = [];
      result = refData.current.filter(
        (gallery) => gallery && gallery.name?.toLowerCase().includes(search.toLowerCase())
      );
      setGalleries(result);
    } else {
      setGalleries(refData.current);
    }
  }, [search]);

  const loadData = useCallback(async () => {
    const unit = units.find((unit) => unit.id === unitId);
    switch (modalType) {
      case "gallery":
        refData.current = [...(unit?.imagePlaylist?.medias || [])];
        setOverride(unit?.imagePlaylistOverride);
        return setGalleries(unit?.galleryPlaylist?.medias);
      case "floorplan":
        refData.current = [...(unit?.floorplanPlaylist?.medias || [])];
        setOverride(unit?.floorplanPlaylistOverride);
        return setGalleries(unit?.floorplanPlaylist?.medias);
      case "viewline":
        refData.current = [...(unit?.viewlinePlaylist?.medias || [])];
        setOverride(unit?.viewlinePlaylistOverride);
        return setGalleries(unit?.viewlinePlaylist?.medias);
      case "360":
        refData.current = [...(unit["360ImagePlaylist"]?.medias || [])];
        setOverride(unit["360ImagePlaylistOverride"]);
        return setGalleries(unit["360ImagePlaylist"]?.medias);
      default:
        break;
    }
  }, [modalType, unitId]);

  useEffect(() => {
    loadData();
  }, [modalType, unitId]);

  const onSearch = (e) => {
    setSearch(e.target?.value);
  };

  const onEditContent = (gallery) => {
    setSeletedGallery(gallery);
    setIsShowEditContentModal(true);
  };

  function swapElements(array, fromIndex, toIndex) {
    // Check if the indices are valid
    if (fromIndex < 0 || fromIndex >= array.length || toIndex < 0 || toIndex >= array.length) {
      console.error("Invalid indices");
      return array; // Return the original array if indices are invalid
    }

    // Create a copy of the original array
    const newArray = [...array];
    
    // Store the element to move
    const elementToMove = newArray[fromIndex];
    
    // Remove the element from the original position
    newArray.splice(fromIndex, 1);
    
    // Insert the element at the new position
    newArray.splice(toIndex, 0, elementToMove);
    
    return newArray;
}

  const onReorderGallery = async (result) => {
    try {
      if (!result.destination) {
        return;
      }
  
      const items = swapElements(
        override,
        result.source.index,
        result.destination.index
      );
  
      setOverride(items);
      const res = await unitApi.putUnitUpdate(unitId, { [typeMapping[modalType]]: items });
      if (res?.data) {
        toast.success("Update order success");
        dispatch(reqGetListUnits());
      }
    } catch (error) {
      toast.error("Update order failed")
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal
      iconCancelMark={() => (
        <div className="z-[9999] cursor-pointer fixed top-[50px] right-[30px]">
          <img src={closeIcon} alt="close-icon" />
        </div>
      )}
      classCustom="overflow-hidden"
      wrapperClassName="wrap-update-content-modal"
      panelClassName="w-[80vw] p-0 rounded-none"
      zIndex="z-[9998]"
      maskClassName="opacity-90"
      show={show}
      setShow={handleClose}
      content={
        <div className="p-[40px] max-h-[85vh] ">
          {/* <Select
            id="location"
            name="location"
            defaultValue="Canada"
            className="absolute top-[5px] block w-[300px] rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <option>Master Playlist</option>
            <option>Master Playlist 1</option>
            <option>Master Playlist 2</option>
          </Select> */}
          <SearchIcon handleInputChange={onSearch} />
          <div className="overflow-y-scroll overflow-x-hidden h-[80vh]">
            <DragDropContext onDragEnd={onReorderGallery}>
              <table
                className="w-full border-collapse table-fixed"
                id="my-table"
              >
                <thead>
                  <tr className="text-sm font-semibold">
                    <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>
                      Image
                    </th>
                    <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>
                      File Name
                    </th>
                    <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>
                      Media Name
                    </th>
                    <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>
                      Content Type
                    </th>
                    <th className={`${thCss} sticky z-[1] top-0 bg-gray-100`}>
                      Last Edited
                    </th>
                  </tr>
                </thead>
                {galleries?.length > 0 && (
                  <>
                    <Droppable droppableId="gallery-list">
                      {(provided, snapshot) => (
                        <tbody
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {override?.map((i, index) => {
                            const gallery = galleries.find(it => it.id === i) || {};
                            return !isEmpty(gallery) ? (
                              <Draggable
                                key={i}
                                draggableId={i}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <tr
                                    key={i.id}
                                    className={`text-sm transition-all hover:bg-gray-100 cursor-grab h-12 ${
                                      snapshot.isDragging ? "table" : ""
                                    }`}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <td className={thCss}>{getIcon(gallery.type)}</td>
                                    <td className={thCss}>{gallery.name}</td>
                                    <td className={thCss}>{gallery.name}</td>
                                    <td className={thCss}>{gallery.type}</td>
                                    <td className={thCss}>
                                      {dayjs(gallery.updatedAt).format(
                                        "HH:mm - YYYY-MM-DD"
                                      )}
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ) : null;
                          })}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </>
                )}
                {galleries?.length === 0 && (
                  <div className="empty-data w-full justify-center items-center flex gap-10 mt-10 flex-col">
                    <div className="italic text-gray-300">{`(Empty data)`}</div>
                    <div
                      className={"cursor-pointer bg-gray-100 rounded-lg"}
                      onClick={() => {}}
                    >
                      <PlusIcon />
                    </div>
                  </div>
                )}
              </table>
            </DragDropContext>
          </div>
        </div>
      }
    />
  );
};

export default UnitModal;
