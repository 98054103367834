import React, { useRef } from 'react';
import FilterGroupRange from './sub-components/filter-group-range';
import { COMPONENTS_ENUM, SUB_COMPONENTS_TYPES_ENUM } from '../../../constants/modules';

const FilterInteriorSize = (props) => {
  const {
    filterValue,
    onChange,
    visible = false,
    componentConfig = null,
    label = 'Filter Interior Size',
    order = 0,
  } = props;

  if (
    !visible
  ) return null;

  const refFilterRangeLot = useRef();

  const renderSubComponents = () => {
    const rangeItems = componentConfig?.subComponents
      ?.filter((sComp) => sComp?.props?.type === SUB_COMPONENTS_TYPES_ENUM.RANGE)
      ?.sort((a, b) => a?.props?.order - b?.props?.order);

    return rangeItems?.map((item, key) => (
      <FilterGroupRange
        ref={refFilterRangeLot}
        label={{
          title: label,
          min: 'MIN',
          max: 'MAX',
        }}
        max={5000}
        icon={'----'}
        filterValue={{
          min: filterValue?.min,
          max: filterValue?.max,
        }}
        onChange={onChange}
        customClass='!gap-[5px]'
        order={order}
        key={key}
        {...(item?.props || {})}
      />
    ))
  }

  return <>
    {renderSubComponents()}
  </>;
};
FilterInteriorSize.displayName = COMPONENTS_ENUM.FILTER_SIZE_INTERIOR;

export default FilterInteriorSize;
