import ApiClient from '../apiClient';
import { authFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);

const getModalDetail = (id, data) =>
  client.get(endPoint.MODAL_DETAIL.replace(':id', id), data);
const getListModal = (data) => client.get(endPoint.MODAL_LIST, data);

export default {
  getModalDetail,
  getListModal,
};
