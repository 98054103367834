import { useState } from "react";
import CollapsedMenuItem from "../CollapsedMenuItem";
import MenuItem from "../MenuItem";
import CreatePlayListForm from "../left-panel/CreatePlayListForm";
import { Modal } from "../../components/commons";
import PlusIcon from "../../configuration/components/svgs/PlusIcon";

const TabContent = ({ config, addButton = false, name, folder = false }) => {
  const [openMenu, setOpenMenu] = useState({});

  const toggleMenu = (menuIndex) => {
    setOpenMenu((prev) => ({
      ...prev,
      [menuIndex]: !prev[menuIndex],
    }));
  };

  const renderSubItems = (subItems, parentIndex) => {
    return subItems.map((subItem, subIndex) => {
      const currentIndex = `${parentIndex}-${subIndex}`;
      if (subItem.type === "submenu") {
        return (
          <div key={currentIndex}>
            <button
              className="w-full flex items-center justify-between p-2 text-sm font-medium text-left bg-yellow-200 hover:bg-yellow-300 rounded"
              onClick={() => toggleMenu(currentIndex)}
            >
              <span className="text-black">{subItem.title}</span>
              <svg
                className={`w-4 h-4 transition-transform transform ${
                  openMenu[currentIndex] ? "rotate-180" : ""
                }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="black"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            {openMenu[currentIndex] && (
              <div className="ml-6 mt-2 space-y-2">
                {renderSubItems(subItem.subItems, currentIndex)}
              </div>
            )}
          </div>
        );
      } else {
        return (
          <MenuItem
            folder={folder}
            path="media"
            subfix={subItem.value}
            marginTop
            key={currentIndex}
            title={subItem.label}
            isShowEdit
            item={subItem}
          />
        );
      }
    });
  };

  return (
    <>
      <div className="flex items-center justify-center rounded-lg h-16 bg-white">
        <h1 className="text-xl text-black font-semibold">{name}</h1>
      </div>
      <nav className="flex-1 px-4 py-2 overflow-y-auto pb-4  h-[calc(100vh-170px-80px)]">
        <div className="space-y-2">
          {config?.map((item, index) => {
            if (item.type === "menu") {
              return (
                <MenuItem
                  id={item.path}
                  folder={folder}
                  path={item.path}
                  marginTop
                  key={index}
                  title={item.title}
                  subfix={item.mediaType}
                  type={item.type}
                />
              );
            } else if (item.type === "submenu") {
              return (
                <div key={index}>
                  <CollapsedMenuItem
                    folder={folder}
                    item={item}
                    openMenu={openMenu}
                    toggleMenu={toggleMenu}
                    index={index}
                    isShowAdd
                  />
                  {openMenu[index] && (
                    <div className="ml-16 space-y-2">
                      {renderSubItems(item.subItems, index)}
                    </div>
                  )}
                </div>
              );
            }
            return null;
          })}
          {addButton && (
            <Modal
              maskClosable
              maskClassName="bg-opacity-50"
              zIndex="z-[9998]"
              title="Create new playlist"
              trigger={
                <button
                  className={`w-full flex items-center justify-between p-2 text-sm font-medium text-left bg-yellow-200 hover:bg-yellow-300 rounded`}
                >
                  <PlusIcon className="text-black w-6 h-6" />
                </button>
              }
              content={({ setIsShow }) => (
                <CreatePlayListForm onClose={() => setIsShow(false)} />
              )}
            />
          )}
        </div>
      </nav>
    </>
  );
};

export default TabContent;
