import React, {  } from "react";
import FormLogin from "../../components/auth/form-login";
import HoldingPageWrapper from "../../components/holding/holding-wrapper";

const HoldingPage = () => {
  return (
    <HoldingPageWrapper>
      <FormLogin />
    </HoldingPageWrapper>
  );
};

export default HoldingPage;
