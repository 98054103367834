import React from "react";
import {
    InfoWindow,
    Polygon,
} from "@react-google-maps/api";

const MapPolygon = ({poi, }) => {
    if (!poi.action_types.includes("polygon")) return <></>;
    return (
      <>
        <Polygon
          key={`${poi.id}_drawing`}
          paths={poi.polygon.coordinates}
          onClick={() => {
            setActivePoi(poi);
          }}
          onLoad={(shape) => {
            let paths = shape.getPaths();
            paths.forEach((path) => {
              let arr = path.getArray();
              arr.forEach((item) => activeMarkers.push(item));
            });
          }}
          onUnmount={(shape) => {
            let paths = shape.getPaths();
            paths.forEach((path) => {
              let arr = path.getArray();
              arr.forEach((item) => {
                let lat = item.lat();
                let lng = item.lng();
                activeMarkers.splice(
                  activeMarkers.findIndex((am) => {
                    return am.lng() === lng && am.lat === lat;
                  }, 1)
                );
              });
            });
          }}
          options={poi.polygon.options}
        />
        {poi.action_types.includes("info_window") && activePoi === poi.id && (
          <InfoWindow
            key={`${poi.id}_info_window`}
            onCloseClick={clearActivePoi}
            position={{
              lat: poi.polygon.coordinates[0].lat,
              lng: poi.polygon.coordinates[0].lng,
            }}
            options={{ closeBoxMargin: "100px 20px 2px 2px", padding: "100px" }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: poi.info_window.content }}
            />
          </InfoWindow>
        )}
      </>
    );
};

export default MapPolygon