import React from "react";
import homeSvg from "../../assets/images/icHome2.svg";

const SessionLogoSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="86"
    height="78"
    viewBox="0 0 86 78"
    fill="none"
  >
    <path
      d="M37.9827 0.598633H0.796631V0.929846L5.42534 3.01749H34.7665L34.747 8.28177H37.9827V0.598633Z"
      fill="black"
    />
    <path
      d="M37.3474 32.7561H37.9828V12.6123H34.7471V55.4542H37.9828V33.9655H37.3474V32.7561Z"
      fill="black"
    />
    <path
      d="M51.253 8.40723L51.2335 3.01749H80.5747L85.2034 0.929846V0.598633H48.0222V8.27675H48.291C49.3272 8.27675 50.3048 8.32694 51.2579 8.40221L51.253 8.40723Z"
      fill="black"
    />
    <path
      d="M48.0173 12.6123V32.7511L51.253 32.5704V12.6123H48.0173Z"
      fill="black"
    />
    <path
      d="M48.0173 55.4542H51.253V34.2064L48.0173 33.9756V55.4542Z"
      fill="black"
    />
    <path
      d="M26.9949 58.9025V33.9662H33.5103V32.7567H26.9949V9.53167H48.8139C57.7682 9.53167 62.1232 13.3557 62.1232 21.2145C62.1232 27.6982 59.0928 31.4118 52.9147 32.4857L51.248 32.5761V34.2121L52.9147 34.3325C60.3978 35.3412 64.1956 39.4111 64.1956 46.492C64.1956 55.0785 60.0264 58.9075 50.681 58.9075H51.248V60.132C62.045 59.8259 68.4626 54.7322 68.4626 46.3465C68.4626 39.11 63.2327 34.2572 54.684 33.389C61.4291 32.3803 66.3218 27.3971 66.3218 21.2195C66.3218 13.7421 60.8671 9.18038 51.2528 8.41257V8.50792H48.0171V8.28711H37.9826H34.7469H23.3389V60.1621H34.7518V58.9125H26.9998L26.9949 58.9025Z"
      fill="black"
    />
    <path
      d="M47.895 32.756H37.9827V33.9654H47.68L47.895 33.9704L48.0172 33.9755V32.751L47.895 32.756Z"
      fill="black"
    />
    <path d="M48.0172 58.9023H37.9778V60.1519H48.0172V58.9023Z" fill="black" />
    <path d="M37.9826 32.7568H37.3472V33.9663H37.9826V32.7568Z" fill="black" />
    <path
      d="M51.253 8.50304V8.40769C50.2999 8.33241 49.3224 8.28223 48.2862 8.28223H48.0173V8.50304H51.253Z"
      fill="black"
    />
    <path
      d="M48.0173 33.9755L51.253 34.2063V32.5703L48.0173 32.751V33.9755Z"
      fill="black"
    />
    <path
      d="M77.7935 44.2888C76.6938 51.9017 73.3017 58.9375 67.9007 64.4778C63.2427 69.2603 57.5045 72.5222 51.253 74.0529V60.1269C50.906 60.1369 50.5736 60.157 50.2168 60.157H48.0173V74.7002V77.902C56.351 76.7879 64.0541 72.9237 70.0904 66.726C76.4299 60.2172 80.2765 51.8265 81.127 42.7783H80.9804L77.7887 44.2838L77.7935 44.2888Z"
      fill="black"
    />
    <path
      d="M51.253 60.1268V58.9023H50.6861C50.6861 58.9023 50.6812 58.9023 50.6763 58.9023H48.0173V60.1519H50.2168C50.5687 60.1519 50.906 60.1318 51.253 60.1218V60.1268Z"
      fill="black"
    />
    <path
      d="M34.747 74.0526C28.5005 72.517 22.7622 69.26 18.1042 64.4775C12.7277 58.9573 9.33557 51.9516 8.22605 44.3689L4.87793 42.7881C5.73329 51.8312 9.57506 60.2219 15.9145 66.7258C21.9509 72.9235 29.654 76.7876 37.9827 77.9017V60.1517H34.7519V74.0476L34.747 74.0526Z"
      fill="black"
    />
    <path d="M37.9779 58.9023H34.7471V60.1519H37.9779V58.9023Z" fill="black" />
  </svg>
);

const SessionFooter = () => {
  return (
    <div className="footer flex w-[1366px] p-[70px_354px_20px_354px] gap-[40px] flex-col items-center bg-white border-t-[1px] border-solid border-[#F1F2F2]">
      <SessionLogoSVG />
      <div className="flex gap-[20px] items-center flex-col">
        <div className="flex flex-col justify-center items-center">
          <div className="[&>span]:text-[#242424] [&>span]:font-[450] [&>span]:text-[13px] [&>span]:font-suisse [&>span]:leading-[17.55px] [&>span]:tracking-[1.3px] [&>span]:text-center [&>span]:uppercase">
            <span className="pr-[10px] webkit-text-stroke">SALES GALLERY</span>
            <span className="opacity-60">10141 E Bay Harbor Drive, </span>
          </div>
          <span className="opacity-60 text-[#242424] uppercase font-[450] text-[13px] font-suisse leading-[17.55px] tracking-[1.3px]">
            Bay Harbor Islands, FL 33154
          </span>
        </div>
        <div className="text-[#242424] font-[450] text-[13px] font-suisse leading-[17.55px] tracking-[1.3px] text-center opacity-60">
          786.425.3557
        </div>
      </div>
    </div>
  );
};

export default SessionFooter;
