import { useSearchParams, useNavigate } from "react-router-dom";

const useQuery = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const query = Object.fromEntries(searchParams.entries());

  const push = (pathnameOrUpdates, updatesOrOptions = {}, options = {}) => {
    const newParams = new URLSearchParams(searchParams);
    let updates, pathname;

    if (typeof pathnameOrUpdates === "string") {
      pathname = pathnameOrUpdates;
      updates = updatesOrOptions;
    } else {
      updates = pathnameOrUpdates;
      options = updatesOrOptions;
    }

    Object.entries(updates).forEach(([key, value]) => {
      if (value === null) {
        newParams.delete(key);
      } else {
        newParams.set(key, value);
      }
    });

    if (pathname) {
      navigate(
        {
          pathname,
          search: newParams.toString(),
        },
        options
      );
    } else {
      setSearchParams(newParams, options);
    }
  };

  return {
    query,
    push,
  };
};

export default useQuery;
