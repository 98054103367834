/** @format */

export const fakeMediaImg = {
  media: [
    {
      type: 'image',
      name: 'RESIDENCES',
      path: '/media/gallery-image-mock.png',
      id: '64d31681220e029812baad20',
    },
    {
      type: 'image',
      name: 'INFINITY POOL',
      path: '/media/gallery-image-mock.png',
      id: '64d31681220e029812baad21',
    },
    {
      type: 'image',
      name: 'OCEAN ACCESS',
      path: '/media/gallery-image-mock.png',
      id: '64d31681220e029812baad22',
    },
    {
      type: 'image',
      name: 'PRIVATE BEACH',
      path: '/media/gallery-image-mock.png',
      id: '64d31681220e029812baad23',
    },
    {
      type: 'image',
      name: '270 DEGREE VIEWS',
      path: '/media/gallery-image-mock.png',
      id: '64d31681220e029812baad24',
    },
    {
      type: 'image',
      name: 'PRIVATE POOL',
      path: '/media/gallery-image-mock.png',
      id: '64d31681220e029812baad25',
    },
    {
      type: 'image',
      name: 'RESIDENCES',
      path: '/media/gallery-image-mock.png',
      id: '64d31681220e029812baad26',
    },
    {
      type: 'image',
      name: 'RESIDENCES',
      path: '/media/gallery-image-mock.png',
      id: '64d31681220e029812baad27',
    },
    {
      type: 'image',
      name: 'BALCONY PALM VIEW',
      path: '/media/gallery-image-mock.png',
      id: '64d31681220e029812baad28',
    },
    {
      type: 'image',
      name: 'AMENITY OVERVIEW',
      path: '/media/gallery-image-mock.png',
      id: '64d31681220e029812baad29',
    },
    {
      type: 'image',
      name: 'RESIDENCES',
      path: '/media/gallery-image-mock.png',
      id: '64d31681220e029812baad2b',
    },
    {
      type: 'image',
      name: 'PORTE COCHERE',
      path: '/media/gallery-image-mock.png',
      id: '64d31681220e029812baad2c',
    },
    {
      type: 'virtual_tour',
      name: 'PORTE COCHERE',
      path: '/media/gallery-image-mock.png',
      id: '64d31681220e029812baad2d',
    },
    {
      type: 'virtual_tour',
      name: 'LIVING TO PALM VIEW',
      path: '/media/gallery-image-mock.png',
      id: '64d31548220e029812baad1c',
    },
    {
      type: 'virtual_tour',
      name: 'PALM LOCATION ORLA BUILDINGS',
      path: '/media/gallery-image-mock.png',
      id: '656d281589be075637693d50',
    },
    {
      type: 'virtual_tour',
      name: 'PORTE COCHERE',
      path: '/media/gallery-image-mock.png',
      id: '64d31681220e029812baad2d',
    },
    {
      type: 'virtual_tour',
      name: 'LIVING TO PALM VIEW',
      path: '/media/gallery-image-mock.png',
      id: '64d31548220e029812baad1c',
    },
    {
      type: 'virtual_tour',
      name: 'PALM LOCATION ORLA BUILDINGS',
      path: '/media/gallery-image-mock.png',
      id: '656d281589be075637693d50',
    },
  ],
  floorplans: [],
  type: 'orla_image',
  name: 'Orla - Images',
  thumbnail: '/media/gallery-image-mock.png',
  id: '64eefa248fc374edbf4b3f7f',
};

export const fakeMediaFilm = {
  media: [
    {
      type: 'video',
      name: 'Orla Film 1',
      path: '/media/media-film-mock.mp4',
      thumbnail: '/media/gallery-image-mock.png',
      message: {
        FG: {
          FG_LIV2580_Orla_FullCGI_Upscaled: 'MOV',
          MSG_ID: 1,
        },
      },
      id: '64d32074220e029812baad3e',
    },
    {
      type: 'video',
      name: 'Orla Film 2',
      path: '/media/media-film-mock.mp4',
      thumbnail: '/media/gallery-image-mock.png',
      message: {
        FG: {
          MSG_ID: 1,
          FG_LIV2580_Orla_Hero_90_Upscaled: 'MOV',
        },
      },
      id: '64d32081220e029812baad3f',
    },
    {
      type: 'video',
      name: 'AVA Palm Jumeirah',
      path: '/media/media-film-mock.mp4',
      thumbnail: '/media/gallery-image-mock.png',
      message: {
        FG: {
          MSG_ID: 1,
          FG_AVA_Immersion_Room: 'MOV',
        },
      },
      id: '652df68fe6af721195f78611',
    },
  ],
  floorplans: [],
  type: 'orla_film',
  name: 'Orla - Films',
  thumbnail: '/media/gallery-image-mock.png',
  id: '64eefa248fc374edbf4b3f7e',
};

export const fakeMediaImg360 = {
  media: [
    {
      type: 'virtual_tour',
      name: 'PORTE COCHERE',
      path: '/panorama/day_night/PANO0021_Panorama_Day_0.jpg',
      id: '64d31681220e029812baad2d',
    },
    {
      type: 'virtual_tour',
      name: 'LIVING TO PALM VIEW',
      path: '/panorama/day_night/PANO0021_Panorama_Day_1.jpg',
      id: '64d31548220e029812baad1c',
    },
    {
      type: 'virtual_tour',
      name: 'PALM LOCATION ORLA BUILDINGS',
      path: '//panorama/day_night/PANO0021_Panorama_Day_2.jpg',
      id: '656d281589be075637693d50',
    },
    {
      type: 'virtual_tour',
      name: 'PORTE COCHERE',
      path: '/panorama/day_night/PANO0021_Panorama_Day_3.jpg',
      id: '64d31681220e029812baad2d',
    },
    {
      type: 'virtual_tour',
      name: 'LIVING TO PALM VIEW',
      path: '/panorama/day_night/PANO0021_Panorama_Day_4.jpg',
      id: '64d31548220e029812baad1c',
    },
    {
      type: 'virtual_tour',
      name: 'PALM LOCATION ORLA BUILDINGS',
      path: '/panorama/day_night/PANO0021_Panorama_Day_5.jpg',
      id: '656d281589be075637693d50',
    },
  ],
  floorplans: [],
  type: 'orla_image',
  name: 'Orla - Images',
  thumbnail: '/media/gallery-image-mock.png',
  id: '64eefa248fc374edbf4b3f7f',
};

export const fakeMediaFloor = [
  {
    media: [
      {
        type: 'image',
        name: '2',
        path: '/images/floorplates/ORLA_Level_2_1.svg',
        hotspots: [
          {
            x: 158,
            y: 17,
            image: '/media/floorplateicons/203.svg',
            floorplan: 203,
          },
          {
            x: 158,
            y: 76,
            image: '/media/floorplateicons/204.svg',
            floorplan: 204,
          },
          {
            x: 361,
            y: 112,
            image: '/media/floorplateicons/206.svg',
            floorplan: 206,
          },
          {
            x: 450,
            y: 103,
            image: '/media/floorplateicons/208.svg',
            floorplan: 207,
          },
          {
            x: 450,
            y: 161,
            image: '/media/floorplateicons/207.svg',
            floorplan: 208,
          },
          {
            x: 569,
            y: 112,
            image: '/images/icon-floorplan-hotspot.svg',
            floorplan: 211,
          },
        ],
        id: '64c7a8cb9c9ac86af7ad691a',
      },
    ],
    floorplans: [
      {
        type: 'image',
        name: '203',
        path: '/media/explore-orla-floorplan-203.jpg',
        id: '64ebdb1c9fbacf2bf758db7f',
      },
      {
        type: 'image',
        name: '204',
        path: '/media/explore-orla-floorplan-204.jpg',
        id: '64ebdb1c9fbacf2bf758db80',
      },
      {
        type: 'image',
        name: '206',
        path: '/media/explore-orla-floorplan-206.jpg',
        id: '64ebdb1c9fbacf2bf758db81',
      },
      {
        type: 'image',
        name: '207',
        path: '/media/explore-orla-floorplan-207.jpg',
        id: '64eebc588fc374edbf4b3f33',
      },
      {
        type: 'image',
        name: '208',
        path: '/media/explore-orla-floorplan-208.jpg',
        id: '64eebc588fc374edbf4b3f34',
      },
      {
        type: 'image',
        name: '211',
        path: '/media/explore-orla-floorplan-211.jpeg',
        id: '64eebc588fc374edbf4b3f35',
      },
    ],
    type: 'orla_level',
    name: '2',
    id: '64eefa248fc374edbf4b3f8b',
  },
  {
    media: [
      {
        type: 'image',
        name: '3',
        path: '/images/floorplates/ORLA_Level_3_1.svg',
        hotspots: [
          {
            x: 158,
            y: 17,
            image: '/images/icon-floorplan-hotspot.svg',
            floorplan: 303,
          },
          {
            x: 158,
            y: 76,
            floorplan: 305,
          },
        ],
        id: '64c7a8cb9c9ac86af7ad691b',
      },
    ],
    floorplans: [
      {
        type: 'image',
        name: '303',
        path: '/media/explore-orla-floorplan-303.jpeg',
        id: '64eebc588fc374edbf4b3f36',
      },
      {
        type: 'image',
        name: '305',
        path: '/media/explore-orla-floorplan-305.jpg',
        id: '64eebc588fc374edbf4b3f37',
      },
    ],
    type: 'orla_level',
    name: '3',
    id: '64eefa248fc374edbf4b3f8c',
  },
  {
    media: [
      {
        type: 'image',
        name: '6',
        path: '/images/floorplates/ORLA_Level_6_1.svg',
        hotspots: [
          {
            x: 158,
            y: 17,
            image: '/media/floorplateicons/603.svg',
            floorplan: 603,
          },
          {
            x: 241,
            y: 103,
            image: '/images/icon-floorplan-hotspot.svg',
            floorplan: 605,
          },
          {
            x: 450,
            y: 103,
            image: '/images/icon-floorplan-hotspot.svg',
            floorplan: 608,
          },
        ],
        id: '64c7a8cb9c9ac86af7ad691e',
      },
    ],
    floorplans: [
      {
        type: 'image',
        name: '208',
        path: '/media/explore-orla-floorplan-208.jpg',
        id: '64eebc588fc374edbf4b3f34',
      },
      {
        type: 'image',
        name: '208',
        path: '/media/explore-orla-floorplan-208.jpg',
        id: '64eebc588fc374edbf4b3f34',
      },
    ],
    type: 'orla_level',
    name: '6',
    id: '64eefa248fc374edbf4b3f8f',
  },
  {
    media: [
      {
        type: 'image',
        name: '8',
        path: '/images/floorplates/ORLA_Level_8_1.svg',
        hotspots: [
          {
            x: 450,
            y: 103,
            image: '/media/floorplateicons/808.svg',
            floorplan: 808,
          },
        ],
        id: '64c7a8cb9c9ac86af7ad691c',
      },
    ],
    floorplans: [
      {
        type: 'image',
        name: '808',
        path: '/media/explore-orla-floorplan-808.jpg',
        id: '64feab17de9ea55b44f78e33',
      },
    ],
    type: 'orla_level',
    name: '8',
    id: '64eefa248fc374edbf4b3f8d',
  },
  {
    media: [
      {
        type: 'image',
        name: '10',
        path: '/images/floorplates/ORLA_Level_2_1.svg',
        hotspots: [
          {
            x: 241,
            y: 103,
            image: '/images/icon-floorplan-hotspot.svg',
            floorplan: 1005,
          },
          {
            x: 450,
            y: 103,
            image: '/media/floorplateicons/1008.svg',
            floorplan: 1008,
          },
        ],
        id: '64c7a8cb9c9ac86af7ad691d',
      },
    ],
    floorplans: [
      {
        type: 'image',
        name: '1005',
        path: '/media/explore-orla-floorplan-1005.jpg',
        id: '64feab17de9ea55b44f78e34',
      },
      {
        type: 'image',
        name: '1008',
        path: '/media/explore-orla-floorplan-1008.jpg',
        id: '64feab17de9ea55b44f78e35',
      },
    ],
    type: 'orla_level',
    name: '10',
    id: '64eefa248fc374edbf4b3f8e',
  },
  {
    media: [
      {
        type: 'image',
        name: '11',
        path: '/images/floorplates/ORLA_Level_11_1.svg',
        hotspots: [
          {
            x: 158,
            y: 17,
            image: '/images/icon-floorplan-hotspot.svg',
            floorplan: 1103,
          },
        ],
        id: '64c7a8cb9c9ac86af7ad6919',
      },
    ],
    floorplans: [
      {
        type: 'image',
        name: '1103',
        path: '/media/explore-orla-floorplan-1103.jpeg',
        id: '64feab17de9ea55b44f78e36',
      },
    ],
    type: 'orla_level',
    name: '11',
    id: '64eefa248fc374edbf4b3f8a',
  },
];
