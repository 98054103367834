import React from "react";

const CancelIcon = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#C7A446" />
      <path
        d="M14.66 5.84525L13.8147 5L9.83 8.98475L5.84525 5L5 5.84525L8.98475 9.83L5 13.8147L5.84525 14.66L9.83 10.6752L13.8147 14.66L14.66 13.8147L10.6752 9.83L14.66 5.84525Z"
        fill="white"
      />
    </svg>
  );
};

export default CancelIcon;
