import React, { useState, useCallback, useRef, useEffect } from "react";
import _ from "lodash";

const useDebouncedSearch = (delay = 500) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const timeoutRef = useRef(null);

  const debouncedSearch = useCallback(
    _.debounce((value) => {
      setSearch(value);
      setLoading(false);
    }, delay),
    [delay]
  );

  const onSearch = useCallback(
    (value) => {
      setLoading(true);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        debouncedSearch(value);
      }, delay);
    },
    [debouncedSearch, delay]
  );

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return [search, onSearch, loading];
};

export default useDebouncedSearch;
