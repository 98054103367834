import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { reqSetIsTransparent } from "../../reduxs/home/action";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import CMSExtraRight from "../../assets/images/cms-extra-right.svg";
import { getS3FEMediaUrl } from "../../helper/media";
import socket from "../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import CloseIcon from "../../assets/images/close.svg";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { cn } from "../../helper/utils";
import CancelIcon from "../svgs/cancelIcon";
import PlusIcon from "../svgs/plusIcon";
const FloorPlanGallery = (props) => {
  const { isPresentation } = props;
  const [media, setMedia] = useState([]);

  const transformComponentRef = useRef(null);

  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const isTransparent = useSelector((state) => state.home.isTransparent);
  const isShowFloorplan = useSelector(
    (state) => state.unitExplore.isShowFloorplan
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedUnit) {
      const floorPlan = selectedUnit?.floorPlan;
      setMedia(floorPlan?.media ? floorPlan.media : []);
    }
  }, [selectedUnit]);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.ZOOM_OUT_FLOORPLAN) {
          if (!transformComponentRef.current) return;
          if (content.data.state) {
            const ratioHeight = window.screen.height / content.data.height;
            const ratioWidth = (window.screen.width * 0.7) / content.data.width;
            const marginWidth = ratioHeight > 1 ? 0.98 : 1.03;
            const marginHeight = ratioHeight > 1 ? 1.3 : 0.98;
            return transformComponentRef.current.setTransform(
              content.data.state.positionX * ratioWidth * marginWidth,
              content.data.state.positionY * ratioHeight * marginHeight,
              content.data.state.scale === 1
                ? 1
                : content.data.state.scale * (1 / marginWidth)
            );
          }
        }

        if (content.action === ACTION_NAME.ENABLE_TRANSPARENT_MODE) {
          return enableTransparent();
        }

        if (content.action === ACTION_NAME.DISABLE_TRANSPARENT_MODE) {
          return showFilter();
        }
      });
    }
  }, [isPresentation]);

  const showFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.DISABLE_TRANSPARENT_MODE);
    }
    dispatch(reqSetIsTransparent(false));
  };

  const enableTransparent = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.ENABLE_TRANSPARENT_MODE);
    }
    dispatch(reqSetIsTransparent(true));
  };

  const hideFloorplan = () => {
    if (!isTransparent) {
      enableTransparent();
    } else {
      handleCloseBtn();
    }
  };

  const handleCloseBtn = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_FLOORPLAN);
    }
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(reqSetIsTransparent(false));
  };

  const mediaImages = media.filter((item) => item.isActive);

  return (
    <TransformWrapper
      ref={transformComponentRef}
      maxScale={2.5}
      minPositionX={window.screen.width * 1.55 * -1}
      minPositionY={window.screen.height * 1.7 * -1}
      maxPositionX={window.screen.width * 1.55}
      maxPositionY={window.screen.height * 1.7}
      wheel={{
        step: 0.1,
      }}
      pinch={{
        step: 0.1,
      }}
      onTransformed={(ref, state) => {
        if (!isPresentation) {
          socket.emitUIActionEvent(ACTION_NAME.ZOOM_OUT_FLOORPLAN, {
            state,
            height: window.screen.height,
            width: window.screen.width,
          });
        }
      }}
    >
      {({ zoomIn, zoomOut }) => (
        <>
          {isShowFloorplan && isTransparent && (
            <img src={CloseIcon} alt="CloseIcon" onClick={handleCloseBtn} />
          )}
          <div className="w-full h-full absolute z-[60] text-center m-0 p-0 left-0 top-0 bg-[#FEF8EF]">
            <TransformComponent
              contentClass="!bg-[#FEF8EF] h-full w-screen"
              wrapperClass="h-full w-screen"
            >
              {mediaImages?.length ? (
                mediaImages.map((item, key) => {
                  return (
                    <img
                      key={key}
                      src={getS3FEMediaUrl(encodeURI(item?.path))}
                      alt="floorplan"
                      className="w-full h-[70vh] object-contain"
                    />
                  );
                })
              ) : (
                <div className="w-full h-[70vh] object-contain flex items-center justify-center">
                  Image Not Found
                </div>
              )}
            </TransformComponent>
          </div>
          <div className="flex justify-between absolute bottom-[98.5px] z-[61] gap-10 w-full">
            {isTransparent && (
              <div
                className="flex gap-2 ml-5 items-center"
                onClick={showFilter}
              >
                <div className="flex cursor-pointer mx-[5px]">
                  <PlusIcon />
                </div>
                <div className="flex cursor-pointer mx-[5px]">
                  <span className="text-[14px] text-[#C7A446] leading-[1.56px] font-normal">
                    Reopen panels
                  </span>
                </div>
              </div>
            )}
            {isShowFloorplan && (
              <div
                className="flex gap-2  mr-5 items-center"
                onClick={hideFloorplan}
              >
                {isTransparent && (
                  <div className="flex cursor-pointer mx-[5px]">
                    <CancelIcon />
                  </div>
                )}
                <div
                  className={cn("flex cursor-pointer mx-[5px]", {
                    "m-0": !isTransparent,
                  })}
                >
                  <span className="text-[14px] text-[#C7A446] leading-[1.56px] font-normal">
                    {isTransparent ? "Close floorplan" : "Close panels"}
                  </span>
                  {/* <img className="rotate-image" src="/icons/arrow.svg" alt="" /> */}
                </div>
                {!isTransparent && (
                  <div className="flex cursor-pointer mx-[5px]">
                    <img
                      src={CMSExtraRight}
                      alt="cms-extra-right"
                      className={classNames({
                        "ml-0": !isTransparent,
                      })}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </TransformWrapper>
  );
};

export default FloorPlanGallery;
