import React, { useEffect } from "react";
import { useController } from "react-hook-form";

const CheckboxField = ({
  controller,
  callbackOnChange,
  defaultChecked,
  isNoLabel = false,
  label,
  disabled = false,
}) => {
  const { field } = useController(controller);

  const handleOnChange = (e) => {
    field.onChange(!field.value);
    callbackOnChange && callbackOnChange(e.target.value);
  };

  useEffect(() => {
    field.onChange(defaultChecked);
  }, [defaultChecked]);

  return (
    <div className="flex gap-2 items-center">
      <input
        id={field.name}
        checked={field.value}
        defaultChecked={defaultChecked}
        disabled={disabled}
        onChange={handleOnChange}
        name={field.name}
        className="w-5 h-5 rounded-[5px]"
        type="checkbox"
      />
      {!isNoLabel && <label htmlFor={field.name}>{label}</label>}
    </div>
  );
};

export default CheckboxField;
