import store from "../../reduxs/store";
import { THROW_ERROR } from "../../reduxs/error/reducer";

const throwErr = (errors) => {
  store.dispatch({
    type: THROW_ERROR,
    errors,
  });
};

const reqSignOut = () => () => {
  // dispatch(push(ROUTE_PATH.LOGIN_SCREEN));
};

export const logoutOnUnauthorized = (response) => {
  if (response.status === 401) {
    // store.dispatch(reqSignOut());
    return response;
  }
  return response;
};

export function rejectInvalidStatusCode({ response, json }) {
  if (response.status >= 200 && response.status < 300) {
    return { response, json };
  }
  // errors for redux-form
  if (json.errors) {
    return throwErr(json.errors);
  }
  if (json.data && json.data.errors) {
    return throwErr(json.data);
  }

  return new Error(json.data ?? json.message);

  // return throwErr({
  //   statusCode: response.status,
  //   message: json.message || '',
  // });
}

export async function toJson(response) {
  const responseText = await response.clone().text();
  if (responseText.length === 0) {
    return {
      response,
      json: {
        data: {},
      },
    };
  }
  try {
    const json = await response.json();
    return { response, json };
  } catch (_) {
    // handle error while parsing json
    throwErr({
      statusCode: response.status,
      message: response.statusText,
    });
  }
  return response;
}

export function getJson(res) {
  if (res && res.json) {
    return res.json;
  }
  return res;
}

export const checkErr = (res) => {
  if (res?.code && res?.code >= 400 && res?.code <= 500) {
    throwErr(res || {});
    if (res.code === 401) {
      store.dispatch(reqSignOut());
    }
  }
  return res;
};
