import React from "react";
import { cn } from "../../../../../helper/utils";
import Loading from "../loading";

const LoadingTable = (props) => {
  return (
    <div
      {...props}
      className={cn(
        "flex flex-col justify-center items-center text-black",
        props.className
      )}
    >
      <Loading width="w-10" height="h-10" />
    </div>
  );
};

export default LoadingTable;
