import React from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import Module from "./module";
import { reqGetPageList } from "../../../reduxs/page-configuration/action";
import settingModuleApi from "../../../apis/api/page-configuration";
import { convertLabelSetting } from "./utils";
import { cn } from "../../../helper/utils";
import FolderIcon from "./components/svgs/FolderIcon";
import CaretDownIcon from "./components/svgs/CaretDownIcon";
import CaretRightIcon from "./components/svgs/CaretRightIcon";
import CmsPageHeader from "../components/PageHeader";
import Table from "./Table";
import StaticPageTemplate from "./static-page-template";
import Switch from "./components/switch";
import TreeSideCanvas from "./canvas/tree-side";
import ContentSideCanvas from "./canvas/content-side";

const Configuration = () => {
  const dispatch = useDispatch();
  const pagesRes = useSelector((state) => state.configuration.pages);

  const [pageState, setPageState] = React.useState(null);
  const [moduleState, setModuleState] = React.useState(null);

  const isStaticPage = pageState?.label?.includes("Static Page");

  const pages = pagesRes?.map((page) => ({
    modules: page?.modules,
    id: page?.id,
    value: page?.id,
    label: convertLabelSetting(page?.name),
    visible: page?.props?.visible,
  }));

  const onSelectPage = (page) => {
    if (pageState?.value === page.value) {
      setPageState(null);
    } else {
      setPageState({ ...page });
    }
    if (pageState?.value != page.value) {
      setModuleState(null);
    }
  };

  const toggleVisiblePage = async (page, visible) => {
    try {
      const res = await settingModuleApi.updatePages(page.id, {
        props: {
          visible,
        },
      });

      if (res) {
        await dispatch(reqGetPageList({}));
        onSelectPage({ ...page, visible });
        !visible && setModuleState(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="page-container">
      <CmsPageHeader title={"MODULE CONFIGURATOR"} />
      <div className="flex">
        <div className="border-r-[1px] h-[calc(100vh-132px-80px)] overflow-y-auto overflow-x-hidden py-2">
          <div className="mx-3 mb-3">
            <Select
              isDisabled
              value={{
                value: "BHT",
                label: "Bayharbor project",
              }}
            />
          </div>
          {pages?.map((page) => (
            <details
              className="group w-[300px] mx-3 my-1"
              key={`page-${page.value}`}
              open={pageState?.value === page.value}
            >
              <summary
                onClick={(e) => {
                  e.preventDefault();
                  onSelectPage(page);
                }}
                className={cn(
                  "p-2 rounded border border-gray-300 relative flex justify-between font-medium list-none cursor-pointer text-slate-700 focus-visible:outline-none transition-colors duration-300 hover:bg-gray-300",
                  {
                    "bg-gray-300": pageState?.value === page.value,
                  }
                )}
              >
                <div className="flex items-center gap-2">
                  {!!page?.modules?.length && (
                    <div>
                      {pageState?.value === page.value ? (
                        <CaretDownIcon />
                      ) : (
                        <CaretRightIcon />
                      )}
                    </div>
                  )}
                  <div>
                    <FolderIcon />
                  </div>
                  <p>{page.label}</p>
                </div>
                <Switch
                  onChange={(e) => toggleVisiblePage(page, e.target.checked)}
                  defaultChecked={page.visible}
                  checked={page.visible}
                  value={page.value}
                  id={`${page?.id}`}
                />
              </summary>
              <div className="pl-2">
                <Module
                  totalData={page}
                  moduleState={moduleState}
                  setModuleState={setModuleState}
                />
              </div>
            </details>
          ))}
          {!!pages?.length && (
            <TreeSideCanvas onSelect={onSelectPage} pageState={pageState} />
          )}
        </div>
        <div className="flex-1 relative">
          {pageState && !pageState.visible && (
            <div className="absolute w-full h-full z-[1000] bg-transparent cursor-not-allowed" />
          )}
          {moduleState && !isStaticPage && (
            <Table
              moduleState={moduleState}
              setModuleState={setModuleState}
              pageState={pageState}
            />
          )}
          {moduleState && isStaticPage && (
            <StaticPageTemplate
              moduleState={moduleState}
              setModuleState={setModuleState}
            />
          )}
          {pageState && pageState?.name === "canvas" && (
            <ContentSideCanvas pageState={pageState} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Configuration;
