import React, { useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import Table from "../../components/Table";
import { cn } from "../../../../../helper/utils";
import { Button } from "../../../components/commons";
import settingModuleApi from "../../../../../apis/api/page-configuration";
import useColumns from "./useColumns";

const List = () => {
  const [loadingExport, setLoadingExport] = React.useState(false);

  const {
    units: unitsRes,
    amenities: amenitiesRes,
    transportOptions: transportOptionsRes,
    transportOptionsLoading,
    unitsLoading,
    amenitiesLoading,
  } = useSelector((state) => state.configuration);

  const { amenitiesColumns, transportOptionColumns, unitColumns } =
    useColumns();

  const onExport = async () => {
    setLoadingExport(true);
    try {
      const res = await settingModuleApi.export3DCanvas();
      if (res) {
        const data = JSON.stringify(res, null, 2);

        const blob = new Blob([data], { type: "application/json" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "canvas-export.json";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      setLoadingExport(false);
    } catch (e) {
      setLoadingExport(false);
      toast.error("Export failed");
    }
  };

  return (
    <div className="mt-10">
      <div className="grid grid-cols-1 gap-5">
        <Table
          disableSortBy
          manualPagination
          loading={unitsLoading}
          cellClassName="first:bg-gray-200 first:text-black border-l-[1px]  border-stone-200 "
          wrapperClassName={cn(
            "rounded-md mt-1 col-span-1 bg-white overflow-y-auto h-[50vh] 2xl:h-[55vh] border-[1px] border-gray-300"
          )}
          classnamethead="sticky top-0 z-10 bg-white"
          data={_.sortBy(unitsRes, "level") || []}
          columns={unitColumns}
        />
      </div>
      <div className="grid grid-cols-2 mt-10 gap-10">
        <Table
          disableSortBy
          manualPagination
          loading={amenitiesLoading}
          cellClassName="first:bg-gray-200 first:text-black border-l-[1px]  border-stone-200"
          wrapperClassName={cn(
            "rounded-md mt-1 bg-white col-span-1 overflow-y-auto h-[50vh] 2xl:h-[55vh]  border-[1px] border-gray-300"
          )}
          classnamethead="sticky top-0 z-10 bg-white"
          data={amenitiesRes || []}
          columns={amenitiesColumns}
        />
        <Table
          manualPagination
          loading={transportOptionsLoading}
          cellClassName="first:bg-gray-200 first:text-black border-l-[1px]  border-stone-200"
          wrapperClassName={cn(
            "rounded-md mt-1 bg-white col-span-1 overflow-y-auto h-[50vh] 2xl:h-[55vh]  border-[1px] border-gray-300"
          )}
          classnamethead="sticky top-0 z-10 bg-white"
          data={transportOptionsRes || []}
          columns={transportOptionColumns}
        />
      </div>
      <div className="mt-8 pb-5 flex justify-center">
        <Button
          className="w-1/2 py-3"
          disabled={!unitsRes?.length}
          isLoading={loadingExport}
          onClick={onExport}
        >
          Export out JSON for Artist for Auth
        </Button>
      </div>
    </div>
  );
};

export default List;
